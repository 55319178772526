import React from 'react';
import { Footer } from '../components';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import { useStyle } from './styles';
import CoverSection from './components/CoverSection';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// [theme.breakpoints.down(600)]: {
//                 margin: 0,
//             }
const remaxLogo = 'images/remax-pay.png';
const reloj = 'images/reloj.png';
const billete = 'images/Billete.png';
const tarjeta = 'images/tarjeta.png';
const tarjetaCheck = 'images/tarjeta_check.png';
const graficoFlecha = 'images/grafico_flecha.png';
const step1 = 'images/step1.png';
const step2 = 'images/step2.png';
const step3 = 'images/step3.png';
const remaxrd_azul = 'images/remaxrd_azul.png';

export default function LandingPage() {
    const classes = useStyle();
    const matches1370pxDown = useMediaQuery('(max-width: 1370px)');
    const matches990pxDown = useMediaQuery('(max-width: 990px)');
    const matches960pxDown = useMediaQuery('(max-width: 960px)');
    const matches600pxDown = useMediaQuery('(max-width: 600px)');
    const matches700pxDown = useMediaQuery('(max-width: 700px)');
    const infoIcon = [
        {
            icon: billete,
            label: 'No necesitas efectivo al momento de realizar el pago.',
        },
        { icon: reloj, label: 'Ahorra tiempo.' },
        { icon: tarjeta, label: 'Acumulas puntos en tu tarjeta.' },
        { icon: graficoFlecha, label: 'Construye un crédito.' },
        {
            icon: tarjetaCheck,
            label: 'Pagas al momento que corte tu tarjeta de crédito.',
        },
    ];

    return (
        <>
            <Grid container item xs={12}>
                <Grid container xs={12}>
                    <CoverSection />
                </Grid>
                <Grid container item xs={12} justify="center">
                    <Grid container item xs={8} className={classes.Labels}>
                        <Grid item xs={4}>
                            <Typography>+Fácil</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>+Rápido</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>+Seguro</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justify="space-around">
                    {!matches600pxDown && (
                        <Grid
                            container
                            item
                            xl={8}
                            lg={8}
                            md={10}
                            sm={11}
                            sm={11}
                            style={{ margin: '3vh 0px' }}
                            justify="space-around"
                        >
                            {infoIcon.map(x => (
                                <>
                                    <Grid
                                        item
                                        xs={2}
                                        justify="center"
                                        className={classes.iconText}
                                    >
                                        <img src={x.icon} />
                                        <Typography
                                            style={{
                                                lineHeight: '1.2',
                                                fontSize: '1.2em',
                                            }}
                                        >
                                            {x.label}
                                        </Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    )}

                    {matches600pxDown && (
                        <Carousel
                            style={{ margin: '1vh 0px' }}
                            className={classes.carrusel}
                            width={'60vw'}
                            infiniteLoop={true}
                            showStatus={false}
                            showThumbs={false}
                            autoPlay={true}
                            interval={3000}
                            centerMode={true}
                        >
                            {infoIcon.map(x => (
                                <>
                                    <div className="content_slide">
                                        <img src={x.icon} width="100px" />
                                        <p className="legend">{x.label}</p>
                                    </div>
                                </>
                            ))}
                        </Carousel>
                    )}
                </Grid>

                <Grid container item xs={12} justify="center">
                    <Button className={classes.button_red} href="/register">
                        Regístrate
                    </Button>
                </Grid>

                {!matches700pxDown && (
                    <Grid container item xs={12} justify="center">
                        <Grid
                            container
                            item
                            lg={10}
                            md={10}
                            sm={12}
                            xs={12}
                            justify="center"
                            className={classes.cardSteps}
                        >
                            <Grid item xs={12} justify="center">
                                <Typography
                                    align="center"
                                    className={classes.cart_title}
                                >
                                    ¡Aquí te explicamos cómo funciona!
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={10}
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.step_content_item}
                                >
                                    <img
                                        src={step1}
                                        className={classes.step_img}
                                    />
                                    <Typography className={classes.step_text}>
                                        Accede a la plataforma de Pago y
                                        "Regístrate"
                                    </Typography>
                                </Grid>
                                {!matches600pxDown && (
                                    <Grid item xs={2} className={classes.line}>
                                        ---------------
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.step_content_item}
                                >
                                    <img
                                        src={step2}
                                        className={classes.step_img}
                                    />
                                    <Typography className={classes.step_text}>
                                        Agrega los datos del inmueble.
                                    </Typography>
                                </Grid>
                                {!matches600pxDown && (
                                    <Grid item xs={2} className={classes.line}>
                                        ---------------
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.step_content_item}
                                >
                                    <img
                                        src={step3}
                                        className={classes.step_img}
                                    />
                                    <Typography className={classes.step_text}>
                                        Confirma todos los datos y listo ¡Paga y
                                        recibe pagos de tu alquiler!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {matches700pxDown && (
                    <Grid
                        container
                        item
                        xs={12}
                        justify="center"
                        className={classes.cardSteps}
                        spacing={2}
                    >
                        <Grid item xs={12} justify="center">
                            <Typography
                                align="center"
                                className={classes.cart_title}
                            >
                                ¡Aquí te explicamos cómo funciona!
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={11}
                            justify="space-around"
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                <img src={step1} className={classes.step_img} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography className={classes.step_text}>
                                    Accede a la plataforma de Pago y
                                    "Regístrate"
                                </Typography>
                            </Grid>
                        </Grid>
                        {/*  */}
                        <Grid
                            container
                            item
                            xs={11}
                            justify="space-around"
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                <img src={step2} className={classes.step_img} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography className={classes.step_text}>
                                    Agrega los datos del inmueble.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/*  */}
                        <Grid
                            container
                            item
                            xs={11}
                            justify="space-around"
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                <img src={step3} className={classes.step_img} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography className={classes.step_text}>
                                    Confirma todos los datos y listo ¡Paga y
                                    recibe pagos de tu alquiler!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    style={{ margin: '15px 0px' }}
                >
                    <Grid
                        container
                        item
                        xs={10}
                        lg={8}
                        md={8}
                        lx={8}
                        sm={10}
                        justify="space-around"
                    >
                        <Grid
                            xs={12}
                            lg={3}
                            md={3}
                            lx={3}
                            sm={12}
                            className={classes.cards_Descriptions}
                        >
                            <Typography className="text">
                                Sistema respaldado por
                            </Typography>
                            <img src={remaxrd_azul} alt="" srcset="" />
                        </Grid>
                        <Grid
                            xs={12}
                            lg={3}
                            md={3}
                            lx={3}
                            sm={12}
                            className={classes.cards_Descriptions}
                        >
                            <Typography className="text">
                                Se cobra una tarifa por el servicio.
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            lg={3}
                            md={3}
                            lx={3}
                            sm={12}
                            className={classes.cards_Descriptions}
                        >
                            <Typography className="text">
                                Inicialmente solo pagos entre personas físicas.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    justify="center"
                    xs={12}
                    style={{ margin: '15px 0px' }}
                >
                    <Typography className={classes.message}>
                        Sin demoras en tu alquiler. Paga y cobra por RE/MAX PAY.
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="center">
                    <Button className={classes.button_red} href="/register">
                        Regístrate
                    </Button>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={11} className={classes.hasQuestions}>
                        <Typography className="title">
                            ¿Tienes dudas o necesitas más información?
                        </Typography>
                        <Typography className="body">
                            Escríbenos a
                            <span className="ref"> info@remaxrd.com</span> y te
                            explicaremos como funciona.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Footer></Footer>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
