import * as React from 'react';
import * as intl from 'react-intl-universal';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
    CssBaseline,
    Typography,
    Theme,
    createStyles,
    withStyles,
    CircularProgress,
} from '@material-ui/core';
import { themeConfig } from 'app/theme';
import { Panel } from 'app/ui';
import { connect } from 'react-redux';
import { paymentsService } from '../../service/payments';
import storage from 'app/helpers/storage';

const styles = (theme: Theme) =>
    createStyles({
        panel: {
            marginTop: theme.spacing(8),
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            width: 400,
            textAlign: 'center',
            [theme.breakpoints.down(460)]: {
                width: 320,
            },
        },
        body: {
            wordBreak: 'break-all',
        },
        icon: {
            marginBottom: 30,
        },
        success: {
            color: themeConfig.palette.success,
        },
        warning: {
            color: themeConfig.palette.warning,
        },
        textColor: {
            color: 'blue',
        },
        typography: {
            width: '50',
        },
    });
export interface ParametersProps {
    loading: boolean;
    error: any;
    location: string;
    URLSearchParams: string[];
    statusCode: string;
    orderNumber: string;
}

'{ (regexp: string | RegExp): number; (searcher: { [Symbol.search](string: string): number; }): number; }';
class ConfirmTransaction extends React.Component<ParametersProps> {
    public constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            location: '',
            URLSearchParams: [],
            orderId: '',
            statusCode: '',
            orderNumber: '',
        };
    }
    public async componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);

        this.setState({
            statusCode: params.get('IsoCode'),
            loading: false,
            orderId: params.get('AzulOrderId'),
            error: 'Tarjeta Declinada',
            orderNumber: params.get('OrderNumber'),
        });

        //formatting the date.
        let date = new Date();
        let dateFormated = date
            .toLocaleDateString('en-GB', {
                // you can use undefined as first argument
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
            .replace('/', '-')
            .replace('/', '-');
        const savePayment = paymentsService.savePaymentTransaction(
            params.get('Amount'),
            dateFormated,
            params.get('ResponseMessage'),
            params.get('RRN'),
            params.get('CardNumber'),
            storage.get('user')?.id !== undefined
                ? storage.get('user')?.id
                : '',
            params.get('AzulOrderId'),
            storage.get('token') !== undefined ? params.get('token') : '',
            params.get('affiliate'),
            params.get('property_id'),
        );
    }
    public render() {
        const { classes } = this.props;
        const { error, loading, statusCode, orderId, orderNumber } = this.state;
        return (
            <main>
                <CssBaseline />
                <Panel variant={'primary'} className={classes.panel}>
                    {loading && (
                        <div>
                            <Typography
                                gutterBottom={true}
                                component="h1"
                                variant="h6"
                            >
                                Procesando transacción...
                                <br />
                                <CircularProgress />
                            </Typography>
                        </div>
                    )}
                    {!loading && orderNumber === null && (
                        <div>
                            <i
                                className={classNames(
                                    'fa fa-times-circle fa-5x',
                                    classes.icon,
                                    classes.warning,
                                )}
                            />
                            <Typography
                                gutterBottom={true}
                                component="h1"
                                variant="h6"
                            >
                                Acción Cancelada
                                <br />
                            </Typography>
                            <Link className={classes.textColor} to="/">
                                Regresar al inicio
                            </Link>
                        </div>
                    )}
                    {!loading &&
                        statusCode !== '00' &&
                        orderNumber !== '' &&
                        orderNumber !== null && (
                            <div>
                                <i
                                    className={classNames(
                                        'fa fa-exclamation-triangle fa-5x',
                                        classes.icon,
                                        classes.warning,
                                    )}
                                />
                                <Typography
                                    gutterBottom={true}
                                    variant="h6"
                                    className={classes.body}
                                >
                                    {intl.get('statusCode.rejected')}
                                    <br />
                                </Typography>
                                <Typography
                                    gutterBottom={true}
                                    variant="body1"
                                    className={classes.typography}
                                >
                                    {intl.get('subStatusCode.rejected')}
                                </Typography>
                                <br />
                                <Link className={classes.textColor} to="/">
                                    Regresar
                                </Link>
                            </div>
                        )}

                    {!loading &&
                        statusCode === '00' &&
                        orderNumber !== '' &&
                        orderNumber !== null && (
                            <div>
                                <i
                                    className={classNames(
                                        'fa fa-check-circle fa-5x',
                                        classes.icon,
                                        classes.success,
                                    )}
                                />
                                <Typography
                                    gutterBottom={true}
                                    component="h1"
                                    variant="h6"
                                >
                                    {intl.get('statusCode.accepted')}
                                </Typography>
                            </div>
                        )}
                    {!loading &&
                        statusCode === '00' &&
                        orderNumber !== null &&
                        orderNumber !== '' && (
                            <Typography variant="body1">
                                {intl.get('subStatusCode.accepted')} {'#'}
                                {orderId}
                                <br />
                                <br />
                                <Link className={classes.textColor} to="/">
                                    Regresar
                                </Link>
                            </Typography>
                        )}
                </Panel>
            </main>
        );
    }
}

export default connect(null, null)(withStyles(styles)(ConfirmTransaction));
