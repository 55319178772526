import { Config } from './types';

export const config: Config = {
    title: 'admin.brand',
    logoUri: '/images/remax_azul.png',
    resourceBaseUri: APP_BASEHREF || '/',
    defaultLocale: 'en-US', // empty to use navigator language
    locales: [
        {
            name: 'English',
            value: 'en-US',
            // uncomment that will load locale file(file name format: en-US.json) via ajax
            messages: require('../../assets/locales/json/en-US.json'),
        },
    ],
};
