import * as React from 'react';
// import * as intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';

import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import logo from '/assets/images/remax_azul.png';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { actions as authActions } from 'app/service/auth';
import { actions as globalActions } from 'app/service/global';
import { Link } from '@material-ui/core';
import {
    LoginComponentProps,
    LoginComponentState,
} from './types/RegisterTypes';

const styles = (theme: Theme) =>
    createStyles({
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(6))]: {
                width: 400,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)}px ${theme.spacing(
                3,
            )}px ${theme.spacing(3)}px`,
        },
        avatar: {
            margin: theme.spacing(),
            backgroundColor: theme.palette.secondary.main,
            width: 100,
            height: 100,
            fontSize: 110,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(),
        },
        submit: {
            marginTop: theme.spacing(3),
        },
        rounded: {
            width: '70%',
            margin: '40px 0 40px 0',
        },
    });

class Login extends React.Component<LoginComponentProps, LoginComponentState> {
    public constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
        this.onLogin = this.onLogin.bind(this);
    }

    public render() {
        const { classes } = this.props;
        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <img alt="Remax" src={logo} className={classes.rounded} />
                    <br />
                    <Typography component="h1" variant="h5">
                        {'Iniciar sesión'}
                    </Typography>
                    <form className={classes.form}>
                        <FormControl
                            margin="normal"
                            required={true}
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="email">
                                {'Ingresa tu correo'}
                            </InputLabel>
                            <Input
                                id="email"
                                name="email"
                                type="text"
                                autoComplete="email"
                                autoFocus={true}
                                onChange={e => {
                                    this.setState({ email: e.target.value });
                                }}
                            />
                        </FormControl>
                        <FormControl
                            margin="normal"
                            required={true}
                            fullWidth={true}
                        >
                            <InputLabel htmlFor="password">
                                {'Contraseña'}
                            </InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={e => {
                                    this.setState({ password: e.target.value });
                                }}
                            />
                        </FormControl>
                        <Link href="/cambiar-contraseña">
                            Olvidé mi contraseña
                        </Link>
                        <Button
                            type="submit"
                            fullWidth={true}
                            variant="contained"
                            style={{ backgroundColor: '#003DA5' }}
                            className={classes.submit}
                            onClick={this.onLogin}
                        >
                            <span style={{ color: 'white' }}>{'Entrar'}</span>
                        </Button>
                        <br />
                        <br />
                        <Link href="/register">
                            ¿No estás registrado? Haz click acá
                        </Link>
                    </form>
                </Paper>
            </main>
        );
    }

    private onLogin(event) {
        this.props.onLogin(this.state.email, this.state.password);
        event.preventDefault();
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onLogin: (email: string, password: string): boolean => {
        if (!email || !password) {
            dispatch(
                globalActions.notify({
                    message: 'Por favor ingresa tu usuario y contraseña',
                    variant: 'error',
                    placement: 'bottom center',
                }),
            );
            return false;
        }
        dispatch(authActions.login({ email, password }));
        return true;
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Login));
