import * as React from 'react';
import {
    createStyles,
    withStyles,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
    DialogContent,
} from '@material-ui/core';

import Slide from '@mui/material/Slide';

const styles = createStyles({
    root: {
        marginTop: 100,
        width: 600,
        margin: 'auto',
        textAlign: 'center',
    },
    dialogPaper: {
        backgroundColor: '#007DC3',
        color: '#ffffff',
    },
    button: {
        color: 'white',
    },
    content: {
        color: 'white',
        fontSize: '1.6rem',
        textAlign: 'center',
        fontWeight: 500,
        lineHeight: 'normal',
        fontFamily: 'Roboto, Helvetica',
    },
    title: {
        '& h2': {
            fontSize: '25px',
            textAlign: 'center',
        },
    },
});

class Advice extends React.Component<{
    titleMessage?: string;
    body?: string;
    subBody?: string;
    hasActions?: boolean;
}> {
    private redirectTo(path) {
        location.href = path;
    }

    public render() {
        const { classes, body, hasActions, subBody, titleMessage } = this.props;
        return (
            <div>
                <Dialog
                    open={true}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ classes: { root: classes.dialogPaper } }}
                >
                    {titleMessage && (
                        <DialogTitle className={classes.title}>
                            {titleMessage}
                        </DialogTitle>
                    )}
                    <DialogContent>
                        <DialogContentText
                            className={classes.content}
                            id="alert-dialog-slide-description"
                        >
                            {body}
                        </DialogContentText>
                        <DialogContentText
                            className={classes.content}
                            id="alert-dialog-slide-description"
                        >
                            {subBody}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {hasActions && (
                            <Button
                                className={classes.button}
                                onClick={() =>
                                    this.redirectTo('/completar-datos')
                                }
                            >
                                Completar campos
                            </Button>
                        )}
                        <Button
                            className={classes.button}
                            onClick={() => this.redirectTo('/')}
                        >
                            Regresar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(Advice);
