import ajax from 'app/helpers/ajax';
import axios, { AxiosResponse } from 'axios';
import ApiConstants from '../config/api.constants';

export const config = {
    headers: {
        // 'Content-Type': 'application/json',
        'X-API-KEY': ApiConstants.X_API_KEY,
    },
};
export interface PromiseProps {
    data: any;
    status: unknown;
}

export const paymentsService = {
    getPayments: async (): Promise<PromiseProps> => {
        let res;
        await axios
            .get(ApiConstants.GET_PAYMENTS_ENDPOINT, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getPaymentsById: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.GET_TENANT_BY_ID_ENDPOINT, { id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getTenantsPayments: async (id, email): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.GET_TENANT_PAYMENTS_ENDPOINT,
                { id, email },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },

    getTenantsOwnerPayments: async (id, ownerId): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.GET_OWNER_PAYMENTS_ENDPOINT,
                { id, ownerId },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getUsers: async (init, page, perPage, search): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.GET_USERS_PAGINATED_ENDPOINT,
                { init, page, perPage, search },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    cancelPayment: async (token): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.CANCEL_PAYMENT_ENDPOINT + token, '', config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    downloadCSVFromAPI(id) {
        let formData = new FormData();
        formData.append('id', id);
        fetch(ApiConstants.DOWNLOAD_CSV_FROM_API, {
            method: 'POST',
            headers: new Headers({
                'X-API-KEY': ApiConstants.X_API_KEY,
            }),
            body: formData,
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'información_de_pagos.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    },
    changePaymentStatus: async (
        id,
        transaction_date,
    ): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.CHANGE_PAYMENT_STATUS_ENDPOINT,
                { id, transaction_date },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    activatePayment: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.SET_ACTIVATE_PAYMENTS_ENDPOINT, { id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },

    findPaymentInfo: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.FIND_PAYMENT_INFO_ENDPOINT, { id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    findPropertyInfo: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.FIND_PROPERTY_INFO_ENDPOINT, { id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    checkAdmin: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.CHECK_IS_USER_ADMIN, { id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    checkUserCanPay: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.CHECK_IS_USER_CAN_PAY, { id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
};
