import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { paymentsService } from 'app/service/payments';
import { CircularProgress } from '@material-ui/core';
import { PropsParameters } from '../types/IPayments';
const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

const products = [
    {
        name: 'dummy',
    },
];

export default function ReviewLink(props: PropsParameters) {
    const [detail, setDetail] = React.useState(null);
    const [ErrorMessage, setErrorMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    async function getDetailsLink(token) {
        const details = await paymentsService.getPaymentDetailsByToken(token);
        if (details.status === 200) {
            if (details.data.data === null) {
                window.location.href = '/inicio';
                return;
            }
            props.checkExpDate(details.data.data.payment_link_expiration);
            props.checkLinkStatus(details.data.isUsed);
            let totalAmount;
            if (details.data.data !== null) {
                totalAmount = parseFloat(details.data.data['Monto']).toFixed(2);
                props.setTotalAmount(parseFloat(totalAmount));
                props.checkIsValidLink(true);
            }
            setDetail(details.data.data);
            setLoading(false);
        }

        if (details.response.status === 403) {
            setErrorMessage(details.response.data.message);
            props.checkIsValidLink(false);
            setLoading(false);
            return;
        }

        props.setValidate(false);
    }
    useEffect(() => {
        let params = new URLSearchParams(props.history.location.search);
        if (
            params.get('token') !== undefined &&
            params.get('token') !== null &&
            params.get('token') !== ''
        ) {
            props.setToken(params.get('token'));
            getDetailsLink(params.get('token'));
        } else {
            window.location.href = '/inicio';
        }
    }, []);
    const classes = useStyles('paper');
    function RenderDetails(): JSX.Element | '' {
        return (
            loading === false &&
            detail && (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                        Detalles de Pago
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <ListItemText
                                primary={detail['Tipo de Propiedad']}
                            />
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <ListItemText
                                primary={
                                    <>
                                        <Typography variant="body1">
                                            <strong>Alquiler: </strong>
                                            {'RD$' +
                                                detail['Monto']
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ',',
                                                    )}
                                        </Typography>
                                    </>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.title}
                            >
                                Dirección de la Propiedad
                            </Typography>
                            <Typography
                                gutterBottom
                                style={{
                                    wordWrap: 'break-word',
                                    textAlign: 'justify',
                                }}
                            >
                                {detail['Dirección Propiedad']}
                            </Typography>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.title}
                            >
                                Detalles del Propietario
                            </Typography>
                            <Grid container>
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Nombre:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            {detail['Nombre Prop.']}{' '}
                                            {detail['Apellido Prop.']}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        );
    }
    return (
        <>
            {(detail !== null && RenderDetails()) || ''}
            {loading === true && (
                <React.Fragment>
                    <br />
                    <center>
                        <Typography variant="h6" gutterBottom>
                            Cargando datos...
                        </Typography>
                        <br />
                        <CircularProgress />
                    </center>
                </React.Fragment>
            )}
            {ErrorMessage && (
                <React.Fragment>
                    <br />
                    <center>
                        <Typography variant="h6" gutterBottom>
                            {ErrorMessage}
                        </Typography>
                    </center>
                </React.Fragment>
            )}
        </>
    );
}
