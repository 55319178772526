import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dropzone from './components/Dropzone';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    InputAdornment,
    InputLabel,
    makeStyles,
    Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { publicService } from 'app/service/public';
import { propertiesService } from 'app/service/properties';
import OptionType from './types/IProperties';
import {
    propertyTypes,
    businessTypes,
    currencyTypes,
} from './types/IProperties';
import Select from 'react-select';
import storage from 'app/helpers/storage';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { red, green } from '@material-ui/core/colors';
import { CalendarToday } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { loading } from '@bndynet/dialog';
import LeavingPageAdvice from './components/LeavingPageAdvice';
import { goBack } from 'connected-react-router';
interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    setState: Function;
}
const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    typography: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    icon: {
        margin: theme.spacing(2),
    },
    dialogError: {
        textAlign: 'center',
    },
    avatarFailure: {
        backgroundColor: red[400],
        color: 'white',
        textAlign: 'center',
        marginTop: 10,
        width: 120,
        height: 120,
        margin: '0 auto',
    },
    dialog: {
        backgroundImage: `url("${require('../../../assets/images/owner-dialog-background.jpg')}")`,
        backgroundSize: '960px 540px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
        height: '540px',
        [theme.breakpoints.down(996)]: {
            backgroundImage: `url("${require('../../../assets/images/owner-dialog-background.jpg')}")`,
            backgroundSize: '448px 281px',
            height: '31vh',
            minHeight: '281px',
        },
        [theme.breakpoints.down(519)]: {
            backgroundImage: `none`,
        },
        [theme.breakpoints.down(420)]: {
            backgroundImage: `none`,
        },
    },
    typographyOne: {
        fontSize: '2.30rem',
        fontFamily: 'Roboto, Helvetica',
        fontWeight: 'bold',
        marginTop: '100px',
        marginLeft: '30px',
        [theme.breakpoints.down(996)]: {
            fontSize: '1.25rem',
            marginTop: '20px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            marginLeft: '0px',
            fontSize: '1.2rem',
            textAlign: 'center',
        },
    },
    typographyTwo: {
        fontSize: '1.70rem',
        fontFamily: 'Roboto, Helvetica',
        marginLeft: '30px',
        fontWeight: 400,
        [theme.breakpoints.down(996)]: {
            fontSize: '1.05rem',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            // display: 'none',
            fontSize: '0.90rem',
            textAlign: 'center',
        },
    },
    link: {
        fontSize: '1.25rem',
        fontFamily: 'Roboto, Helvetica',
        marginLeft: '30px',
        fontWeight: 400,
        [theme.breakpoints.down(996)]: {
            fontSize: '0.95rem',
            fontFamily: 'Roboto, Helvetica',
            fontWeight: 400,
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            fontSize: '0.70rem',
            textAlign: 'center',
            marginLeft: '13%',
        },
        [theme.breakpoints.down(380)]: {
            marginLeft: '7%',
            fontSize: '0.75rem',
        },
    },
    dialogPaper: {
        [theme.breakpoints.down(996)]: {
            maxWidth: '426px',
            minHeight: '244px',
        },
    },
    backButton: {
        marginLeft: '30px',
        borderRadius: '25px',
        marginTop: '50px',
        width: '100px',
        border: '3px solid #007dc3',
        color: '#007dc3',
        [theme.breakpoints.down(996)]: {
            width: '80px',
            border: '2px solid #007dc3',
            marginTop: '15px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            left: '32%',
        },
        [theme.breakpoints.down(380)]: {
            marginBottom: '50px',
            left: '28%',
        },
    },
    backButtonProperties: {
        marginLeft: '30px',
        borderRadius: '25px',
        marginTop: '50px',
        width: '100px',
        border: '3px solid #007dc3',
        color: '#007dc3',
        fontSize: '.85rem',
        [theme.breakpoints.down(996)]: {
            width: '80px',
            border: '2px solid #007dc3',
            marginTop: '15px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            left: '32%',
        },
        [theme.breakpoints.down(380)]: {
            marginBottom: '50px',
            left: '28%',
        },
    },
    iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
            color: red[800],
        },
    },
}));
const getDate = new Date();

export default function EditProperty(props) {
    const [sector, setSector] = React.useState('');
    const [sectorData, setSectorData] = React.useState();
    const [city, setCity] = React.useState();
    const [cityName, setCityName] = React.useState();
    const [citiesData, setCitiesData] = React.useState([]);
    const [rawSectors, setRawSectors] = React.useState([]);
    const [bedrooms, setBedrooms] = React.useState('');
    const [bathrooms, setBathrooms] = React.useState('');
    const [activeSector, setActiveSector] = React.useState('');
    const [meters, setMeters] = React.useState('');
    const [parking, setParking] = React.useState('');
    const [sectorId, setSectorId] = React.useState(0);
    const [address, setAddress] = React.useState('');
    const [business, setBusiness] = React.useState('');
    const [businessId, setBusinessId] = React.useState(0);
    const [types, setTypes] = React.useState('');
    const [typesId, setTypesId] = React.useState(0);
    const [price, setPrice] = React.useState('');
    const [currency, setCurrency] = React.useState(0);
    const [currencyId, setCurrencyId] = React.useState(1); // Set currency just for RD$
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailure, setOpenFailure] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [revenue, setRevenue] = React.useState(null);
    const [payday, setPayday] = React.useState(null);
    const [loader, setLoader] = React.useState(true);
    const [id, setId] = React.useState(0);
    const [propertyId, setPropertyId] = React.useState(0);
    const [mainId, setMainId] = React.useState(0);
    const [contract, setContract] = React.useState<any[]>([]);
    const [files, setFiles] = React.useState([]);
    const [formField, SetFormField] = React.useState();

    //Labels for types
    const [propertyName, setPropertyName] = React.useState('');
    const [businessName, setBusinessName] = React.useState('');
    const [sectorName, setSectorName] = React.useState('');
    const [state, setState] = React.useState({});
    // check if state has changed
    const [back, setBack] = React.useState(true);
    //adding localeMap to config format
    const localeMap = {
        es: esLocale,
    };

    function setPropertyType(value) {
        setTypes({ name: value.label, label: value.label });
        setTypesId(value.id);
        setPropertyName(value.label);
    }

    function setBusinessType(value) {
        setBusiness({ name: value.label, label: value.label });
        setBusinessId(value.id);
        // setBusinessName(value.label);
    }

    function setCurrencyType(value) {
        setCurrency({ name: value.label, label: value.label });
        setCurrencyId(value.id);
    }

    async function getSectorsData() {
        const sectorsData = await publicService.getSectors();
        return sectorsData.data;
    }

    function NumberFormatCustom(props: NumberFormatCustomProps) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
                prefix="RD$"
            />
        );
    }

    function fieldsFilled() {
        if (
            (typesId !== 0 &&
                sectorId !== 0 &&
                businessId !== 0 &&
                address !== '' &&
                parking !== '' &&
                bathrooms !== '' &&
                bedrooms !== '' &&
                price !== '' &&
                currencyId !== 0 &&
                meters !== '' &&
                propertyName !== '' &&
                cityName !== '' &&
                sectorName !== '' &&
                businessName !== '' &&
                revenue !== null &&
                payday !== null &&
                contract.length > 0) ||
            files.length > 0
        ) {
            setSubmitDisabled(false);
            return [
                true,
                {
                    typesId,
                    sectorId,
                    businessId,
                    address,
                    parking,
                    bathrooms,
                    bedrooms,
                    price,
                    currencyId,
                    meters,
                    propertyName,
                    businessName,
                    cityName,
                    sectorName,
                    revenue,
                    payday,
                    propertyId,
                    contract,
                },
            ];
        } else {
            setSubmitDisabled(true);
        }
    }
    async function getCitiesData() {
        const sectors = await getSectorsData();
        setRawSectors(sectors);
        setCitiesData(
            sectors.map(({ id, name }) => ({
                id: id,
                city: name,
            })),
        );
        return;
    }

    function handleSelect(e) {
        setCity(e);
        setCityName(e.value);
        setSector('Cargando...');
        setTimeout(function() {
            rawSectors.map(data => {
                if (
                    data.id === e.value ||
                    data.cities.find(x => x.id == e.value)
                ) {
                    const sectors: OptionType[] = data.cities
                        .map(x => x.sectors)
                        .flat()
                        .map(data => ({
                            value: data.id,
                            label: data.name,
                            id: data.id,
                        }));

                    //Uses to organize alphabetically the select options
                    sectors.sort(function(a, b) {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    });
                    setSectorData(sectors);
                }
            });
        }, 500);
    }

    /*Get all values from property through property id*/
    async function getEditFieldsData() {
        const urlParams = new URLSearchParams(props.location.search);
        let id = urlParams.get('id');
        let userId = storage.get('user').id;
        setId(id);
        const propertyData = await propertiesService.getEditFieldsData(
            id,
            userId,
        );
        let fields = propertyData.data.data;
        SetFormField(fields);
        setSectorId(fields.sectorId);

        /*Set currency data */
        setCurrency({ name: fields.currency, label: fields.currency });
        setCurrencyId(fields.currencyId);

        /*Set price */
        setPrice(fields.price.replace(/[^0-9\.]+/g, ''));

        /*Set propertyType */
        setTypes({
            name: fields.realestateType,
            label: fields.realestateType,
        });
        setTypesId(fields.realestateTypeId);

        setPropertyName({
            name: fields.propertyTypeName,
            label: fields.propertyTypeName,
        });

        /*Set businessType */
        setBusiness({
            name: fields.businessType,
            label: fields.businessType,
        });
        setBusinessId(fields.businessTypeId);
        setBusinessName(fields.businessType);
        /*Set cities and Sector values */
        setCity({ value: fields.cityId, label: fields.city });
        setCityName(fields.city);
        setSector({ value: fields.sectorId, label: fields.sector });
        setSectorName(fields.sector);

        /*Set parking slots , meters and more*/
        setBedrooms(fields.bedrooms);
        setBathrooms(fields.bathrooms);
        setMeters(fields.sqmConstruction.replace('.00', ''));
        setParking(fields.parkingSpots);
        setAddress(fields.address);

        /*Set parking revenue date, */
        setRevenue(new Date(fields.revenueDate));
        /*Set payday date*/
        setPayday(new Date(fields.payDay));
        /*property id */
        setPropertyId(fields.propertyId);
        /*main record property id */
        setMainId(fields.id);
        setFiles([
            {
                name: fields.rentalDocument,
                path: fields.rentalDocument,
                preview: fields.documentPreview,
                type: '',
            },
        ]);
    }

    useEffect(() => {
        /*Check if fields aren't modified */
        /*get all fields to edit data*/
        setSubmitDisabled(false);
        fieldsFilled();
        if (loader) {
            getEditFieldsData();
            getCitiesData();
            setLoader(false);
        }
    });
    useEffect(() => {
        document.getElementById('pages').scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (formField && rawSectors.length > 0) {
            handleSelect({ value: formField.cityId, label: formField.city });
            setSector({ value: formField.sectorId, label: formField.sector });
        }
    }, [formField, rawSectors]);

    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: '#ffffff',
            cursor: 'pointer',
        }),
        option: () => {
            return {
                backgroundColor: '#ffffff',
                cursor: 'pointer',
            };
        },
    };

    const cities: OptionType[] = citiesData.map(element => ({
        value: element.id,
        label: element.city,
    }));
    // toggle modal
    function handleClickOpenSuccess() {
        setOpenSuccess(true);
        setBack(true);
    }

    function handleClickOpenFail() {
        setOpenFailure(true);
        setBack(true);
    }

    function handleCloseFailure() {
        setOpenFailure(false);
    }
    function handleCloseSuccess() {
        setOpenSuccess(false);
    }

    //Modal rendering
    const SuccessDialog = () => {
        return (
            <Dialog
                open={openSuccess}
                onClose={handleCloseSuccess}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                PaperProps={{ classes: { root: classes.dialogPaper } }}
                maxWidth={'md'}
            >
                <DialogContent className={classes.dialog}>
                    <h3 className={classes.typographyOne}>
                        ¡Propiedad editada <br /> satisfactoriamente!
                    </h3>
                    <h3 className={classes.typographyTwo}>
                        Puedes consultar tus propiedades <br />
                        creadas en este enlace: <br />
                    </h3>
                    <Link
                        className={classes.link}
                        to="/propiedades"
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                        }}
                    >
                        Ver mis propiedades registradas
                    </Link>
                    <br />
                    <Button
                        className={classes.backButton}
                        onClick={handleCloseSuccess}
                        variant="outlined"
                    >
                        Volver
                    </Button>
                </DialogContent>
            </Dialog>
        );
    };

    //Modal rendering
    const ErrorDialog = () => {
        return (
            <div>
                <Dialog
                    open={openFailure}
                    className={classes.dialogError}
                    onClose={handleCloseFailure}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <Avatar className={classes.avatarFailure}>
                        <ErrorIcon style={{ fontSize: '40' }} />
                    </Avatar>
                    <DialogTitle id="alert-dialog-title">
                        {'Error al crear la propiedad'}
                    </DialogTitle>
                    <DialogContent className={classes.dialogError}>
                        <DialogContentText id="alert-dialog-description">
                            No se pudo crear la propiedad. Favor intentar mas
                            tarde
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseFailure}
                            color="primary"
                            autoFocus
                        >
                            Volver
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    async function onPropertyEdit() {
        loading();
        const data = fieldsFilled();
        if (data[0]) {
            const response = await propertiesService.editProperty(data[1]);
            if (response['status'] === 200) {
                getEditFieldsData();
                handleClickOpenSuccess();
                loading(false);
            } else {
                loading(false);
                handleClickOpenFail();
            }
            setBack(true);
        } else {
            loading(false);
            handleClickOpenFail();
            setBack(true);
        }
        getEditFieldsData();
        setBack(true);
    }

    async function getSectorId(values) {
        setSectorName(values.label);
        setSector(values);
        setSectorId(values.id);
    }

    const RenderSectorsSelect = () => {
        return (
            <Grid item xs={12} md={4}>
                <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                <Select
                    inputId="react-select-single"
                    styles={colourStyles}
                    TextFieldProps={{
                        label: 'Sector',
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    value={sector}
                    onChange={values => {
                        getSectorId(values);
                        setBack(false);
                    }}
                    options={sectorData}
                    placeholder="Escoge un sector"
                />
            </Grid>
        );
    };

    const RenderCitySelect = () => {
        return (
            <Grid item xs={12} md={4}>
                <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
                <Select
                    inputId="react-select-single"
                    styles={colourStyles}
                    TextFieldProps={{
                        label: 'Ciudad',
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    value={city}
                    onChange={e => {
                        handleSelect(e);
                        setBack(false);
                    }}
                    options={cities}
                    placeholder="Escoge una ciudad"
                />
            </Grid>
        );
    };

    function handleValidations(value, name) {
        setBack(false);
        switch (name) {
            case 'parking':
                if (value.match(/^[0-9]*$/)) {
                    setParking(value);
                }
                break;
            case 'bedrooms':
                if (value.match(/^[0-9]*$/)) {
                    setBedrooms(value);
                }
                break;
            case 'bathrooms':
                if (value.match(/^[0-9]*$/)) {
                    setBathrooms(value);
                }
                break;
            case 'meters':
                if (value.match(/^[0-9]*$/)) {
                    setMeters(value);
                }
                break;
            case 'price':
                if (value.match(/^[0-9]*$/)) {
                    setMeters(value);
                }
                break;

            default:
                break;
        }
    }
    const classes = useStyles('paper');
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Typography
                    className={classes.typography}
                    variant="h5"
                    gutterBottom
                >
                    Editar propiedad - {id}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={6}>
                        <InputLabel id="demo-simple-select-label">
                            Precio del alquiler (RD$) *
                        </InputLabel>
                        <TextField
                            id="price"
                            label=""
                            fullWidth
                            value={price}
                            onBlur={e => {
                                setPrice(
                                    e.target.value.replace(/[^0-9\.]+/g, ''),
                                );
                                setBack(false);
                            }}
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                        <InputLabel id="demo-simple-select-label">
                            Tipo de Propiedad
                        </InputLabel>
                        <Select
                            id="demo-simple-select"
                            styles={colourStyles}
                            value={types}
                            onChange={values => {
                                setPropertyType(values);
                                setBack(false);
                            }}
                            options={propertyTypes}
                            placeholder="Escoge un tipo *"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={6}>
                        <InputLabel id="demo-simple-select-label">
                            Tipo de Alquiler
                        </InputLabel>
                        <Select
                            id="demo-simple-select"
                            styles={colourStyles}
                            value={business}
                            onChange={values => {
                                setBusinessType(values);
                                setBack(false);
                            }}
                            options={businessTypes}
                            placeholder="Escoge un tipo *"
                        />
                    </Grid>
                    {RenderCitySelect()}
                    {RenderSectorsSelect()}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="meters"
                            required
                            label="MTS² de construcción"
                            fullWidth
                            value={meters}
                            onChange={e =>
                                handleValidations(e.target.value, 'meters')
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            id="bedrooms"
                            label="Habitaciones *"
                            value={bedrooms}
                            fullWidth
                            onChange={e =>
                                handleValidations(e.target.value, 'bedrooms')
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            id="bathrooms"
                            label="Baños *"
                            value={bathrooms}
                            fullWidth
                            onChange={e =>
                                handleValidations(e.target.value, 'bathrooms')
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            id="parking"
                            label="Parqueos *"
                            value={parking}
                            fullWidth
                            onChange={e =>
                                handleValidations(e.target.value, 'parking')
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            id="address"
                            label="Dirección *"
                            fullWidth
                            value={address}
                            onChange={e => {
                                setAddress(e.target.value);
                                setBack(false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Contrato de alquiler
                        </Typography>
                        <Dropzone
                            setContract={setContract}
                            setFiles={setFiles}
                            contract={contract}
                            files={files}
                            setBack={setBack}
                            fieldsFilled={fieldsFilled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                        <Typography variant="body1" gutterBottom>
                            Fecha de renovación de contrato
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            style={{ cursor: 'pointer' }}
                            locale={localeMap['es']}
                        >
                            <DatePicker
                                disablePast
                                required
                                openTo="year"
                                views={['year', 'month', 'date']}
                                value={revenue}
                                style={{ cursor: 'pointer' }}
                                format="dd/MM/yyyy"
                                label="Escoge una fecha"
                                id="date-picker-inline"
                                onChange={e => {
                                    setRevenue(e);
                                    setBack(false);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <Icon>
                                                <CalendarToday />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                            Día de pago del alquiler
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            style={{ cursor: 'pointer' }}
                            locale={localeMap['es']}
                        >
                            <DatePicker
                                required
                                value={payday}
                                format="dd"
                                id="date-picker-inline"
                                label="Escoge la fecha"
                                onChange={e => {
                                    setPayday(e);
                                    setBack(false);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <Icon>
                                                <CalendarToday />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onPropertyEdit()}
                        disabled={submitDisabled}
                        className={classes.button}
                    >
                        <span style={{ color: 'white' }}>Guardar cambios</span>
                    </Button>
                </Grid>
                {back === false && <LeavingPageAdvice prompt={props} />}
                {SuccessDialog()}
                {ErrorDialog()}
            </Paper>
        </React.Fragment>
    );
}
