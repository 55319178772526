import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Grid, Link as LinkMaterial, Toolbar } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Review from './Review';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { InputsPayments } from './components';
import Terms from '../public/Terms';
import SelectProperty from './SelectProperty';
import { Image } from '@material-ui/icons';
import azulLogo from '../../../assets/images/logo-azul.svg';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <LinkMaterial color="inherit" href="https://remaxrd.com/">
                RE/MAX RD
            </LinkMaterial>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function PaymentsBrand() {
    return (
        <Typography variant="body1" color="textPrimary" align="center">
            Aceptamos las tarjetas <br />
            <div style={{ display: 'inline-flex' }}>
                <img src="../images/brands/visa.png" />
                <img src="../images/brands/mastercard.png" />
                <img src="../images/brands/discover.png" />
                <img src="../images/brands/diners.png" />
                <img src="../images/brands/american.png" />
            </div>
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(1151 + theme.spacing(2) * 2)]: {
            maxWidth: '65%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.down(800)]: {
            width: '90%',
        },
    },

    layout2: {
        width: '45%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(1151 + theme.spacing(2) * 2)]: {
            maxWidth: '65%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.down(800)]: {
            width: '90%',
        },
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    gridContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
    },
    bubbleOne: {
        textAlign: 'center',
        margin: 'auto',
        color: 'white',
        backgroundColor: '#007dc3',
        fontSize: '1.2em',
        fontWeight: 500,
        padding: 5,
        borderRadius: 40,
        [theme.breakpoints.down(1141 + theme.spacing(3) * 2)]: {
            fontSize: '1.1em',
            width: '80%',
        },
        [theme.breakpoints.down(1141 + theme.spacing(3) * 2)]: {
            fontSize: '1.1em',
            width: '85%',
        },
        [theme.breakpoints.down(1024 + theme.spacing(3) * 2)]: {
            width: '95%',
        },
        [theme.breakpoints.down(753 + theme.spacing(3) * 2)]: {
            width: '100%',
        },
    },
    bubbleTwo: {
        margin: 'auto',
        backgroundColor: '#007dc3',
        borderRadius: 50,
        fontWeight: 500,
        marginTop: '40px',
        padding: 2,
        color: 'white',
        [theme.breakpoints.down(1141 + theme.spacing(3) * 2)]: {
            width: '95%',
        },
        [theme.breakpoints.down(1024 + theme.spacing(3) * 2)]: {
            width: '100%',
        },
    },
    stepsTitle: {
        textAlign: 'center',
        fontSize: '1.2rem',
        [theme.breakpoints.down(753 + theme.spacing(3) * 2)]: {
            fontSize: '0.9rem',
        },
    },
    azul: {
        width: '30px',
        height: 'auto',
        position: 'absolute',
    },
    stepContent: {
        textAlign: 'justify',
        fontSize: '1rem',
        lineHeight: '2',
        marginLeft: '30px',
        whiteSpace: 'pre-wrap',
        width: '85%',
        overflowWrap: 'break-word',
        [theme.breakpoints.down(1141 + theme.spacing(3) * 2)]: {
            fontSize: '0.9rem',
        },
        [theme.breakpoints.down(1024 + theme.spacing(3) * 2)]: {
            fontSize: '0.85rem',
        },
        [theme.breakpoints.down(753 + theme.spacing(3) * 2)]: {
            marginLeft: '20px',
            fontSize: '0.75rem',
        },
    },
}));

export default function Checkout(props) {
    const classes = useStyles('paper');
    const [activeStep, setActiveStep] = React.useState(1);
    const [validate, setValidate] = React.useState(false);
    const [isAdviced, setIsAdviced] = React.useState(true);
    const [totalAmount, setTotalAmount] = React.useState('');
    const [propertyId, setPropertyId] = React.useState('');
    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <SelectProperty
                        handleNext={handleNext}
                        setPropertyId={setPropertyId}
                    />
                );
            case 1:
                return (
                    <>
                        <Review
                            setValidate={setValidate}
                            setTotalAmount={setTotalAmount}
                            setIsAdviced={setIsAdviced}
                            redirect={props.history}
                            propertyId={propertyId}
                        />
                    </>
                );
            default:
                throw new Error('Unknown step');
        }
    }
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    function MakePayment() {
        return (
            validate && (
                <>
                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            name="submit"
                            disabled={!state.checkedB}
                            className={classes.button}
                        >
                            <span style={{ color: 'white' }}>
                                Realizar Pago
                            </span>
                        </Button>
                    </div>
                </>
            )
        );
    }
    const [state, setState] = React.useState({
        checkedB: false,
    });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="absolute"
                color="default"
                className={classes.appBar}
            >
                <Toolbar>
                    <Link href="/">
                        {'   '}
                        <ArrowBackIcon />
                        Volver al Inicio
                    </Link>
                </Toolbar>
            </AppBar>
            <main className={!isAdviced ? classes.layout : classes.layout2}>
                <form
                    action={`${process.env.AZUL_URL}`}
                    method="post"
                    id="paymentForm"
                    name="paymentForm"
                    target="_blank"
                >
                    {validate && (
                        <InputsPayments
                            totalAmount={totalAmount}
                            propertyId={propertyId}
                        />
                    )}
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={!isAdviced ? 6 : 12}
                            sm={!isAdviced ? 6 : 12}
                            lg={!isAdviced ? 6 : 12}
                        >
                            <Paper className={classes.paper}>
                                <Typography
                                    component="h1"
                                    variant="h4"
                                    align="center"
                                />
                                {getStepContent(activeStep)}
                                <MakePayment />
                                {validate && activeStep === 1 && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <>
                                                    <Checkbox
                                                        checked={state.checkedB}
                                                        onChange={handleChange}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                    <Terms />
                                                </>
                                            }
                                            label={''}
                                        />

                                        <span
                                            style={{
                                                fontSize: '9px',
                                                color: '#808080',
                                                display: 'inline-block',
                                            }}
                                        >
                                            No aceptamos devoluciones,
                                            reembolsos o cancelaciones luego de
                                            realizado el pago.
                                        </span>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                        {!isAdviced && (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sm={6}
                                lg={6}
                                className={classes.gridContainer}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Paper className={classes.bubbleOne}>
                                            {
                                                'Este servicio no te implica ningún'
                                            }
                                            <br />
                                            {''}
                                            {
                                                'costo adicional al realizar el pago del'
                                            }
                                            <br />
                                            {
                                                'alquiler con tu tarjeta de crédito.'
                                            }
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper className={classes.bubbleTwo}>
                                            <h2 className={classes.stepsTitle}>
                                                {`Pasos para realizar el pago:`}
                                            </h2>
                                            <p className={classes.stepContent}>
                                                {
                                                    '1. Haz clic en "Realizar pago".'
                                                }
                                                <br />
                                                {`2. Serás redireccionado al portal de pagos de AZUL. `}{' '}
                                                <img
                                                    alt="Remax"
                                                    src={azulLogo}
                                                    className={classes.azul}
                                                />
                                                <br />
                                                {
                                                    '3. Completa los datos de tu tarjeta de crédito.'
                                                }
                                                <br />
                                                {
                                                    '4. Luego de haber realizado la transacción el propietario recibirá el pago en un plazo de 48 horas.'
                                                }
                                            </p>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <PaymentsBrand />
                </form>
            </main>
        </React.Fragment>
    );
}
