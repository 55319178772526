import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ButtonLanding from './ButtonLanding';

export const useStyle = makeStyles(theme =>
    createStyles({
        cardContainer: {
            width: '100%',
            height: '18em',
            display: 'flex',
            padding: '2vh 4vh',
            borderRadius: '20px',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
                height: 'calc(18em + 1vw)',
                margin: '10px',
                display: 'flex',
                padding: '1vh 2vh',
                borderRadius: '20px',
            },
            [theme.breakpoints.down(1000)]: {
                height: 'calc(13em + 1vw)',
            },
            [theme.breakpoints.down(970)]: {
                height: 'calc(10em + 1vw)',
            },
            [theme.breakpoints.down(800)]: {
                margin: '2vh 0px',
                fontSize: '2.5vw',
                height: 'calc(5em + 1vw)',
            },
            [theme.breakpoints.down(700)]: {
                margin: '2vh 0px',
                fontSize: '2.5vw',
                height: 'calc(5em + 1vw)',
            },
            [theme.breakpoints.down(600)]: {
                margin: '10px 0px',
                padding: '1vh 2vh',
                height: 'calc(7em + 1vw)',
            },
        },
        textHeader: {
            [theme.breakpoints.down(1000)]: {
                width: '17em',
                fontSize: 'calc(1.5em)',
            },
            [theme.breakpoints.down(800)]: {
                width: '100%',
                fontSize: 'calc(1em)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '4em',
            },
            [theme.breakpoints.down(600)]: {
                height: '4em',

                width: '100%',

                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
            },
            [theme.breakpoints.down(500)]: {
                width: '100%',
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: '500',
            },
            color: 'white',
            width: '15em',
            fontSize: 'calc(0.8em + 1vw)',
            fontWeight: '600',
        },
        textBody: {
            [theme.breakpoints.down(800)]: {
                margin: '2vh 0px',
                fontSize: '2.5vw',
                display: 'none',
                fontWeight: '300',
            },
            //    [theme.breakpoints.down(750)]: {},
            color: 'white',

            fontSize: 'calc(0.5em + 1vw)',
            fontWeight: '300',
        },
        button: {
            [theme.breakpoints.down(1000)]: {
                display: 'none',
            },
        },
    }),
);

interface Props {
    textHeader: string;
    textBody: string;
    to?: string;
    textAlign?: 'right' | 'left';
    background?: string;
}
const TransparentCards = (props: Props) => {
    const classes = useStyle();
    return (
        <>
            <Grid
                container
                item
                xs={12}
                className={classes.cardContainer}
                style={{
                    textAlign: props.textAlign ?? 'left',
                    background: props.background ?? '#003da5d6',
                }}
            >
                <Grid
                    container
                    item
                    xs={12}
                    justify={props.textAlign == 'right' ? 'flex-end' : ''}
                >
                    <Typography className={classes.textHeader}>
                        {props.textHeader}
                    </Typography>
                </Grid>

                <Grid container item xs={12}>
                    <Typography className={classes.textBody}>
                        {props.textBody}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.button}
                    justify={props.textAlign == 'right' ? 'flex-end' : ''}
                >
                    <ButtonLanding label="Regístrate" to="/register" />
                </Grid>
            </Grid>
        </>
    );
};

export default TransparentCards;
