import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Tooltip, Typography, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';

interface Props {
    isOpen: boolean;
    handleOpen: any;
    handleClose: any;
    url: string;
    // classes: any;
}
const useStyles = makeStyles(() => ({
    copyLink: {
        color: 'gray',
        width: '10vw',
        border: '2px solid blue',
        padding: '7px',
        overflow: 'hidden',
        background: 'whitesmoke',
        whiteSpace: 'nowrap',
        borderRadius: '6px',
        textOverflow: 'ellipsis',
    },
    description: {
        marginBottom: '15px',
    },
}));
export default function PaymentLink(props: Props) {
    const [copiedLink, updateCopiedLink] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [MethopSelected, setMethopSelected] = React.useState('');
    const classes = useStyles();

    React.useEffect(() => {}, []);
    const urlToSend = `Estimado inquilino, Tu propietario te ha compartido el siguiente enlace para realizar el pago de tu alquiler con tarjeta de crédito en RE/MAX PAY. Este enlace de pago tiene un tiempo de duración de 24 horas, favor procede a realizar el pago antes de la expiración del mismo; en caso de que el enlace expire, deberás solicitar uno nuevo al propietario. \n \n ${props.url}`;
    const handleTelOpt = e => {
        const value = e.target.value.replace(/[^0-9\.]+/g, '');
        setPhone(value);
    };
    const handleCopiedLink = () => {
        navigator.clipboard.writeText(urlToSend);
        updateCopiedLink(true);
        setTimeout(() => {
            updateCopiedLink(false);
        }, 1500);
    };
    const onSendWhatsappMessage = () => {
        const url = `https://wa.me/${phone}/?text=${urlToSend}`;
        window.open(url, '_blank');
    };
    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Compartir</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.description}
                        spacing={1}
                    >
                        <Grid item>
                            <Typography variant="body1">
                                Copia y comparte el enlace de pago con tu
                                inquilino a través del método que prefieras,
                                este enlace es válido solo por 24 horas.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid
                            container
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            alignItems="center"
                            justify="space-around"
                        >
                            <Grid
                                item
                                lg={7}
                                md={7}
                                sm={12}
                                xs={12}
                                className={classes.copyLink}
                            >
                                <a>{props.url}</a>
                            </Grid>

                            <Grid
                                container
                                item
                                lg={4}
                                md={7}
                                sm={12}
                                xs={12}
                                justify="center"
                                style={{ margin: '5px 0px' }}
                            >
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    open={copiedLink}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Copiado"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCopiedLink}
                                    >
                                        {!copiedLink
                                            ? 'Copiar enlace'
                                            : 'Enlace Copiado'}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={6}
                            alignItems="center"
                        >
                            <Typography variant="subtitle2">
                                Enviar via WhatsApp
                            </Typography>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={6}>
                            <Button
                                color="primary"
                                onClick={() => setMethopSelected('WhatsApp')}
                            >
                                <img
                                    style={{ width: '45px' }}
                                    src={'images/whassat.png'}
                                />
                            </Button>
                        </Grid>
                        {MethopSelected == 'WhatsApp' && (
                            <Grid item lg={12} md={12} sm={12}>
                                <InputMask
                                    mask="1-999-999-9999"
                                    value={phone}
                                    label="Número de WhatsApp"
                                    onChange={e => handleTelOpt(e)}
                                >
                                    {() => (
                                        <TextField
                                            id="cardOwnerTel"
                                            label="Número de WhatsApp"
                                            fullWidth
                                            autoComplete="cc-last"
                                        />
                                    )}
                                </InputMask>
                                <Button
                                    disabled={phone.length != 11}
                                    color="primary"
                                    onClick={onSendWhatsappMessage}
                                >
                                    Compartir
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.handleClose}
                        color="primary"
                        autoFocus
                    >
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
