import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }),
);

export default function SearchUsers({ searchUser }) {
    const handleSearch = keyword => {
        searchUser(keyword);
    };
    const classes = useStyles('paper');
    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon fontSize="large" />
            </div>
            <InputBase
                placeholder="Buscar por nombre o correo... "
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onChange={e => handleSearch(e.target.value)}
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    );
}
