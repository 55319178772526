import React from 'react';
import {
    Button,
    createStyles,
    makeStyles,
    useMediaQuery,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ButtonLanding from '../../components/ButtonLanding';
import TransparentCards from '../../components/TransparentCard';

const remaxLogo = 'images/remax-pay.png';
const landingMainTop = 'images/landing-main-top.png';
const remax_azul = 'images/remax_azul.png';

const downIcon = 'images/down.png';

// const payLeaseImg = 'images/pay_your_lease.png';
// const stepsImg = 'images/icon_123.png';
// const stepOneMobile = 'images/step_one_mobile.svg';
// const stepTwoMobile = 'images/step_two_mobile.svg';
// const stepThreeMobile = 'images/step_three_mobile.svg';
// const azulLogo = 'images/logo-azul.png';
// const backToTopDesktop = 'images/back_to_top_desktop.png';
// const backToTopMobile = 'images/back_to_top_mobile.png';
// const remaxPayWhiteLogo = 'images/REMAX-PAY-BLANCO.png';
// const goDownArrow = 'images/go_down_arrow.png';
// const startNowButton = 'images/start_now_button.png';

export const color = {
    darkGray: '#404041',
    lightGray: '#626366',
    blue: '#007DC3',
    lighterGray: 'rgba(196, 198, 200, 0.6)',
};

export const useStyle = makeStyles(theme =>
    createStyles({
        mainContainer: {
            [theme.breakpoints.down(4500)]: {
                width: '100vw',
                height: '110vh',
            },
            [theme.breakpoints.down(4500)]: {
                height: '110vh',
            },

            [theme.breakpoints.down(800)]: {
                marginTop: '0',
                width: '100vw',
                height: '53vh',
                background: 'url(images/landing-main-top.png)',
                boxSizing: 'border-box',
                objectFit: 'contain',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                // backgroundPositionX: '-119px',
            },
            [theme.breakpoints.down(600)]: {
                marginTop: '0',
                width: '100vw',
                height: '53vh',
                backgroundPositionX: '-119px',
            },

            marginTop: '0',
            width: '100vw',
            height: '53vh',
            background: 'url(images/landing-main-top.png)',
            boxSizing: 'border-box',
            objectFit: 'contain',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'center center',
        },
        imgLogo: {
            [theme.breakpoints.down(600)]: {
                width: '30vw',
            },
            width: '18vw',
        },
        content_header: {
            [theme.breakpoints.down(600)]: {
                padding: '1vh 1vw',
                top: '0vh',
                position: 'relative',
            },
            [theme.breakpoints.down(800)]: {
                top: '0vh',
            },
            top: '5vh',
            padding: '4vh 4vw',
            position: 'absolute',
            alignItems: 'center',
        },
        button_containt: {},

        cardContainer: {
            [theme.breakpoints.down(900)]: {
                marginTop: 'calc(50vh + 1vw)',
            },
            [theme.breakpoints.down(800)]: {
                marginTop: 'calc(17vh + 1vw)',
            },

            marginTop: 'calc(54vh + 1vw)',
            display: 'flex',
            justifyContent: 'space-around',
        },
        downIcon: {
            top: '3%',
            position: 'relative',
            justifyContent: 'center',
        },
        mobileHeader: {},
    }),
);
// const classes = useStyle();

function CoverSection() {
    const classes = useStyle();
    const matches1370pxDown = useMediaQuery('(max-width: 1370px)');
    const matches990pxDown = useMediaQuery('(max-width: 990px)');
    const matches960pxDown = useMediaQuery('(max-width: 960px)');
    const matches600pxDown = useMediaQuery('(max-width: 600px)');
    const matches700pxDown = useMediaQuery('(max-width: 700px)');
    return (
        <>
            {/* {!matches600pxDown && ( */}
            <Grid
                container
                item
                xs={12}
                className={classes.content_header}
                justify="space-between"
            >
                <Grid item xs={4}>
                    <img
                        src={remax_azul}
                        alt="remaxLogo"
                        className={classes.imgLogo}
                    />
                </Grid>
                <Grid
                    container
                    justify="space-evenly"
                    item
                    lx={3}
                    lg={4}
                    md={4}
                    sm={7}
                    xs={7}
                >
                    <ButtonLanding
                        label="iniciar Sesión"
                        variant="outline"
                        to="/login"
                    />
                    <ButtonLanding label="Regístrarse" to="/register" />
                </Grid>
            </Grid>
            {/* )} */}
            <Grid className={classes.mainContainer}>
                <Grid container item xs={12} className={classes.cardContainer}>
                    <Grid item xl={4} lg={4} md={5} sm={10} xs={10}>
                        <TransparentCards
                            textHeader="¡Recibe pagos de alquiler con tarjetas de crédito!"
                            textBody="Obtén el pago del alquiler de tu inquilino sin demora. Hayas o no alquilado con nosotros."
                            textAlign="left"
                        ></TransparentCards>
                    </Grid>

                    <Grid item xl={4} lg={4} md={5} sm={10} xs={10}>
                        <TransparentCards
                            textHeader="¡Paga el alquiler de tu propiedad con tarjeta de crédito!"
                            textBody="Puedes hacerlo hayas alquilado o no con RE/MAX"
                            textAlign="right"
                            background="#0e295ae6"
                        ></TransparentCards>
                    </Grid>
                </Grid>
                {matches600pxDown && (
                    <Grid container xs={12} justify="center">
                        <Button
                            href="/register"
                            style={{
                                width: '122px',
                                background: 'rgb(250, 250, 250)',
                                color: 'gray',
                                borderRadius: '20px',
                                fontSize: '12px',
                            }}
                        >
                            Regístrate
                        </Button>
                    </Grid>
                )}

                {!matches700pxDown && (
                    <Grid container xs={12} className={classes.downIcon}>
                        <img src={downIcon} style={{ width: '3em' }} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default CoverSection;
