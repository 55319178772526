import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import terms from '../../../assets/documents/PolíticaPrivacidadTCV2.docx';
export default function Terms() {
    const docs = [
        {
            uri: require('../../../assets/documents/PolíticaPrivacidadTCV2.docx'),
        },
    ];
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Link to="#" onClick={handleClickOpen('paper')}>
                <small style={{ color: 'blue' }}>
                    Acepto términos y condiciones y política de privacidad
                </small>
            </Link>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <center>
                        <h4 style={{ fontWeight: 500 }}>
                            Política de Privacidad y Términos & Condiciones de
                            Uso remaxrd.com
                        </h4>
                    </center>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <div
                            style={{ color: 'black' }}
                            dangerouslySetInnerHTML={{ __html: terms }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
