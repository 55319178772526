export interface OptionPropTypes {
    label: string;
    id: number;
}
export interface OptionType {
    label: string;
    value: string;
}
export interface PropertiesState {
    anchorEl: HTMLButtonElement | null;
}

export const propertyTypes: OptionPropTypes[] = [
    {
        label: ' ',
        id: 0,
    },
    {
        label: 'Apartamento',
        id: 1,
    },
    {
        label: 'Casa',
        id: 2,
    },
    {
        label: 'Local Comercial u Oficina',
        id: 3,
    },
];

export const businessTypes: OptionPropTypes[] = [
    {
        label: ' ',
        id: 0,
    },
    {
        label: 'Alquiler',
        id: 2,
    },
    {
        label: 'Alquiler Linea Blanca',
        id: 6,
    },
    {
        label: 'Alquiler Amueblado',
        id: 3,
    },
];

export const currencyTypes: OptionPropTypes[] = [
    {
        label: ' ',
        id: 0,
    },
    {
        label: 'RD$',
        id: 1,
    },
];
