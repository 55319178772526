/* eslint-disable */
import { AxiosPromise } from 'axios';
import { Ajax, AjaxError } from '../helpers/ajax';
import { store } from '../redux';
import { getConfig, AuthType } from '../config';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions as globalActions } from './global';
import { Url } from 'app/helpers/url';
import storage from 'app/helpers/storage';
import utils from 'app/helpers/utils';
import ApiConstants from '../config/api.constants';
import { ControlPointTwoTone } from '@material-ui/icons';

export const KEY_TOKEN = 'token';
export const USER_INFO = 'user';
export const EXPIRATION_DATE = 'expiration_date';
export const MISSING_INFO = 'missing_info';
export const SHOW_ROLE_ON_LOGIN = 'showRoleOnLogin';

export const ACTION_AUTH_REQUEST = 'USER_AUTH_REQUEST';
export const ACTION_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const ACTION_AUTH_FAILURE = 'USER_AUTH_FAILURE';

export const ACTION_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const ACTION_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const ACTION_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const ACTION_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const ACTION_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const ACTION_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const ACTION_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const ACTION_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const ACTION_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const ACTION_GETUSER_REQUEST = 'USER_GETUSER_REQUEST';
export const ACTION_GETUSER_SUCCESS = 'USER_GETUSER_SUCCESS';
export const ACTION_GETUSER_FAILURE = 'USER_GETUSER_FAILURE';

export interface UserInfo {
    id?: string;
    name?: string;
    last_name?: string;
    address: string;
    email?: string;
    email_verified_at: null;
    avatar?: string;
    accept_terms?: boolean;
    user_type?: number;
    missingInfo?: [];
}

export interface AuthState {
    user?: UserInfo;
    token?: TokenInfo;
    error?: any;
    created?: boolean;
    isAdmin?: boolean;
}

export interface LoginData {
    email: string;
    password: string;
    client_id?: string;
    client_secret?: string;
    grant_type?: string;
}

export interface RegisterData {
    name?: string;
    last_name?: string;
    email?: string;
    password?: string;
    address: string;
    password_confirmation?: string;
    is_admin?: boolean;
    accept_terms?: boolean;
    user_type: number;
    document_type: string;
}

export interface TokenInfo {
    access_token: string;
    refresh_token?: string;
    token_type?: string;
    expires_in?: number;
    scope?: string;
}

export const actions = {
    authSuccess: (tokenInfo: TokenInfo) => ({
        type: ACTION_AUTH_SUCCESS,
        payload: tokenInfo,
    }),
    register: (data: RegisterData) => ({
        type: ACTION_REGISTER_REQUEST,
        payload: data,
    }),
    registerSuccess: (response: RegisterData) => ({
        type: ACTION_REGISTER_SUCCESS,
        payload: response,
    }),
    registerFailure: error => ({
        type: ACTION_REGISTER_FAILURE,
        payload: error,
    }),
    login: (data: LoginData) => ({
        type: ACTION_LOGIN_REQUEST,
        payload: data,
    }),
    loginSuccess: (response: TokenInfo) => ({
        type: ACTION_LOGIN_SUCCESS,
        payload: response,
    }),
    loginFailure: error => ({
        type: ACTION_LOGIN_FAILURE,
        payload: error,
    }),
    getUserInfo: (accessToken: string) => ({
        type: ACTION_GETUSER_REQUEST,
        payload: accessToken,
    }),

    getUserInfoSuccess: (userInfo: UserInfo) => ({
        type: ACTION_GETUSER_SUCCESS,
        payload: userInfo,
    }),
    logout: () => ({
        type: ACTION_LOGOUT_REQUEST,
    }),
    logoutSuccess: () => ({
        type: ACTION_LOGOUT_SUCCESS,
    }),
};

export function reducer(state: AuthState = {}, action): AuthState {
    switch (action.type) {
        case ACTION_AUTH_SUCCESS:
            return {
                ...state,
                token: action.payload,
            };

        case ACTION_LOGIN_REQUEST:
            return { ...state };
        case ACTION_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload,
            };
        case ACTION_REGISTER_REQUEST:
            return { ...state };
        case ACTION_REGISTER_SUCCESS:
            return {
                ...state,
                created: action.payload,
            };
        case ACTION_GETUSER_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case ACTION_LOGOUT_SUCCESS:
            return { ...state, user: null, token: null };

        default:
            return state;
    }
}

const config = getConfig();
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-API-KEY': ApiConstants.X_API_KEY,
};

export const service = {
    login: (data: LoginData) => {
        switch (config.authType) {
            case AuthType.Mock:
                return new Ajax({
                    headers: headers,
                })
                    .post(ApiConstants.LOGIN_ENDPOINT, data)
                    .then((result: any) => {
                        if (
                            result.user?.is_active &&
                            result.user.is_active === 1
                        ) {
                            storage.set(KEY_TOKEN, result.access_token);
                            storage.set(USER_INFO, result.user);
                            storage.set(MISSING_INFO, {
                                data: { missing_info: result.missing_info },
                            });
                            storage.set(
                                EXPIRATION_DATE,
                                new Date().getTime() + result.expires_in * 100,
                            );
                            return result.access_token;
                        }
                    });
            default:
                return new Promise((resolve, reject) => {
                    const error: AjaxError = {
                        data: {
                            error_description: `No handler found for ${config.authType}`,
                        },
                        status: 404,
                        statusText: 'No `AuthType` matched.',
                        headers: null,
                        config: null,
                        request: null,
                    };
                    reject(error);
                });
        }
    },
    register: (data: RegisterData): AxiosPromise => {
        switch (config.authType) {
            case AuthType.Mock:
                return new Ajax({
                    headers: {
                        ...headers,
                        'Access-Control-Allow-Origin': '*',
                    },
                })
                    .post(ApiConstants.REGISTER_ENDPOINT, data)
                    .then((result: any) => {
                        if (result.message) {
                            return result.message;
                        } else if (result[1] !== 200) {
                            return Promise.reject(result[0]);
                        } else {
                            return result;
                        }
                    });
            default:
                return new Promise((resolve, reject) => {
                    const error: AjaxError = {
                        data: {
                            error_description: `No handler found for ${config.authType}`,
                        },
                        status: 404,
                        statusText: 'No `AuthType` matched.',
                        headers: null,
                        config: null,
                        request: null,
                    };
                    reject(error);
                });
        }
    },
    getUser: (): AxiosPromise | any => {
        if (config.authType === AuthType.Mock) {
            return new Promise((resolve, reject) => {
                const user: UserInfo = JSON.parse(
                    getState().token.access_token,
                ) as UserInfo;
                resolve(user);
            });
        }
        return new Ajax({
            headerAuthorization: () =>
                `${getState().token.token_type || 'Bearer'} ${
                    getState().token.access_token
                }`,
        }).get(config.authConfig.userProfileUri);
    },

    logout: (): AxiosPromise | any => {
        if (config.authType === AuthType.Mock) {
            return new Promise((resolve, reject) => {
                storage.remove(KEY_TOKEN);
                storage.remove(USER_INFO);
                storage.remove(EXPIRATION_DATE);
                storage.remove(MISSING_INFO);
                storage.remove(SHOW_ROLE_ON_LOGIN);
                resolve('ok');
            });
        }
        if (config.logoutHandler) {
            return config.logoutHandler(
                config.authConfig.logoutUri,
                getState(),
            );
        } else {
            return new Ajax({
                headerAuthorization: () => {
                    if (getState().token) {
                        return `${getState().token.token_type} ${
                            getState().token.access_token
                        }`;
                    }
                    return '';
                },
            }).remove(config.authConfig.logoutUri);
        }
    },
};

function* auth() {
    yield put(globalActions.showLoading('Redireccionando...'));
}

function* authSuccess(action) {
    yield put(actions.getUserInfo(action.payload));
}

function* login(action) {
    try {
        // effects(call, put):
        // trigger off the code that we want to call that is asynchronous
        // and also dispatched the result from that asynchrous code.
        const loginData: LoginData = action.payload;
        yield put(globalActions.showLoading('Cargando...'));
        const tokenInfo: TokenInfo = yield call(service.login, {
            ...loginData,
        });
        yield put(globalActions.changeRole());
        yield put(globalActions.isAdmin());
        yield put(actions.loginSuccess(tokenInfo));
        if (tokenInfo.access_token) {
            yield put(actions.getUserInfo(tokenInfo.access_token));
        }
        yield put(globalActions.hideLoading());
    } catch (err) {
        yield put(globalActions.hideLoading());
        if (err) {
            yield put(globalActions.notifyError(err.data.error[0]));
        } else {
            yield put(globalActions.notifyError(`Servicio no disponible`));
        }
    }
}

function* logout(action) {
    yield put(actions.logoutSuccess());
}

function* register(action) {
    try {
        const registerData: RegisterData = action.payload;
        yield put(globalActions.showLoading('Cargando...'));
        const registered = yield call(service.register, {
            ...registerData,
        });
        yield put(actions.registerSuccess(registered));
        yield put(globalActions.hideLoading());
        yield put(push('/success'));
    } catch (err) {
        yield put(globalActions.hideLoading());
        if (err.email) {
            yield put(
                globalActions.notifyError(`Este correo ya ha sido registrado`),
            );
        } else if (err.data?.userExists) {
            yield put(
                globalActions.notifyError(`Este correo ya ha sido registrado`),
            );
        } else if (err.name) {
            yield put(globalActions.notifyError(`Tu nombre contiene errores`));
        } else if (err.last_name) {
            yield put(
                globalActions.notifyError(`Tu apellido contiene errores`),
            );
        } else if (err.accept_terms) {
            yield put(
                globalActions.notifyError(
                    `Debes aceptar los términos y condiciones`,
                ),
            );
        } else if (
            err.password[0] === 'The password confirmation does not match.'
        ) {
            yield put(
                globalActions.notifyError(`Las contraseñas no coinciden`),
            );
        } else if (err.password) {
            yield put(
                globalActions.notifyError(
                    `Tu contraseña debe tener al menos 6 caracteres.`,
                ),
            );
        } else if (err.data.notMatch) {
            yield put(globalActions.notifyError(`Los correos no coinciden.`));
        } else if (err.data.tel) {
            yield put(globalActions.notifyError(`El teléfono es requerido.`));
        } else {
            yield put(globalActions.notifyError(`Favor intentar mas tarde.`));
        }
    }
}

function* getUser(action) {
    try {
        yield put(
            globalActions.showLoading('Obteniendo información del usuario...'),
        );
        yield put(globalActions.changeRole());
        yield put(globalActions.getMissingInfo(storage.get('missing_info')));
        const response = yield call(service.getUser);
        const userInfo: UserInfo =
            typeof config.userConverter === 'function'
                ? config.userConverter(response)
                : { ...response };
        yield put(actions.getUserInfoSuccess(userInfo));
        yield put(globalActions.hideLoading());
        // yield put(push('/'));
    } catch (e) {
        yield put(globalActions.hideLoading());
    }
}

export function* saga() {
    // takeEvery:
    // listen for certain actions that are going to be dispatched and take them and run through our worker saga.
    yield takeLatest(ACTION_AUTH_REQUEST, auth);
    yield takeLatest(ACTION_AUTH_SUCCESS, authSuccess);
    yield takeLatest(ACTION_LOGIN_REQUEST, login);
    yield takeLatest(ACTION_LOGOUT_REQUEST, logout);
    yield takeLatest(ACTION_REGISTER_REQUEST, register);
    yield takeLatest(ACTION_GETUSER_REQUEST, getUser);
}

export function getState(): AuthState {
    return store.getState().auth as AuthState;
}

export function isAuthorized(): boolean {
    if (config.authType === AuthType.Mock) {
        let token: TokenInfo = storage.get(KEY_TOKEN);
        let user: UserInfo = storage.get(USER_INFO);
        if (token) {
            if (config.userConverter) {
                user = config.userConverter(user);
            }
            const state = getState();
            state.token = token;
            state.user = user;
        }
    }
    return !!getState().token;
}

export function getAuthUri(): string {
    if (
        config.authType === AuthType.Custom ||
        config.authType === AuthType.OAuthPassword ||
        config.authType === AuthType.Mock
    ) {
        return '/inicio';
    }

    if (
        config.authType === AuthType.OAuth ||
        config.authType === AuthType.OAuthCode
    ) {
        let authUrl = config.authConfig.authorizationUri;
        if (!config.authConfig.callbackUri) {
            const callbackUri = Url.current().merge('/auth/callback');
            authUrl = authUrl.replace('{callbackUri}', callbackUri);
        }

        Object.keys(config.authConfig).forEach(key => {
            authUrl = authUrl.replace(`{${key}}`, config.authConfig[key]);
        });

        return `${authUrl}&state=${getValidState()}`;
    }
}

export function getValidState(): string {
    const KEY_AUTH_STATE = 'AUTH_STATE';
    if (!storage.getSession(KEY_AUTH_STATE)) {
        storage.setSession(KEY_AUTH_STATE, utils.randomString(10));
    }

    return storage.getSession(KEY_AUTH_STATE);
}

export function getAccessTokenUri(code: string): string {
    if (
        config.authType === AuthType.OAuth ||
        config.authType === AuthType.OAuthCode
    ) {
        let tokenUrl = config.authConfig.accessTokenUri;
        tokenUrl = tokenUrl.replace('{code}', code);
        if (!config.authConfig.callbackUri) {
            const callbackUri = Url.current().merge('/auth/callback');
            tokenUrl = tokenUrl.replace('{callbackUri}', callbackUri);

            Object.keys(config.authConfig).forEach(key => {
                tokenUrl = tokenUrl.replace(`{${key}}`, config.authConfig[key]);
            });
        }
        return tokenUrl;
    }
}

export function checkTimeOut() {
    let currentDate = new Date();
    let expires_in = storage.get(EXPIRATION_DATE);
    let expirationDate = new Date(parseInt(expires_in));
    if (currentDate > expirationDate) {
        storage.remove(USER_INFO);
        storage.remove(KEY_TOKEN);
        storage.remove(EXPIRATION_DATE);
        storage.remove(MISSING_INFO);
        storage.remove(SHOW_ROLE_ON_LOGIN);
        location.href = '/inicio';
    } else {
        return false;
    }
}
