/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import storage from 'app/helpers/storage';
import WelcomeMobile from './welcomeMobile';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper1: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
            backgroundImage: `url("/images/inicio-background.png")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '57vh',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
        },
        paper2: {
            padding: theme.spacing(2),
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            backgroundPosition: 'center center',
            height: '35vh',

            [theme.breakpoints.down('md')]: {
                height: '37vh',
            },

            [theme.breakpoints.down('sm')]: {
                height: '42vh',
            },
        },
        colorLogo: {
            width: '25%',
            [theme.breakpoints.down('md')]: {
                width: '35%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '40%',
            },
        },
        colorLogoFooter: {
            width: '40%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        text1: {
            textAlign: 'left',
            marginLeft: '6px',
            fontWeight: 'bold',
            color: '#3f4445',
            marginTop: '5%',
            fontSize: '4vw',
            [theme.breakpoints.down('md')]: {
                marginLeft: '6px',
                fontWeight: 'bold',
                color: '#3f4445',
                marginTop: '5%',
                fontSize: '5vw',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '3px',
                fontWeight: 'bold',
                color: '#3f4445',
                marginTop: '4.5%',
                fontSize: '7.5vw',
            },
        },
        text2: {
            textAlign: 'left',
            marginLeft: '10px',
            color: '#3f4445',
            fontSize: '1.5vw;',
            [theme.breakpoints.down('md')]: {
                marginLeft: '6px',
                fontSize: '2.4vw;',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '6px',
                fontSize: '3.4vw;',
            },
        },
        textInfo: {
            textAlign: 'justify',
            color: '#3f4445',
            fontSize: '1vw',
            paddingLeft: '10px',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.6vw;',
            },
        },
        title1: {
            textAlign: 'center',
            marginLeft: '10px',
            color: '#3f4445',
            padding: '4px 0px 4px 0px',
            fontSize: '2vw',
            [theme.breakpoints.down('md')]: {
                fontSize: '3vw',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '5vw;',
            },
        },
        textSquares3: {
            textAlign: 'center',
            color: '#3f4445',
            fontSize: '0.9vw',
            fontWeight: '500',
            paddingTop: '25px',
            height: '16vh',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
                height: '14vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.7vw',
                height: '14vh',
            },
        },
        textSquares2: {
            textAlign: 'center',
            color: '#3f4445',
            fontSize: '0.9vw',
            fontWeight: '500',
            paddingTop: '15px',
            height: '16vh',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
                height: '14vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.7vw',
                height: '14vh',
            },
        },
        textSquares1: {
            textAlign: 'center',
            color: '#3f4445',
            fontSize: '0.9vw',
            paddingTop: '5px',
            fontWeight: '500',
            height: '16vh',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
                height: '14vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.7vw',
                height: '14vh',
            },
        },
        brandContainer: {
            paddingLeft: '10px',
            paddingTop: '10px',
        },
        borderStyle: {
            borderRight: '1px solid rgba(0, 0, 0, 0.22)',
            paddingRight: '90px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '70px',
                width: '1.5vh',
            },
            [theme.breakpoints.down('md')]: {
                paddingRight: '70px',
                width: '1.5vh',
            },
        },
        logoAzul: {
            width: '3vh',
            marginLeft: '15px',
            [theme.breakpoints.down('md')]: {
                marginLeft: '15px',
                width: '3vh',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '15px',
                width: '2.5vh',
            },
        },
        logoRemax: {
            width: '9vh',
            marginLeft: '5px',
            [theme.breakpoints.down('md')]: {
                width: '7vh',
                marginLeft: '5px',
            },
            [theme.breakpoints.down('sm')]: {
                width: '7vh',
                marginLeft: '2px',
            },
        },
    }),
);

export default function Welcome() {
    const classes = useStyles();

    return (
        <>
            {window.innerWidth > 500 && (
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper1}>
                                {window.innerWidth < 960 && (
                                    <img
                                        className={classes.colorLogo}
                                        src="/images/remax_azul.png"
                                        alt="RE/MAX"
                                    />
                                )}
                                <Typography
                                    component="h2"
                                    variant="h2"
                                    className={classes.text1}
                                >
                                    <b>Bienvenido,</b>
                                    <br />
                                </Typography>
                                <Typography
                                    gutterBottom={true}
                                    component="h5"
                                    variant="h5"
                                    className={classes.text2}
                                >
                                    al servicio de pagos de
                                    <br />
                                    alquileres con tarjeta de
                                    <br />
                                    crédito, con el respaldo de <br />
                                    <strong>
                                        RE/MAX, la red #1 del
                                        <br />
                                        sector inmobiliario.
                                    </strong>
                                    <br />
                                </Typography>
                            </Paper>
                            <Paper className={classes.paper2}>
                                <Typography
                                    gutterBottom={true}
                                    component="h3"
                                    variant="h3"
                                    className={classes.title1}
                                >
                                    <strong>
                                        ¡Aquí te explicamos cómo funciona!
                                    </strong>
                                    <br />
                                </Typography>
                                {storage.get('user')?.user_type === 1 ? (
                                    <Grid container xs={12}>
                                        <Grid item xs={4}>
                                            <Paper className={classes.text2}>
                                                <Paper>
                                                    <Typography
                                                        gutterBottom={true}
                                                        component="h3"
                                                        variant="h3"
                                                        className={
                                                            classes.textSquares1
                                                        }
                                                    >
                                                        1. <br />
                                                        Haz clic en <br />
                                                        "Configuración", <br />
                                                        agrega y completa <br />
                                                        los datos del <br />
                                                        inmueble y del <br />
                                                        propietario.
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper className={classes.text2}>
                                                <Paper>
                                                    <Typography
                                                        gutterBottom={true}
                                                        component="h3"
                                                        variant="h3"
                                                        className={
                                                            classes.textSquares2
                                                        }
                                                    >
                                                        2.
                                                        <br /> El propietario
                                                        <br /> recibirá un
                                                        correo
                                                        <br /> para confirmar
                                                        los
                                                        <br />
                                                        datos.
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper className={classes.text2}>
                                                <Paper>
                                                    <Typography
                                                        gutterBottom={true}
                                                        component="h3"
                                                        variant="h3"
                                                        className={
                                                            classes.textSquares3
                                                        }
                                                    >
                                                        3. <br />
                                                        ¡Listo! Ya puedes
                                                        <br /> realizar el pago.
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Typography
                                            gutterBottom={true}
                                            component="h4"
                                            variant="h4"
                                            className={classes.textInfo}
                                        >
                                            Tienes dudas o requieres más
                                            información escríbenos a
                                            info@remaxrd.com
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid container xs={12}>
                                        <Grid item xs={4}>
                                            <Paper className={classes.text2}>
                                                <Paper>
                                                    <Typography
                                                        gutterBottom={true}
                                                        component="h3"
                                                        variant="h3"
                                                        className={
                                                            classes.textSquares1
                                                        }
                                                    >
                                                        1. <br />
                                                        Haz clic en <br />
                                                        "Configuración", <br />
                                                        agrega y completa <br />
                                                        los datos del <br />
                                                        inmueble. <br />
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper className={classes.text2}>
                                                <Paper>
                                                    <Typography
                                                        gutterBottom={true}
                                                        component="h3"
                                                        variant="h3"
                                                        className={
                                                            classes.textSquares2
                                                        }
                                                    >
                                                        2.
                                                        <br />
                                                        Haz clic en <br />
                                                        "Mis propiedades",{' '}
                                                        <br />
                                                        luego clic en "Registrar{' '}
                                                        <br />
                                                        propiedad" y completa{' '}
                                                        <br />
                                                        los datos.
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Paper className={classes.text2}>
                                                <Paper>
                                                    <Typography
                                                        gutterBottom={true}
                                                        component="h3"
                                                        variant="h3"
                                                        className={
                                                            classes.textSquares3
                                                        }
                                                    >
                                                        3. <br />
                                                        Ir a "Mis propiedades",{' '}
                                                        <br />
                                                        luego clic en "Pagos" y
                                                        colocas el correo del{' '}
                                                        <br /> inquilino.
                                                        ¡Listo! Ya <br />
                                                        puedes recibir el pago.
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Typography
                                            gutterBottom={true}
                                            component="h4"
                                            variant="h4"
                                            className={classes.textInfo}
                                        >
                                            Tienes dudas o requieres más
                                            información escríbenos a
                                            info@remaxrd.com
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    className={classes.brandContainer}
                                    xs={4}
                                >
                                    <img
                                        className={classes.colorLogoFooter}
                                        src="/images/remax_azul.png"
                                        alt="RE/MAX"
                                    />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            )}
            {window.innerWidth < 500 && <WelcomeMobile></WelcomeMobile>}
        </>
    );
}
