import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
    Avatar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import TenantPanelInfo from './components/TenantPanelInfo';
import { paymentsService } from '../../service/admin';
import { propertiesService } from '../../service/properties';
import storage from 'app/helpers/storage';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CalendarToday } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import esLocale from 'date-fns/locale/es';
import { red, green } from '@material-ui/core/colors';
import SendPaymentLink from './components/SendPaymentLink';
import { Advice } from 'app/pages/public';
import PaymentLinkDialog from './components/PaymentLinkDialog';
import GenerateInstructionalLink from './components/GenerateInstructionalLink';
import InfoIcon from '@material-ui/icons/Info';
import { service as resourceService } from 'app/service/resource';
import { ApiConstants } from 'app/config';
const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        margin: '48px 17px',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    fieldTitles: {
        fontSize: '14px',
    },
    search: {
        marginTop: '20px',
    },
    dates: {
        width: '100%',
    },
    button: {
        marginTop: 20,
    },
    terms: {
        display: 'flex',
        backgroundColor: '#007dc3',
        color: 'white',
    },
    labelStyles: {
        flex: '1',
        marginLeft: 5,
        backgroundColor: '#007dc3',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '22px',
        [theme.breakpoints.down(996)]: {
            fontSize: '1rem',
            marginTop: '20px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            marginLeft: '0px',
            fontSize: '1rem',
            textAlign: 'center',
        },
    },
    icon: {
        margin: theme.spacing(2),
    },
    dialogError: {
        textAlign: 'center',
    },
    dialog: {
        backgroundColor: '#007dc3',
        backgroundSize: '960px 540px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        textAlign: 'center',
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
        height: 'auto',
        [theme.breakpoints.down(996)]: {
            // height: '31vh',
            minHeight: '281px',
        },
    },
    dialogActions: {
        backgroundColor: '#007dc3',
        textAlign: 'center',
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
    },
    typographyOneAdvice: {
        fontSize: '2rem',
        fontFamily: 'Roboto, Helvetica',
        fontWeight: 500,
        marginTop: '20px',
        textAlign: 'justify',
        color: 'white',
        [theme.breakpoints.down(996)]: {
            fontSize: '1.37rem',
            marginTop: '20px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            marginLeft: '0px',
            fontSize: '1rem',
            textAlign: 'center',
        },
    },
    typographyOne: {
        fontSize: '2.30rem',
        fontFamily: 'Roboto, Helvetica',
        fontWeight: 500,
        marginTop: '20px',
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.down(996)]: {
            fontSize: '1.25rem',
            marginTop: '20px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            marginLeft: '0px',
            fontSize: '1.2rem',
            textAlign: 'center',
        },
    },
    typographyTwo: {
        fontSize: '1.60rem',
        fontFamily: 'Roboto, Helvetica',
        marginLeft: '30px',
        fontWeight: 400,
    },
    link: {
        fontSize: '1.25rem',
        fontFamily: 'Roboto, Helvetica',
        marginLeft: '30px',
        fontWeight: 400,
    },
    dialogPaper: {
        dialogPaper: {
            [theme.breakpoints.down(996)]: {
                maxWidth: '426px',
                minHeight: '244px',
            },
        },
    },
    avatarSuccess: {
        backgroundColor: green[400],
        color: 'white',
        textAlign: 'center',
        width: 100,
        height: 100,
        marginTop: 10,
        margin: '0 auto',
    },
    avatarFailure: {
        backgroundColor: red[400],
        color: 'white',
        textAlign: 'center',
        marginTop: 10,
        width: 120,
        height: 120,
        margin: '0 auto',
    },
    documentType: {
        minWidth: 120,
    },
    documentTypePaper: {
        maxWidth: 170,
        padding: theme.spacing(),
    },
    adviceOwnerStyle: {
        color: '#000',
        textAlign: 'justify',
    },
    backButton: {
        borderRadius: '25px',
        marginBottom: '20px',
        width: '100px',
        border: '3px solid #ffffff',
        color: '#ffffff',
    },
    actionButtons: {
        borderRadius: '25px',
        marginBottom: '20px',
        width: '100px',
        border: '3px solid #ffffff',
        color: '#ffffff',
    },
    avatarCheck: {
        color: 'white',
        textAlign: 'center',
        width: 100,
        height: 100,
        marginTop: 10,
        margin: '0 auto',
    },
}));
const localeMap = {
    es: esLocale,
};
export default function AssignProperty(props) {
    let getDate = new Date();
    const [document, setDocument] = useState(storage.get('user').id_identity);
    const [form, setForm] = useState({ tenantPhone: '', email: '' });
    const [revenue, setRevenue] = React.useState(getDate);
    const [payday, setPayday] = React.useState(getDate);
    const [open, setOpen] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailure, setOpenFailure] = React.useState(false);
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [documentType, setDocumentType] = React.useState('Cédula');
    const [canSendPayment, setCanSendPayment] = React.useState(false);
    const classes = useStyles('paper');
    const [url, setUrl] = useState(null);
    const [openDialogCopy, setOpenDialogCopy] = React.useState(false);
    const [TenantPhone, setTenantPhone] = React.useState(null);
    const [openDialogInstructional, setDialogInstructional] = React.useState(
        false,
    );
    const urlParams = new URLSearchParams(props.location.search);

    async function openDialog() {
        setOpen(true);
    }
    async function checkPermissions() {
        setLoadingModal(true);
        const permissions = await paymentsService.checkUserCanPay(
            storage.get('user').id,
        );
        setCanSendPayment(permissions.data['canPay']);
        setLoadingModal(false);
    }
    async function getProperties() {
        let endpoint = ApiConstants.GET_OWNER_PROPERTIES_ENDPOINT;
        resourceService
            .post(endpoint, {
                id: storage.get('user').id,
                identification: storage.get('user').id_identity,
            })
            .then((res: any) => {
                const property = res.data.filter(
                    x => urlParams.get('id') == x['Código de Propiedad'],
                );

                setForm({
                    ...form,
                    tenantPhone:
                        property[0]['Teléfono inquilino asignado'] ?? '',
                });
            });
    }

    useEffect(() => {
        checkPermissions();
        getProperties();
    }, []);

    async function onSubmitPaymentLink() {
        setOpen(false);
        setLoadingModal(true);
        let ownerId = storage.get('user').id;
        let isTenant = false;
        let propertyId = urlParams.get('id');
        let tenantPhone = form.tenantPhone;
        let addressee = form.email;
        const assign = await propertiesService.sendLinkTenant(
            tenantPhone,
            document,
            ownerId,
            propertyId,
            addressee,
            revenue,
            payday,
            isTenant,
            '',
            '',
            '',
            '',
        );

        if (assign.status === 200) {
            setUrl(assign.data.url);
            setLoadingModal(false);
            setOpenDialogCopy(true);
            setOpenSuccess(true);
        } else {
            setLoadingModal(false);
            setOpenFailure(true);
        }
    }
    const handleClickOpen = () => {
        setOpenDialogCopy(true);
    };

    const handleClose = () => {
        setOpenDialogCopy(false);
    };
    const handleOpenInstructional = () => {
        setDialogInstructional(true);
    };

    const handleCloseInstructional = () => {
        setDialogInstructional(false);
    };
    const handlePayday = (date: Date | null) => {
        setPayday(date);
    };
    const handleRevenueDate = (date: Date | null) => {
        setRevenue(date);
    };
    const handleAssignProperty = () => {
        setOpen(true);
    };

    const handleCloseTerms = () => {
        setOpen(false);
    };
    const handleTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    function handleCloseFailure() {
        setOpenFailure(false);
    }
    function handleCloseSuccess() {
        setOpenSuccess(false);
    }
    function handleDocumentType(value) {
        setDocument('');
        setDocumentType(value);
    }

    const SuccessDialog = () => {
        return (
            <Dialog
                open={openSuccess}
                onClose={handleCloseSuccess}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                PaperProps={{ classes: { root: classes.dialogPaper } }}
                maxWidth={'md'}
            >
                <DialogContent className={classes.dialog}>
                    <CheckCircleOutlineIcon className={classes.avatarCheck} />
                    <h3 className={classes.typographyOne}>
                        ¡Enlace de pago generado satisfactoriamente!
                    </h3>
                    <Button
                        className={classes.backButton}
                        onClick={handleCloseSuccess}
                        variant="outlined"
                    >
                        Volver
                    </Button>
                </DialogContent>
            </Dialog>
        );
    };
    //Modal rendering
    const ErrorDialog = () => {
        return (
            <div>
                <Dialog
                    open={openFailure}
                    className={classes.dialogError}
                    onClose={handleCloseFailure}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <Avatar className={classes.avatarFailure}>
                        <ErrorIcon style={{ fontSize: '40' }} />
                    </Avatar>
                    <DialogTitle id="alert-dialog-title">
                        {'Error al enviar el enlace de pago'}
                    </DialogTitle>
                    <DialogContent className={classes.dialogError}>
                        <DialogContentText id="alert-dialog-description">
                            No se pudo generar el enlace de pago. Favor intentar
                            mas tarde
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseFailure}
                            color="primary"
                            autoFocus
                        >
                            Volver
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const adviceDialog = () => {
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleCloseTerms}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    PaperProps={{ classes: { root: classes.dialogPaper } }}
                    maxWidth={'md'}
                >
                    <DialogContent className={classes.dialog}>
                        <ReportProblemOutlinedIcon
                            className={classes.avatarCheck}
                        />
                        <h3 className={classes.typographyOneAdvice}>
                            Al aceptar los términos te comprometes a asumir el
                            costo del servicio, equivalente al 5% del monto del
                            alquiler, el cuál será descontando al momento de
                            realizar el depósito en tu cuenta.
                        </h3>
                    </DialogContent>
                    <DialogActions className={classes.terms}>
                        <FormControlLabel
                            className={classes.labelStyles}
                            control={
                                <>
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleTerms}
                                        name="check"
                                        style={{
                                            color: 'white',
                                            transform: 'scale(1.5)',
                                        }}
                                    />
                                    <span className={classes.labelStyles}>
                                        Acepto los términos y condiciones de
                                        pago.
                                    </span>
                                </>
                            }
                            label={''}
                        />
                    </DialogActions>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            className={classes.backButton}
                            onClick={handleCloseTerms}
                            variant="outlined"
                        >
                            CANCELAR
                        </Button>
                        <Button
                            className={classes.backButton}
                            onClick={onSubmitPaymentLink}
                            disabled={!checked}
                            color="primary"
                            autoFocus
                        >
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={loadingModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent dividers>
                        <center>
                            <Typography
                                gutterBottom={true}
                                component="h1"
                                variant="h6"
                            >
                                Enviando enlace...
                                <br />
                                <CircularProgress />
                            </Typography>
                        </center>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };
    return (
        <React.Fragment>
            {!loadingModal && (
                <>
                    {canSendPayment && (
                        <Paper className={classes.paper}>
                            <Grid
                                container
                                xs={12}
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <Typography
                                        className={classes.typography}
                                        variant="h5"
                                        gutterBottom
                                    >
                                        Generar enlace de pago
                                    </Typography>
                                </Grid>
                                {!url && (
                                    <Grid item>
                                        <Typography
                                            style={{ cursor: 'pointer' }}
                                            color="primary"
                                            onClick={handleOpenInstructional}
                                            className={classes.typography}
                                            gutterBottom
                                        >
                                            <InfoIcon />
                                            ¿Cómo generar un enlace de pago?
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <br />
                            <Typography
                                variant="body1"
                                className={classes.invite}
                                gutterBottom
                            >
                                {url
                                    ? 'Comparte este link con tu inquilino'
                                    : 'Ingresa el correo de tu inquilino'}
                            </Typography>
                            <>
                                <SendPaymentLink
                                    openDialog={openDialog}
                                    setForm={setForm}
                                    form={form}
                                    urlGenerate={url}
                                />
                            </>
                            {adviceDialog()}
                            <PaymentLinkDialog
                                // classes={classes.url}
                                url={url}
                                isOpen={openDialogCopy}
                                handleOpen={handleClickOpen}
                                handleClose={handleClose}
                            />
                            <GenerateInstructionalLink
                                isOpen={openDialogInstructional}
                                handleOpen={handleOpenInstructional}
                                handleClose={handleCloseInstructional}
                            />
                            {ErrorDialog()}
                        </Paper>
                    )}

                    {!canSendPayment && (
                        <Advice
                            hasActions={false}
                            titleMessage={'Aviso'}
                            body={`
                     Tu usuario debe ser activado por un administrador.
                    `}
                            subBody={`Para más información puedes contactarnos a info@remaxrd.com.`}
                        />
                    )}
                </>
            )}
        </React.Fragment>
    );
}
