//FOR ENDPOINTS WHO DOESN'T NEED TOKEN
import { AnyAction } from 'redux';
import { Ajax } from '../helpers/ajax';
import { call, put, takeLatest } from 'redux-saga/effects';
import ApiConstants from '../config/api.constants';
import { notify } from '@bndynet/dialog';
import axios, { AxiosResponse } from 'axios';

export const ACTION_SECTOR_REQUEST = 'GET_SECTOR_REQUEST';
export const ACTION_SECTOR_SUCCESS = 'GET_SECTOR_SUCCESS';
export const ACTION_SECTOR_FAILURE = 'GET_SECTOR_FAILURE';

export const ACCEPTED_AMOUNT_REQUEST = 'ACCEPTED_AMOUNT_REQUEST';
export const ACCEPTED_AMOUNT_SUCCESS = 'ACCEPTED_AMOUNT_SUCCESS';
export const ACCEPTED_AMOUNT_FAILURE = 'ACCEPTED_AMOUNT_FAILURE';

export const DENIED_AMOUNT_REQUEST = 'DENIED_AMOUNT_REQUEST';
export const DENIED_AMOUNT_SUCCESS = 'DENIED_AMOUNT_SUCCESS';
export const DENIED_AMOUNT_FAILURE = 'DENIED_AMOUNT_FAILURE';

export interface PublicState {
    sectors?: Array<Object>;
    acceptedAmount?: boolean;
    error?: boolean;
    errorMessage?: string;
    deniedAmount: boolean;
}

export const initialState: PublicState = {
    sectors: [],
    acceptedAmount: false,
    error: false,
    errorMessage: '',
    deniedAmount: false,
};

export const actions = {
    getSectors: () => ({
        type: ACTION_SECTOR_REQUEST,
    }),
    getSectorsSuccess: (response: Object) => ({
        type: ACTION_SECTOR_SUCCESS,
        payload: response,
    }),
    getSectorsFailure: error => ({
        type: ACTION_SECTOR_FAILURE,
        payload: error,
    }),
    acceptedAmount: (token: string) => ({
        type: ACCEPTED_AMOUNT_REQUEST,
        payload: token,
    }),
    acceptedAmountSuccess: (response: Object) => ({
        type: ACCEPTED_AMOUNT_SUCCESS,
        payload: response,
    }),
    acceptedAmountFailure: error => ({
        type: ACCEPTED_AMOUNT_FAILURE,
        payload: error,
    }),
    deniedAmount: (token: string, reason: string) => ({
        type: DENIED_AMOUNT_REQUEST,
        payload: {
            token,
            reason,
        },
    }),
    deniedAmountSuccess: (response: Object) => ({
        type: DENIED_AMOUNT_SUCCESS,
        payload: response,
    }),
    deniedAmountFailure: error => ({
        type: DENIED_AMOUNT_FAILURE,
        payload: error,
    }),
};

export function reducer(
    state: PublicState = initialState,
    action,
): PublicState {
    switch (action.type) {
        case ACTION_SECTOR_REQUEST:
            return {
                ...state,
                sectors: [],
                error: false,
                errorMessage: '',
            };
        case ACTION_SECTOR_SUCCESS:
            return {
                ...state,
                sectors: action.payload.data,
                error: false,
                errorMessage: '',
            };
        case ACTION_SECTOR_FAILURE:
            return {
                ...state,
                sectors: [],
                error: true,
                errorMessage: action.payload,
            };
        case ACCEPTED_AMOUNT_REQUEST:
            return {
                ...state,
                acceptedAmount: false,
                error: false,
                errorMessage: '',
            };
        case ACCEPTED_AMOUNT_SUCCESS:
            return {
                ...state,
                acceptedAmount: true,
                error: false,
                errorMessage: '',
            };
        case ACCEPTED_AMOUNT_FAILURE:
            return {
                ...state,
                acceptedAmount: false,
                error: true,
                errorMessage: action.payload,
            };
        case DENIED_AMOUNT_REQUEST:
            return {
                ...state,
                deniedAmount: false,
                error: false,
                errorMessage: '',
            };
        case DENIED_AMOUNT_SUCCESS:
            return {
                ...state,
                deniedAmount: true,
            };
        case DENIED_AMOUNT_FAILURE: {
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
            };
        }
        default:
            return state;
    }
}

export interface PromiseProps {
    data: any;
    status: unknown;
}

const headers = {
    'Content-Type': 'application/json',
    'X-API-KEY': ApiConstants.X_API_KEY,
};

export const config = {
    headers: headers,
};

export const publicService = {
    getSectors: () => {
        return new Ajax({
            headers: headers,
        }).get(ApiConstants.MAXCORE_SECTORS_ENDPOINT);
    },
    acceptAmount: (token: string) => {
        return new Ajax({
            headers: headers,
            onResponseError: error => {
                notify(
                    'Ocurrió un error, por favor intente mas tarde',
                    'error',
                );
                return Promise.reject(error);
            },
        }).post(ApiConstants.ACCEPTED_PAYMENT + token);
    },
    changeUserRole: async (role, id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.PAYMENT_ADMIN_CHANGE_ROLE, { role, id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    deniedAmount: (token: string, reason: string) => {
        return new Ajax({
            headers: headers,
            onResponseError: error => {
                notify(
                    'Ocurrio un error, por favor intente mas tarde',
                    'error',
                );
                return Promise.reject(error);
            },
        }).post(ApiConstants.CANCEL_PAYMENT_ENDPOINT + token, { reason });
    },
    checkUserInfoRequirements: async (id): Promise<PromiseProps> => {
        let res;
        await axios
            .get(ApiConstants.PAYMENT_ADMIN_CHECK_INFO + id, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    fillUserInfoRequirements: async (inputs, id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.PAYMENT_ADMIN_FILL_INFO, { inputs, id }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
};

/**
 * Worker saga in charge of handling logic for sectors
 */
function* getSectors() {
    try {
        const result = yield call(publicService.getSectors);
        yield put(actions.getSectorsSuccess(result));
    } catch (e) {
        const error = e.data ? e.data.message : e;
        yield put(actions.getSectorsFailure(error));
    }
}

/** Worker saga in charge of handling logic for accepted payments amount */
function* acceptedAmount(action: AnyAction) {
    try {
        const result = yield call(publicService.acceptAmount, action.payload);
        yield put(actions.acceptedAmountSuccess(result));
    } catch (e) {
        const error = e.data ? e.data.message : e;
        yield put(actions.acceptedAmountFailure(error));
    }
}

/** Worker saga in charge of handling logic for denied payments amount */
function* deniedAmount(action: AnyAction) {
    try {
        const { token, reason } = action.payload;
        const result = yield call(publicService.deniedAmount, token, reason);
        yield put(actions.deniedAmountSuccess(result));
    } catch (e) {
        const error = e.data ? e.data.message : e;
        yield put(actions.deniedAmountFailure(error));
    }
}

/**
 * Watcher saga, listen for certain actions that are dispatched
 */
export function* saga() {
    yield takeLatest(ACTION_SECTOR_REQUEST, getSectors);
    yield takeLatest(ACCEPTED_AMOUNT_REQUEST, acceptedAmount);
    yield takeLatest(DENIED_AMOUNT_REQUEST, deniedAmount);
}
