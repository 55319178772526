import * as React from 'react';
import * as intl from 'react-intl-universal';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
    CssBaseline,
    Typography,
    Theme,
    createStyles,
    withStyles,
    CircularProgress,
} from '@material-ui/core';
import { themeConfig } from 'app/theme';
import { Panel } from 'app/ui';
import { connect } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { ApiConstants } from 'app/config';

let config = {
    headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': ApiConstants.X_API_KEY,
    },
};
const styles = (theme: Theme) =>
    createStyles({
        panel: {
            marginTop: theme.spacing(8),
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            width: 400,
            textAlign: 'center',
        },
        body: {
            wordBreak: 'break-all',
        },
        icon: {
            marginBottom: 30,
        },
        success: {
            color: themeConfig.palette.success,
        },
        warning: {
            color: themeConfig.palette.warning,
        },
        textColor: {
            color: 'blue',
            fontSize: '17px',
        },
    });
export interface ParametersProps {
    loading: boolean;
    location: string;
    URLSearchParams: string[];
    message: string;
}

class ActivateUser extends React.Component<ParametersProps> {
    public constructor(props) {
        super(props);
        this.state = {
            loading: true,
            message: '',
        };
    }
    public componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let token = params.get('token');
        axios
            .post(
                `${ApiConstants.CONFIRMATION_ENDPOINT}/${token}`,
                { data: '' },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                this.setState({ message: response.data.message });
                return;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                return error;
            });
        this.setState({
            loading: false,
        });
    }
    public render() {
        const { classes } = this.props;
        const { loading, message } = this.state;
        return (
            <main>
                <CssBaseline />
                <Panel variant={'success'} className={classes.panel}>
                    {loading && (
                        <div>
                            <Typography
                                gutterBottom={true}
                                component="h1"
                                variant="h6"
                            >
                                Activando su cuenta..
                                <br />
                                <CircularProgress />
                            </Typography>
                        </div>
                    )}
                    {!loading && (
                        <div>
                            <i
                                className={classNames(
                                    'fa fa-check-circle fa-5x',
                                    classes.icon,
                                    classes.success,
                                )}
                            />
                            <Typography
                                gutterBottom={true}
                                component="h1"
                                variant="h6"
                            >
                                {message}
                                <br />
                            </Typography>
                            <Link
                                className={classNames(classes.textColor)}
                                to="/login"
                            >
                                Iniciar sesión
                            </Link>
                        </div>
                    )}
                </Panel>
            </main>
        );
    }
}

export default connect(null, null)(withStyles(styles)(ActivateUser));
