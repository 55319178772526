import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    TextField,
    Tooltip,
} from '@material-ui/core';
import validator from 'validator';
import React, { Dispatch, useEffect } from 'react';
import ReCaptchaV2 from 'react-google-recaptcha';
import PaymentLinkDialog from './PaymentLinkDialog.tsx';
import InputMask from 'react-input-mask';

interface Form {
    tenantPhone: any | string;
    email: string;
}
export interface PaymentLinkProps {
    openDialog: () => void;
    form: Form;
    setForm: React.Dispatch<React.SetStateAction<Form>>;
    urlGenerate?: string;
    tenantPhone: string | null;
}

// Dispatch < SetStateAction < object < { tenantPhone: number || null; email: string } >>;

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: 20,
    },
    typography: {
        textAlign: 'center',
        alignItems: 'center',
    },
    loader: {
        color: 'white',
        top: '50%',
        left: '50%',
    },
    url: {
        '& a': {
            width: '33vw',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            color: '#60609f',
        },
    },
}));

export default function SendPaymentLink(props: PaymentLinkProps) {
    // const [email, setEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [errorText, setErrorText] = React.useState('');
    const [errorTextConfirm, setErrorTextConfirm] = React.useState('');
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [isPhoneInvalid, setIsPhoneInvalid] = React.useState(true);
    const [propertyId, setPropertyId] = React.useState('');
    const [form, setForm] = React.useState();
    const [EmailConfirm, setEmailConfirm] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const [snackbar, setSnackbar] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = snackbar;
    const classes = useStyles('paper');
    const snackBarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    async function handleEmail(value) {
        props.setForm({ tenantPhone: props.form.tenantPhone, email: value });
        if (validator.isEmail(value)) {
            setErrorText('');
            setIsDisabled(false);
        } else {
            setErrorText('Debes ingresar un correo válido');
            setIsDisabled(true);
        }
    }
    async function handleConfirmEmail(value) {
        setEmailConfirm(value);
        if (validator.isEmail(value) && value == props.form.email) {
            setErrorTextConfirm('');
            setIsDisabled(false);
        } else {
            setErrorTextConfirm('Ambos correos deben ser iguales');
            setIsDisabled(true);
        }
    }
    useEffect(() => {
        setIsPhoneInvalid(props.form.tenantPhone.match(/(1[0-9]{10})/gm));
    }, []);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleToken = token => {
        setForm(currentForm => {
            return { ...currentForm, token };
        });
    };
    const handleTelOpt = e => {
        const value: string = e.target.value.replace(/[^0-9\.]+/g, '');
        setPhone(value);

        props.setForm(
            Object.assign(props.form, {
                tenantPhone: value,
            }),
        );
        if (value.match(/(1[0-9]{10})/gm)) {
            setIsPhoneInvalid(false);
        } else {
            setIsPhoneInvalid(true);
        }
    };

    return (
        <>
            {props.urlGenerate == null ? (
                <Grid item xl={6} xs={12} md={6} sm={12} spacing={2}>
                    <Grid item>
                        <br />
                        <TextField
                            error={errorText !== '' ? true : false}
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Escribe el correo"
                            name="email"
                            autoComplete="email"
                            value={props.form.email}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleEmail(e.target.value)}
                            helperText={errorText}
                        />
                    </Grid>
                    <Grid item>
                        <br />
                        <TextField
                            error={errorTextConfirm !== '' ? true : false}
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Confirme el correo"
                            name="email"
                            autoComplete="email"
                            value={EmailConfirm}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleConfirmEmail(e.target.value)}
                            helperText={errorTextConfirm}
                        />
                    </Grid>
                    <br />
                    <Grid item>
                        <InputMask
                            mask="1-999-999-9999"
                            value={props.form.tenantPhone}
                            label="No. de teléfono de tu inquilino*"
                            onChange={e => handleTelOpt(e)}
                        >
                            {() => (
                                <TextField
                                    variant="outlined"
                                    id="cardOwnerTel"
                                    label="Teléfono de tu inquilino*"
                                    fullWidth
                                    autoComplete="cc-last"
                                />
                            )}
                        </InputMask>
                    </Grid>

                    {/* <ReCaptchaV2
                    style={{ marginTop: 10 }}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={handleToken}
                    // onExpire={handleExpire}
                /> */}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            !isDisabled &&
                            !isPhoneInvalid &&
                            EmailConfirm == props.form.email
                                ? false
                                : true
                        }
                        onClick={() => props.openDialog()}
                        className={classes.button}
                    >
                        <span style={{ color: 'white' }}>
                            {!loading ? (
                                'Generar enlace'
                            ) : (
                                <CircularProgress
                                    size={20}
                                    className={classes.loader}
                                />
                            )}
                        </span>
                    </Button>
                </Grid>
            ) : (
                <Grid item xl={6} xs={12} md={6} sm={12}>
                    <PaymentLinkDialog
                        classes={classes.url}
                        url={props.urlGenerate}
                        isOpen={openDialog ?? props.urlGenerate}
                        handleOpen={handleClickOpen}
                        handleClose={handleClose}
                    />
                    <Button onClick={handleClickOpen} color="primary" autoFocus>
                        Compartir enlace
                    </Button>
                </Grid>
            )}
        </>
    );
}
