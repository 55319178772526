import axios, { AxiosResponse } from 'axios';
import ApiConstants from '../config/api.constants';

export const config = {
    headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': ApiConstants.X_API_KEY,
    },
};

export interface CheckUserProps {
    token: unknown;
}

export interface SendMailProps {
    status: number;
    email: string;
}

export interface ChangePasswordProps {
    status: number;
    password: string;
    confirmPassword: string;
}

export const resetPassword = {
    sendMail: async (email): Promise<SendMailProps> => {
        let res;
        await axios
            .post(ApiConstants.SEND_RESET_EMAIL_ENDPOINT, { email }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    checkUser: async token => {
        await axios
            .post(ApiConstants.CHECK_USER_ENDPOINT, { token }, config)
            .then(response => {
                //handle success
                return response.data;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                return error;
            });
        return;
    },
    changePassword: async (password, email): Promise<ChangePasswordProps> => {
        let res;
        await axios
            .post(
                ApiConstants.CHANGE_PASSWORD_ENDPOINT,
                { password, email },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
};
