import React from 'react';

export default function Contact() {
    let domain = window.location.href.split('/')[2];
    return (
        <div data-name="top">
            <br />
            <h1>Contacto</h1>
            <h3 className="sub-title-welcome">
                <i className="fa fa-map-marker fa-1x" /> Dirección:{' '}
                <span style={{ color: '#003DA5' }}>
                    Avenida Gustavo Mejía Ricart #52, Segundo Piso, Ensanche
                    Naco, Santo Domingo, República Dominicana.
                </span>
            </h3>
            <h3 className="sub-title-welcome">
                <i className="fa fa-phone fa-1x" /> Teléfono:{' '}
                <span style={{ color: '#003DA5' }}>(829) 213-3278</span>
            </h3>
            <h3 className="sub-title-welcome">
                <i className="fa fa-envelope fa-1x" /> Correo:{' '}
                <span style={{ color: '#003DA5' }}>info@remaxrd.com</span>
            </h3>
            <h3 className="sub-title-welcome">
                <i className="fa fa-globe fa-1x" /> Sitio Web:
                <span style={{ color: '#003DA5' }}> {domain}</span>
            </h3>
        </div>
    );
}
