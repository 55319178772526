import React from 'react';
import { Dispatch, Action } from 'redux';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from 'react-router';
import {
    CssBaseline,
    withStyles,
    CircularProgress,
    createStyles,
    Button,
    Theme,
    Grid,
    TextField,
    WithStyles,
} from '@material-ui/core';
import { Panel } from 'app/ui';
import { connect } from 'react-redux';
import { themeConfig } from 'app/theme';
import { actions as publicActions } from 'app/service/public';

const styles = (theme: Theme) =>
    createStyles({
        panel: {
            marginTop: theme.spacing(8),
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            width: window.innerWidth < 600 ? 300 : 480,
            textAlign: 'center',
        },
        root: {
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        body: {
            wordBreak: 'break-all',
        },
        icon: {
            marginBottom: 30,
        },
        success: {
            color: themeConfig.palette.success,
        },
        warning: {
            color: themeConfig.palette.warning,
        },
        textColor: {
            color: 'blue',
        },
    });
interface IAcceptPaymentProps extends RouteComponentProps, WithStyles {
    loading: boolean;
    message: string;
    token: string;
    canceled: boolean;
    asking: boolean;
    accepted: boolean;
    acceptedAmount: boolean;
    acceptedAmountRequest: Function;
    deniedAmount: boolean;
    deniedAmountRequest: Function;
}

interface IAcceptPaymentState {
    loading: boolean;
    message: string;
    token: string;
    canceled: boolean;
    asking: boolean;
    accepted: boolean;
    reason: string;
    deniedReason: boolean;
    amount: string;
    approved: string;
}

class AcceptPayment extends React.Component<
    IAcceptPaymentProps,
    IAcceptPaymentState
> {
    public constructor(props: IAcceptPaymentProps) {
        super(props);
        this.state = {
            loading: false,
            message: '',
            token: '',
            canceled: false,
            asking: false,
            accepted: false,
            reason: '',
            deniedReason: false,
            amount: '',
            approved: '',
        };
        this.acceptAmountSubmit = this.acceptAmountSubmit.bind(this);
        this.deniedAmountSubmit = this.deniedAmountSubmit.bind(this);
        this.returnToApprovement = this.returnToApprovement.bind(this);
        this.goToDeniedReason = this.goToDeniedReason.bind(this);
    }

    public componentDidUpdate(prevProps: IAcceptPaymentProps) {
        if (
            prevProps.acceptedAmount !== this.props.acceptedAmount &&
            this.props.acceptedAmount
        ) {
            this.setState({
                canceled: false,
                accepted: true,
                asking: false,
                loading: false,
                deniedReason: false,
            });
        }

        if (
            prevProps.deniedAmount !== this.props.deniedAmount &&
            this.props.deniedAmount
        ) {
            this.setState({
                canceled: true,
                accepted: false,
                asking: false,
                loading: false,
                deniedReason: false,
            });
        }
    }

    public acceptAmountSubmit() {
        this.setState({
            canceled: false,
            accepted: false,
            asking: false,
            loading: true,
            deniedReason: false,
        });
        this.props.acceptedAmountRequest(this.state.token);
    }

    public goToDeniedReason() {
        this.setState({
            canceled: false,
            accepted: false,
            asking: false,
            loading: false,
            deniedReason: true,
        });
        return;
    }
    public deniedAmountSubmit() {
        this.setState({
            canceled: false,
            accepted: false,
            asking: false,
            deniedReason: false,
            loading: true,
        });
        this.props.deniedAmountRequest(this.state.token, this.state.reason);
    }
    public returnToApprovement() {
        this.setState({
            canceled: false,
            accepted: false,
            asking: true,
            loading: false,
            deniedReason: false,
        });

        return;
    }

    public componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        if (params.get('token')) {
            this.setState({
                loading: false,
                asking: true,
                token: params.get('token'),
                amount: params.get('amount'),
                approved: params.get('approved'),
            });
            if (params.get('approved') !== '1') {
                this.goToDeniedReason();
            }
        }
    }
    public render() {
        const { classes } = this.props;
        const {
            loading,
            canceled,
            asking,
            accepted,
            deniedReason,
            reason,
        } = this.state;
        return (
            <main>
                <CssBaseline />
                <Panel variant={'primary'} className={classes.panel}>
                    {loading === false &&
                        accepted === false &&
                        canceled === false &&
                        deniedReason === false &&
                        asking === true && (
                            <React.Fragment>
                                <br />
                                <center>
                                    <Typography variant="h6" gutterBottom>
                                        {`Si estás de acuerdo con el monto indicado presiona el botón`}{' '}
                                        <b>{'Aceptar'}</b>
                                    </Typography>
                                    <br />
                                    <Typography gutterBottom>
                                        <strong>{`Monto: RD$ ${parseFloat(
                                            this.state.amount,
                                        )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ',',
                                            )}`}</strong>
                                    </Typography>
                                </center>
                                <div className={classes.root}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.acceptAmountSubmit}
                                    >
                                        {'Aceptar'}
                                    </Button>{' '}
                                </div>
                            </React.Fragment>
                        )}
                    {accepted === false &&
                        canceled === false &&
                        deniedReason === false &&
                        asking === false &&
                        loading === true && (
                            <div>
                                <Typography
                                    gutterBottom={true}
                                    component="h1"
                                    variant="h6"
                                >
                                    Cargando datos..
                                    <br />
                                    <CircularProgress />
                                </Typography>
                            </div>
                        )}
                    {loading === false &&
                        deniedReason === false &&
                        canceled === false &&
                        asking === false &&
                        accepted === true && (
                            <React.Fragment>
                                <br />{' '}
                                <center>
                                    <Typography variant="h6" gutterBottom>
                                        {`Monto Aceptado.`}
                                        <br />
                                    </Typography>
                                    <span style={{ fontSize: '18px' }}>
                                        {` Gracias por confirmar.`}
                                    </span>
                                    <br />
                                    <span style={{ fontSize: '18px' }}>
                                        {`Le notificaremos al  inquilino vía correo electrónico.`}
                                    </span>
                                    <br />
                                    <br />
                                </center>
                            </React.Fragment>
                        )}
                    {loading === false &&
                        accepted === false &&
                        deniedReason === false &&
                        asking === false &&
                        canceled === true && (
                            <React.Fragment>
                                <br />{' '}
                                <center>
                                    <Typography variant="h6" gutterBottom>
                                        {`Monto Rechazado.`}
                                        <br />
                                    </Typography>
                                    <span style={{ fontSize: '18px' }}>
                                        {` Gracias por confirmar.`}
                                    </span>
                                    <br />
                                    <span style={{ fontSize: '18px' }}>
                                        {`Le notificaremos al inquilino vía correo electrónico.`}
                                    </span>
                                    <br />
                                    <br />
                                </center>
                            </React.Fragment>
                        )}
                    {loading === false &&
                        accepted === false &&
                        asking === false &&
                        canceled === false &&
                        deniedReason === true && (
                            <React.Fragment>
                                <center>
                                    <Typography variant="h6" gutterBottom>
                                        {
                                            '¿Cuál es la razón por la que rechazas este pago?'
                                        }
                                        <br />
                                    </Typography>
                                    <br />
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Escribe la razón"
                                            required
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows="6"
                                            onChange={e =>
                                                this.setState({
                                                    reason: e.target.value,
                                                })
                                            }
                                            rowsMax="6"
                                        />
                                    </Grid>

                                    <div className={classes.root}>
                                        {/* <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={this.returnToApprovement}
                                        >
                                            <span style={{ color: 'white' }}>
                                                {'Volver'}
                                            </span>
                                        </Button>{' '} */}
                                        <Button
                                            disabled={
                                                this.state.reason.length > 0
                                                    ? false
                                                    : true
                                            }
                                            variant="contained"
                                            color="primary"
                                            onClick={this.deniedAmountSubmit}
                                        >
                                            <span style={{ color: 'white' }}>
                                                {'Enviar'}
                                            </span>
                                        </Button>{' '}
                                    </div>
                                </center>
                            </React.Fragment>
                        )}
                </Panel>
            </main>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    acceptedAmountRequest: (token: string) =>
        dispatch(publicActions.acceptedAmount(token)),
    deniedAmountRequest: (token: string, reason: string) =>
        dispatch(publicActions.deniedAmount(token, reason)),
});

const mapStateToProps = state => ({
    acceptedAmount: state.publicReducer.acceptedAmount,
    deniedAmount: state.publicReducer.deniedAmount,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AcceptPayment));
