import * as React from 'react';
import _merge from 'lodash-es/merge';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
    Theme,
    createStyles,
    withStyles,
    LinearProgress,
} from '@material-ui/core';

import { routes } from './routers/routes';
import { themeConfig } from 'app/theme';
import { Notifier, NotifierOptions, Overlay, Loading } from 'app/ui';
import { actions as globalActions } from 'app/service/global';
import { KEY_THEME } from 'app/theme';
import storage from 'app/helpers/storage';
import { onAppInit } from './app.events';
import utils from './helpers/utils';
import CornerRibbon from 'react-corner-ribbon';
import { hj } from './scripts';

const styles = (theme: Theme) => {
    return createStyles({
        '@global': {
            a: {
                color: 'inherit',
            },
            '.recharts-tooltip-label': {
                color: theme.palette.common.black,
            },
        },
        progressBar: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 2000,
        },
        backgroundTest: {
            backgroundColor: 'black',
        },
        ribbonStyles: {
            marginTop: '60px',
            fontSize: 30,
            zIndex: 101,
        },
    });
};

interface AppComponentProps {
    classes?: any;
    loading: boolean;
    loadingText: string;
    requesting: boolean;
    showNotifier: boolean;
    notifierOptions: NotifierOptions;
    theme: any;
    onCloseNotifier: () => void;
    isAdmin: number;
    role: number;
}

interface AppComponentState {
    initDone: boolean;
    showCornerRibbon: boolean;
}

class App extends React.Component<AppComponentProps, AppComponentState> {
    public constructor(props) {
        super(props);
        this.state = {
            initDone: false,
            showCornerRibbon: false,
        };
    }
    public componentDidMount() {
        onAppInit({
            localeDone: () => {
                this.setState({ initDone: true });
            },
        });
    }

    public render() {
        const { classes, theme, notifierOptions, showNotifier } = this.props;

        // scrips
        hj();
        // scrips
        return (
            this.state.initDone && (
                <div className={theme.palette.type}>
                    <MuiThemeProvider theme={theme}>
                        <LinearProgress
                            hidden={!this.props.requesting}
                            color="secondary"
                            className={classes.progressBar}
                        />
                        <Notifier
                            options={notifierOptions}
                            open={showNotifier}
                            onCloseButtonClick={this.props.onCloseNotifier}
                            hasCloseButton={true}
                        />
                        {/* USER ROLE RIBBON
                        {storage.get('user') !== null &&
                            this.props.isAdmin === 0 &&
                            window.location.pathname.includes(
                                'confirm-payment',
                            ) === false &&
                            window.location.pathname.includes('enlace-pago') ===
                                false &&
                            window.location.pathname.includes(
                                'accept-payment',
                            ) === false &&
                            window.location.pathname.includes('logout') ===
                                false && (
                                <CornerRibbon
                                    position="top-right" // OPTIONAL, default as "top-right"
                                    fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                                    backgroundColor={
                                        this.props.role === 2 ||
                                        storage.get('user').user_type === 2
                                            ? '#003DA5'
                                            : '#DC1C2E'
                                    } // OPTIONAL, default as "#2c7"
                                    style={{
                                        marginTop:
                                            window.innerWidth > 960
                                                ? '60px'
                                                : '56px',
                                        fontSize:
                                            window.innerWidth < 760 ? 18 : 30,
                                        zIndex: 101,
                                    }} // OPTIONAL, style of ribbon content
                                >
                                    {this.props.role === 2 ||
                                    storage.get('user').user_type === 2
                                        ? 'Propietario'
                                        : 'Inquilino'}
                                </CornerRibbon>
                            )} */}
                        <Overlay open={this.props.loading}>
                            <Loading loadingText={this.props.loadingText} />
                        </Overlay>
                        {utils.renderRoutes(routes)}
                    </MuiThemeProvider>
                </div>
            )
        );
    }
}

const mapStateToProps = state => {
    const clientTheme = state.global.theme || storage.get(KEY_THEME);
    const finalTheme = clientTheme
        ? _merge({}, themeConfig, clientTheme)
        : themeConfig;
    const muiFinalTheme = createMuiTheme(finalTheme);
    return {
        loading: state.global.loading,
        loadingText: state.global.loadingText,
        requesting: state.global.requesting,
        notifierOptions: state.global.notifierOptions,
        showNotifier: state.global.showNotifier,
        theme: muiFinalTheme,
        role: state.global.role,
        user: state.auth.user,
        isAdmin: state.global.isAdmin,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onCloseNotifier: () => dispatch(globalActions.unnotify()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(App));
