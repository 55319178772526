import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { loading } from '@bndynet/dialog';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    InputAdornment,
    InputLabel,
    makeStyles,
    Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { propertiesService } from 'app/service/properties';
import { propertyTypes, businessTypes, OptionType } from './types/IProperties';
import Select from 'react-select';
import storage from 'app/helpers/storage';
import ErrorIcon from '@material-ui/icons/Error';
import { red, green } from '@material-ui/core/colors';
import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { actions as publicActions } from 'app/service/public';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { CalendarToday } from '@material-ui/icons';
import Dropzone from './components/Dropzone';
import LeavingPageAdvice from './components/LeavingPageAdvice';

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const localeMap = {
    es: esLocale,
};
const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    typography: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    icon: {
        margin: theme.spacing(2),
    },
    avatarSuccess: {
        backgroundColor: green[400],
        color: 'white',
        textAlign: 'center',
        width: 100,
        height: 100,
        marginTop: 10,
        margin: '0 auto',
    },
    avatarFailure: {
        backgroundColor: red[400],
        color: 'white',
        textAlign: 'center',
        marginTop: 10,
        width: 120,
        height: 120,
        margin: '0 auto',
    },
    dialogError: {
        textAlign: 'center',
    },
    dialog: {
        backgroundImage: `url("${require('../../../assets/images/owner-dialog-background.jpg')}")`,
        backgroundSize: '960px 540px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
        height: '540px',
        [theme.breakpoints.down(996)]: {
            backgroundImage: `url("${require('../../../assets/images/owner-dialog-background.jpg')}")`,
            backgroundSize: '448px 281px',
            height: '31vh',
            minHeight: '281px',
        },
        [theme.breakpoints.down(519)]: {
            backgroundImage: `none`,
        },
        [theme.breakpoints.down(420)]: {
            backgroundImage: `none`,
        },
    },
    typographyOne: {
        fontSize: '2.30rem',
        fontFamily: 'Roboto, Helvetica',
        fontWeight: 'bold',
        marginTop: '100px',
        marginLeft: '30px',
        [theme.breakpoints.down(996)]: {
            fontSize: '1.25rem',
            marginTop: '20px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            marginLeft: '0px',
            fontSize: '1.2rem',
            textAlign: 'center',
        },
    },
    typographyTwo: {
        fontSize: '1.70rem',
        fontFamily: 'Roboto, Helvetica',
        marginLeft: '30px',
        fontWeight: 400,
        [theme.breakpoints.down(996)]: {
            fontSize: '1.05rem',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            // display: 'none',
            fontSize: '0.90rem',
            textAlign: 'center',
        },
    },
    link: {
        fontSize: '1.25rem',
        fontFamily: 'Roboto, Helvetica',
        marginLeft: '30px',
        fontWeight: 400,
        [theme.breakpoints.down(996)]: {
            fontSize: '0.95rem',
            fontFamily: 'Roboto, Helvetica',
            fontWeight: 400,
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            fontSize: '0.70rem',
            textAlign: 'center',
            marginLeft: '13%',
        },
        [theme.breakpoints.down(380)]: {
            marginLeft: '7%',
            fontSize: '0.75rem',
        },
    },
    dialogPaper: {
        [theme.breakpoints.down(996)]: {
            maxWidth: '426px',
            minHeight: '244px',
        },
    },
    backButton: {
        marginLeft: '30px',
        borderRadius: '25px',
        marginTop: '50px',
        width: '100px',
        border: '3px solid #007dc3',
        color: '#007dc3',
        [theme.breakpoints.down(996)]: {
            width: '80px',
            border: '2px solid #007dc3',
            marginTop: '15px',
            marginLeft: '10px',
        },
        [theme.breakpoints.down(421)]: {
            left: '32%',
        },
        [theme.breakpoints.down(380)]: {
            marginBottom: '50px',
            left: '28%',
        },
    },
    backButtonProperties: {
        display: 'none',
    },
    iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
            color: red[800],
        },
    },
}));
function AddProperty(props) {
    const classes = useStyles('paper');
    let getDate = new Date();
    const [sector, setSector] = React.useState('');
    const [sectorData, setSectorData] = React.useState();
    const [city, setCity] = React.useState('');
    const [cityName, setCityName] = React.useState();
    const [citiesData, setCitiesData] = React.useState([]);
    const [rawSectors, setRawSectors] = React.useState([]);
    const [bedrooms, setBedrooms] = React.useState('');
    const [bathrooms, setBathrooms] = React.useState('');
    const [meters, setMeters] = React.useState('');
    const [parking, setParking] = React.useState('');
    const [activeSector, setActiveSector] = React.useState(false);
    const [sectorId, setSectorId] = React.useState(0);
    const [address, setAddress] = React.useState('');
    const [business, setBusiness] = React.useState('');
    const [businessId, setBusinessId] = React.useState(0);
    const [types, setTypes] = React.useState('');
    const [typesId, setTypesId] = React.useState(0);
    const [price, setPrice] = React.useState('');
    const [files, setFiles] = React.useState([]);
    const [currencyId, setCurrencyId] = React.useState(1);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openFailure, setOpenFailure] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [revenue, setRevenue] = React.useState(getDate);
    const [payday, setPayday] = React.useState(getDate);
    const [contract, setContract] = React.useState<any[]>([]);
    //Labels for types
    const [propertyName, setPropertyName] = React.useState('');
    const [businessName, setBusinessName] = React.useState('');
    const [sectorName, setSectorName] = React.useState('');
    const [back, setBack] = React.useState(true);
    function setPropertyType(value) {
        setTypes({ name: value.label, label: value.label });
        setTypesId(value.id);
        setPropertyName(value.label);
    }

    function setBusinessType(value) {
        setBusiness({ name: value.label, label: value.label });
        setBusinessId(value.id);
        setBusinessName(value.label);
    }

    function NumberFormatCustom(props: NumberFormatCustomProps) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
                prefix="RD$"
            />
        );
    }

    function fieldsFilled() {
        if (
            typesId !== 0 &&
            sectorId !== 0 &&
            businessId !== 0 &&
            address !== '' &&
            parking !== '' &&
            bathrooms !== '' &&
            bedrooms !== '' &&
            price !== '' &&
            currencyId !== 0 &&
            meters !== '' &&
            propertyName !== '' &&
            cityName !== '' &&
            city !== null &&
            sectorName !== '' &&
            businessName !== '' &&
            contract.length > 0
        ) {
            setSubmitDisabled(false);
            return [
                true,
                {
                    typesId,
                    sectorId,
                    businessId,
                    address,
                    parking,
                    bathrooms,
                    bedrooms,
                    price,
                    currencyId,
                    meters,
                    propertyName,
                    businessName,
                    cityName,
                    sectorName,
                    revenue,
                    payday,
                    contract,
                },
            ];
        } else {
            setSubmitDisabled(true);
        }
    }

    useEffect(() => {
        if (props.sectors.length <= 0) {
            props.getSectors();
        }
    }, []);
    useEffect(() => {
        if (props.sectors.length > 0) setRawSectors(props.sectors);
        setCitiesData(
            props.sectors.map(({ id, name }) => ({
                id: id,
                city: name,
            })),
        );
    }, [props, props.sectors]);
    useEffect(() => {
        fieldsFilled();
    });

    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: '#ffffff',
            cursor: 'pointer',
        }),
        option: () => {
            return {
                backgroundColor: '#ffffff',
                cursor: 'pointer',
            };
        },
    };

    const cities: OptionType[] = citiesData.map(element => ({
        value: element.city,
        label: element.city,
    }));

    async function handleSelect(e) {
        setCity(e);
        setCityName(e.value);
        setSector('Cargando...');
        setActiveSector(false);
        setTimeout(function() {
            rawSectors.map(data => {
                if (data.name === e.value) {
                    const cities: OptionType[] = data.cities[0].sectors.map(
                        data => ({
                            value: data.name,
                            label: data.name,
                            id: data.id,
                        }),
                    );
                    //Uses to organize alphabetically the select options
                    cities.sort(function(a, b) {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    });
                    setSectorData(cities);
                }
            });
            setActiveSector(true);
        }, 500);
    }

    // toggle modal
    function handleClickOpenSuccess() {
        setOpenSuccess(true);
        setBack(true);
    }

    function handleClickOpenFail() {
        setOpenFailure(true);
        setBack(true);
    }

    function handleCloseFailure() {
        setOpenFailure(false);
    }

    function handleCloseSuccess() {
        setOpenSuccess(false);
    }

    //Modal rendering
    const SuccessDialog = () => {
        return (
            <Dialog
                open={openSuccess}
                onClose={handleCloseSuccess}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                PaperProps={{ classes: { root: classes.dialogPaper } }}
                maxWidth={'md'}
            >
                <DialogContent className={classes.dialog}>
                    <h3 className={classes.typographyOne}>
                        ¡Propiedad creada <br /> satisfactoriamente!
                    </h3>
                    <h3 className={classes.typographyTwo}>
                        Puedes consultar tus propiedades <br />
                        creadas en este enlace: <br />
                    </h3>
                    <Link
                        className={classes.link}
                        to="/propiedades"
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                        }}
                    >
                        Ver mis propiedades registradas
                    </Link>
                    <br />
                    <Button
                        className={classes.backButton}
                        onClick={handleCloseSuccess}
                        variant="outlined"
                    >
                        Volver
                    </Button>
                </DialogContent>
            </Dialog>
        );
    };

    //Modal rendering
    const ErrorDialog = () => {
        return (
            <div>
                <Dialog
                    open={openFailure}
                    className={classes.dialogError}
                    onClose={handleCloseFailure}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <Avatar className={classes.avatarFailure}>
                        <ErrorIcon style={{ fontSize: '40' }} />
                    </Avatar>
                    <DialogTitle id="alert-dialog-title">
                        {'Error al crear la propiedad'}
                    </DialogTitle>
                    <DialogContent className={classes.dialogError}>
                        <DialogContentText id="alert-dialog-description">
                            No se pudo crear la propiedad. Favor intentar mas
                            tarde
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseFailure}
                            color="primary"
                            autoFocus
                        >
                            Volver
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
    async function cleanFields() {
        setTypesId(0);
        setSectorId(0);
        setBusinessId(0);
        setAddress('');
        setParking('');
        setBathrooms('');
        setBedrooms('');
        setPrice('');
        setMeters('');
        setPropertyType({ label: '', id: 0 });
        setBusinessType({ label: '', id: 0 });
        setCity('');
        setCityName('');
        setSectorName('');
        setRevenue(new Date());
        setPayday(new Date());
        setContract([]);
        setFiles([]);
        setActiveSector(false);
    }

    async function onPropertyRegistration() {
        loading();
        const data = fieldsFilled();
        if (data[0]) {
            const response = await propertiesService.addProperty(
                data[1],
                storage.get('user'),
            );
            if (response['status'] === 200) {
                loading(false);
                handleClickOpenSuccess();
                cleanFields();
            } else {
                handleClickOpenFail();
            }
        } else {
            handleClickOpenFail();
        }
    }

    async function getSectorId(values) {
        setSectorName(values.value);
        setSector(values.name);
        setSectorId(values.id);
    }
    const handleDateChange = (date: Date | null) => {
        setPayday(date);
    };
    const RenderSectorsSelect = () => {
        return (
            <Grid item xs={12} md={4}>
                <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                <Select
                    inputId="react-select-single"
                    styles={colourStyles}
                    TextFieldProps={{
                        label: 'Sector',
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    value={sector}
                    onChange={values => {
                        getSectorId(values);
                        setBack(false);
                    }}
                    options={sectorData}
                    placeholder="Escoge un sector"
                />
            </Grid>
        );
    };

    const RenderCitySelect = () => {
        return (
            <Grid item xs={12} md={4}>
                <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
                <Select
                    inputId="react-select-single"
                    styles={colourStyles}
                    TextFieldProps={{
                        label: 'Ciudad *',
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    value={city}
                    onChange={e => {
                        handleSelect(e);
                        setBack(false);
                    }}
                    options={cities}
                    placeholder="Escoge una ciudad"
                />
            </Grid>
        );
    };

    function handleValidations(value, name) {
        setBack(false);
        switch (name) {
            case 'parking':
                if (value.match(/^[0-9]*$/)) {
                    setParking(value);
                }
                break;
            case 'bedrooms':
                if (value.match(/^[0-9]*$/)) {
                    setBedrooms(value);
                }
                break;
            case 'bathrooms':
                if (value.match(/^[0-9]*$/)) {
                    setBathrooms(value);
                }
                break;
            case 'meters':
                if (value.match(/^[0-9]*$/)) {
                    setMeters(value);
                }
                break;
            case 'price':
                if (value.match(/^[0-9]*$/)) {
                    setMeters(value);
                }
                break;

            default:
                break;
        }
    }
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Typography
                    className={classes.typography}
                    variant="h5"
                    gutterBottom
                >
                    Registrar propiedad
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={6}>
                        <InputLabel id="demo-simple-select-label">
                            Precio del alquiler (RD$) *
                        </InputLabel>
                        <TextField
                            id="price"
                            label=""
                            fullWidth
                            value={price}
                            onBlur={e => {
                                setPrice(
                                    e.target.value.replace(/[^0-9\.]+/g, ''),
                                );
                            }}
                            onChange={() => setBack(false)}
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                        <InputLabel id="demo-simple-select-label">
                            Tipo de Propiedad
                        </InputLabel>
                        <Select
                            id="demo-simple-select"
                            styles={colourStyles}
                            value={types}
                            required
                            onChange={values => {
                                setPropertyType(values);
                                setBack(false);
                            }}
                            options={propertyTypes}
                            placeholder="Escoge un tipo *"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sm={6}>
                        <InputLabel id="demo-simple-select-label">
                            Tipo de Alquiler
                        </InputLabel>
                        <Select
                            id="demo-simple-select"
                            styles={colourStyles}
                            required
                            value={business}
                            onChange={values => {
                                setBack(false);
                                setBusinessType(values);
                            }}
                            options={businessTypes}
                            placeholder="Escoge un tipo *"
                        />
                    </Grid>
                    {RenderCitySelect()}
                    {activeSector && RenderSectorsSelect()}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="meters"
                            required
                            label="MTS² de construcción"
                            fullWidth
                            value={meters}
                            onChange={e => {
                                handleValidations(e.target.value, 'meters');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            id="bedrooms"
                            label="Habitaciones *"
                            value={bedrooms}
                            fullWidth
                            onChange={e => {
                                handleValidations(e.target.value, 'bedrooms');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            id="bathrooms"
                            label="Baños *"
                            value={bathrooms}
                            fullWidth
                            onChange={e => {
                                handleValidations(e.target.value, 'bathrooms');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <TextField
                            id="parking"
                            label="Parqueos *"
                            value={parking}
                            fullWidth
                            onChange={e => {
                                handleValidations(e.target.value, 'parking');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            id="address"
                            label="Dirección *"
                            fullWidth
                            value={address}
                            onChange={e => {
                                setAddress(e.target.value);
                                setBack(false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Contrato de alquiler
                        </Typography>
                        <Dropzone
                            setContract={setContract}
                            setFiles={setFiles}
                            contract={contract}
                            files={files}
                            setBack={setBack}
                            fieldsFilled={fieldsFilled}
                        />
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                            Fecha de renovación de contrato
                        </Typography>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            style={{ cursor: 'pointer' }}
                            locale={localeMap['es']}
                        >
                            <DatePicker
                                disablePast
                                required
                                openTo="year"
                                views={['year', 'month', 'date']}
                                value={revenue}
                                style={{ cursor: 'pointer' }}
                                format="dd/MM/yyyy"
                                label="Escoge la fecha"
                                id="date-picker-inline"
                                onChange={e => {
                                    setRevenue(e);
                                    setBack(false);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <Icon>
                                                <CalendarToday />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} xs={12}>
                        <Typography variant="body1" gutterBottom>
                            Día de pago del alquiler
                        </Typography>

                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={localeMap['es']}
                        >
                            <DatePicker
                                value={payday}
                                format="dd"
                                id="date-picker-inline"
                                onChange={e => {
                                    handleDateChange(e);
                                    setBack(false);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <Icon>
                                                <CalendarToday />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onPropertyRegistration()}
                            disabled={submitDisabled}
                            className={classes.button}
                        >
                            <span style={{ color: 'white' }}>
                                Registrar propiedad
                            </span>
                        </Button>
                    </Grid>
                </Grid>
                {back === false && <LeavingPageAdvice prompt={props} />}
                {SuccessDialog()}
                {ErrorDialog()}
            </Paper>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    sectors: state.publicReducer.sectors,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    getSectors: () => {
        dispatch(publicActions.getSectors());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProperty);
