import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyle = makeStyles(theme =>
    createStyles({
        contains: {
            [theme.breakpoints.down(600)]: {
                color: '#7F7F7F',
                width: '100vw',
                border: 'none',
                height: 'auto',
                fontSize: '10px',
                maxWidth: '100px',
                background: '#355FAB',
                fontWeight: '600',
                borderRadius: '30px',
                letterSpacing: '0.5px',
                textTransform: 'capitalize',
                color: 'white',
                whiteSpace: 'nowrap',
            },
            height: '5vh',
            // width: '10vw',
            borderRadius: '30px',
            background: '#FAFAFA',
            color: '#7F7F7F',
            border: 'none',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: '500',
            letterSpacing: '0.5px',
            width: '9em',
            maxWidth: '150px',
            whiteSpace: 'nowrap',
        },
        outline: {
            [theme.breakpoints.down(600)]: {
                color: '#355FAB',
                width: '100vw',
                border: '2px solid #355FAB',
                height: 'fit-content',
                fontSize: '10px',
                maxWidth: '100px',
                fontWeight: '600',
                borderRadius: '30px',
                letterSpacing: '0.5px',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
            },
            whiteSpace: 'nowrap',
            color: '#FAFAFA',
            // width: '10vw',
            border: '2px solid #FAFAFA',
            height: '5vh',
            fontSize: '16px',
            fontWeight: '500',
            borderRadius: '30px',
            letterSpacing: '0.5px',
            textTransform: 'capitalize',
            width: '9em',
            maxWidth: '150px',
        },
    }),
);

interface Props {
    variant?: 'contains' | 'outline';
    label: string;
    to: string;
}
const ButtonLanding = (props: Props) => {
    const classes = useStyle();
    return (
        <>
            <Button
                href={props.to}
                className={classes[props.variant ?? 'contains']}
            >
                {props.label}
            </Button>
        </>
    );
};

export default ButtonLanding;
