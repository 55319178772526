import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    Collapse,
    Typography,
    TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SortIcon from '@material-ui/icons/Sort';
import PrintIcon from '@material-ui/icons/Print';
import { DataTableRequestParameters } from 'app/ui';
import classNames from 'classnames';
import Pagination from 'react-responsive-pagination';

const useStyles = makeStyles(theme => ({
    buttonAccion: {
        background: 'rgb(0, 61, 165)',
        color: 'white',
        width: '100%',
        height: '50px',
        margin: '10px 0px',
        '&:focus': {
            background: 'rgb(0, 61, 165)',
        },
    },
    icon: {
        fontSize: 32,
    },
    buttonClose: {
        background: '#D0352F',
        color: 'white',
        width: '30vw',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        marginBottom: '10px',
        position: 'relative',
        display: 'block',
        '&:focus': {
            background: '#D0352F',
        },
    },
    buttonMore: {
        background: '#59BC2F',
        color: 'white',
        width: '30vw',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        marginBottom: '10px',
        position: 'relative',
        display: 'block',
        '&:focus': {
            background: '#59BC2F',
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    headerCard: {
        textAlign: 'center',
        fontWeight: 'bold',
        border: '1px solid #e8e8e8',
    },
    Htitle: {
        color: '#fff',
        padding: '10px',
        backgroundColor: 'rgb(0, 61, 165)',
        fontSize: 'small',
    },
    Hbody: {
        // backgroundColor:,
        color: '#D85954',
        padding: 10,
        color: '#D85954',
        padding: '10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bodyCard: {
        fontWeight: 'bold',
        border: '1px solid #e8e8e8',
        padding: '10px',
    },
    Btitle: {
        color: 'rgb(0, 61, 165)',
    },
    Bbody: {
        color: '#767676',
    },
    label: {
        background: '#b92828',
        color: 'white',
        fontWeight: '600',
        width: '100vw',
        zIndex: '0',
        textAlign: 'center',
        marginBottom: '10px',
        marginTop: '10px',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        width: '16em',
        border: '2px solid #3f3f6a',
        height: '3.4em',
        marginTop: '11px',
        borderRadius: '4px',
        padding: '7px 12px',
    },
    paginationBase: {
        '& .pagination': {
            justifyContent: 'center',
            display: 'flex',
            paddingLeft: '0',
            listStyle: 'none',
        },
        '& .page-item .page-link': {
            position: 'relative',
            display: 'block',
            margin: '0 2px',
            border: '1px solid #cccccc',
            padding: '5px 10px',
            borderRadius: '5px',
            color: '#007bff',
            textDecoration: 'none',
        },
        '& .page-item a.page-link:hover': { backgroundColor: '#cccccc' },
        '& .page-item.active .page-link': {
            color: '#ffffff',
            backgroundColor: '#007bff',
        },
        '& .page-item.disabled .page-link': {
            color: '#6c757d',
            pointerEvents: 'none',
            cursor: 'auto',
        },
        '& .sr-only': {
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            border: '0',
        },
    },
}));

interface HeaderHorizontalProps {
    title: string;
    propsRef: string;
}

interface buttonActions {
    label: string;
    color: string;
    action: (x: any) => void;
    textColor?: string;
}
interface Props {
    data: any;
    handleLoadData: (x: DataTableRequestParameters) => void;
    titleLabel: string;
    headerHorizontalProps: HeaderHorizontalProps[];
    handlePrint?: () => void;
    VerticalSumaryProps: string[];
    buttons: buttonActions[];
    hasPagination: boolean;
}

const MobileCardHistory = (props: Props) => {
    const [search, setSearch] = useState(true);
    const [searchText, setSearchText] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        console.log(props);
    }, []);
    const onFilter = () => {
        setSearch(!search);
        if (search) {
            props.handleLoadData({ searchText: searchText });
        }
    };
    const onChangePage = () => {
        setSearch(!search);
        if (search) {
            props.handleLoadData({
                searchText: searchText,
            });
        }
    };

    const onSort = () => {
        // console.log('changer Order');
    };

    const onPrint = () => {
        props.handlePrint();
    };

    return (
        <div>
            <Grid container item xs={12}>
                <Typography className={classes.label}>
                    {props.titleLabel}
                </Typography>
            </Grid>
            <div style={{ padding: '0px 15px' }}>
                <Grid container item xs={12} spacing={1}>
                    {search && (
                        <Grid item xs={8}>
                            <TextField
                                id="outlined-dense"
                                className={classNames(
                                    classes.textField,
                                    classes.dense,
                                )}
                                placeholder="buscar"
                                InputProps={{ disableUnderline: true }}
                                onChange={x => setSearchText(x.target.value)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Button
                            className={classes.buttonAccion}
                            onClick={onFilter}
                        >
                            <SearchIcon className={classes.icon} />
                        </Button>
                    </Grid>

                    {!search && (
                        <>
                            <Grid item xs={4}>
                                <Button
                                    className={classes.buttonAccion}
                                    onClick={onSort}
                                >
                                    <SortIcon className={classes.icon} />
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    className={classes.buttonAccion}
                                    onClick={onPrint}
                                >
                                    <PrintIcon className={classes.icon} />
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>

                {props.data.data.map(transation => (
                    <>
                        <CardHistory
                            buttons={props.buttons}
                            data={transation}
                            headerHorizontalProps={props.headerHorizontalProps}
                            VerticalSumaryProps={props.VerticalSumaryProps}
                        />
                    </>
                ))}
            </div>
            {props.hasPagination && (
                <PaginationBase
                    totalPages={props.data.total ?? 1}
                    currentPage={props.data.page ?? 1}
                    HandleChangePage={onChangePage()}
                />
            )}
        </div>
    );
};

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    HandleChangePage: () => void;
}
const PaginationBase = (props: PaginationProps) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.paginationBase}>
                <Pagination
                    current={props.currentPage}
                    total={props.totalPages}
                    onPageChange={props.HandleChangePage}
                />
            </div>
        </>
    );
};

interface CardHistoryProps {
    data: any;
    headerHorizontalProps: HeaderHorizontalProps[];
    VerticalSumaryProps: string[];
    buttons: buttonActions[];
}

function CardHistory(props: CardHistoryProps) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    function handleExpandClick() {
        setExpanded(!expanded);
    }

    return (
        <>
            <Grid container>
                {props.headerHorizontalProps.map((x, i) => (
                    <Grid
                        container
                        item
                        xs={4}
                        className={classes.headerCard}
                        key={i}
                    >
                        <Grid item xs={12} className={classes.Htitle}>
                            {x.title}
                        </Grid>
                        <Grid item xs={12} className={classes.Hbody}>
                            {props.data[x.propsRef]}
                        </Grid>
                    </Grid>
                ))}

                {props.buttons && (
                    <Grid container item xs={12} className={classes.bodyCard}>
                        <Grid item xs={6} className={classes.Btitle}>
                            Opciones
                        </Grid>
                        <Grid item xs={6} className={classes.Bbody}>
                            {props.buttons.map((x, i) => (
                                <Button
                                    variant="outlined"
                                    onClick={() => x.action(props.data)}
                                    style={{
                                        background: x.color,
                                        color: x.textColor ?? 'white',
                                        height: '2em',
                                        margin: '0px 2px',
                                        width: '1%',
                                        fontSize: 'small',
                                        fontWeight: '400',
                                    }}
                                    key={i}
                                >
                                    {x.label}
                                </Button>
                            ))}
                        </Grid>
                    </Grid>
                )}

                {props.VerticalSumaryProps.map((x, i) => (
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.bodyCard}
                        key={i}
                    >
                        <Grid item xs={6} className={classes.Btitle}>
                            {x}
                        </Grid>
                        <Grid item xs={6} className={classes.Bbody}>
                            {props.data[x]}
                        </Grid>
                    </Grid>
                ))}

                <DetailSplit
                    classes={classes}
                    data={props.data}
                    headerHorizontalProps={props.headerHorizontalProps}
                    VerticalSumaryProps={props.VerticalSumaryProps}
                />
            </Grid>
        </>
    );
}

const DetailSplit = (props: any) => {
    const [open, setOpen] = useState(false);
    const headerHorizontalProps = props.headerHorizontalProps.map(x => x.title);
    const detail = [];
    Object.keys(props.data).map(
        x =>
            !props.VerticalSumaryProps.includes(x) &&
            !headerHorizontalProps.includes(x) &&
            detail.push({
                title: x,
                value: props.data[x],
            }),
    );
    return (
        <Grid container item xs={12} className="Slit">
            <Collapse in={open} style={{ width: '100%' }}>
                {detail.map(x => (
                    <>
                        <Grid
                            container
                            item
                            xs={12}
                            className={props.classes.bodyCard}
                        >
                            <Grid item xs={6} className={props.classes.Btitle}>
                                {x.title}
                            </Grid>
                            <Grid item xs={6} className={props.classes.Bbody}>
                                {x.value}
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Collapse>
            <Grid container item justify="flex-start" xs={12}>
                <Button
                    className={
                        open
                            ? props.classes.buttonClose
                            : props.classes.buttonMore
                    }
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    {open ? 'Cerrar' : 'Ver Más'}
                </Button>
            </Grid>
        </Grid>
    );
};

export default MobileCardHistory;
