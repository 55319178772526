import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Pagination from 'material-ui-flat-pagination';
import { PaginationProps } from '../types';
import { paymentsService as admin } from 'app/service/admin';

const theme = createMuiTheme();

export default function UserPagination(props: PaginationProps) {
    const { setOffset, total, setUsers } = props;

    const handleClick = async offset => {
        setOffset(offset);
        const paginate = await admin.getUsers('inactive', offset, '8');
        setUsers(paginate.data[0]);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Pagination
                limit={8}
                offset={props.offset}
                total={total}
                size="large"
                onClick={(e, offset) => handleClick(offset)}
            />
        </MuiThemeProvider>
    );
}
