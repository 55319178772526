import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddAmount from './AddAmount';
import OwnerForm from './OwnerForm';
import PropertyForm from './PropertyForm';
import { paymentsService } from '../../service/payments';
import storage from 'app/helpers/storage';
import { actions as publicActions } from 'app/service/public';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { FullPageHeader } from './components';
import { loading } from '@bndynet/dialog';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://remaxrd.com/">
                RE/MAX RD
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
function PaymentsBrand() {
    return (
        <Typography variant="body1" color="textPrimary" align="center">
            Aceptamos las tarjetas <br />
            <div style={{ display: 'inline-flex' }}>
                <img src="../images/brands/visa.png" />
                <img src="../images/brands/mastercard.png" />
                <img src="../images/brands/discover.png" />
                <img src="../images/brands/diners.png" />
                <img src="../images/brands/american.png" />
            </div>
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    layoutSuccess: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1138,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    img: {
        objectFit: 'cover',
        width: '100%',
    },
    imgLogo: {
        objectFit: 'cover',
        width: '15%',
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    back: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const steps = [
    'Agregar monto',
    'Detalles del Propietario',
    'Detalles de la Propiedad',
];

function ConfigPayment(props) {
    let getDate = new Date();
    const classes = useStyles('paper');
    const [stepButton, setStepButton] = React.useState(true);

    const [activeStep, setActiveStep] = React.useState(0);
    const [idTenant, setIdTenant] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [payday, setPayday] = React.useState(getDate);

    const [ownerName, setOwnerName] = React.useState('');
    const [ownerLast, setOwnerLast] = React.useState('');
    const [documentType, setDocumentType] = React.useState('');
    const [document, setDocument] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [tenantEmail, setTenantEmail] = React.useState(
        storage.get('user').email,
    );
    const [confirmEmail, setConfirmEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [optPhone, setOptPhone] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [bank, setBank] = React.useState('');
    const [bankAccount, setBankAccount] = React.useState('');
    const [accountNumber, setAccountNumber] = React.useState('');
    const [ownerAddress, setOwnerAddress] = React.useState('');

    const [propertyType, setPropertyType] = React.useState('');
    const [city, setCity] = React.useState('');
    const [sector, setSector] = React.useState('');
    const [construction, setConstruction] = React.useState('');
    const [bedrooms, setBedrooms] = React.useState('');
    const [bathrooms, setBathrooms] = React.useState('');
    const [parking, setParking] = React.useState('');
    const [contract, setContract] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [revenue, setRevenue] = React.useState(null);
    const [isSaved, setIsSaved] = React.useState(false);

    useEffect(() => {
        if (storage.get('user') === null) {
            props.history.push('/inicio');
            return;
        }
        if (props.sectors.length <= 0) {
            props.getSectors();
        }
        setIdTenant(storage.get('user').id);
    }, []);

    const handleNext = async () => {
        setActiveStep(activeStep + 1);
        if (activeStep === steps.length - 1) {
            loading();
            const result = await paymentsService.savePaymentConfig(
                amount,
                payday,
                ownerName,
                ownerLast,
                documentType,
                document,
                email,
                phone,
                optPhone,
                address,
                bank,
                bankAccount,
                accountNumber,
                ownerAddress,
                propertyType.value,
                city.value,
                sector.value,
                construction,
                bedrooms,
                bathrooms,
                parking,
                revenue,
                idTenant,
                tenantEmail,
                contract,
            );
            if (result.status === 200) {
                loading(false);
                setIsSaved(true);
            }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <AddAmount
                        amount={amount}
                        payday={payday}
                        setAmount={setAmount}
                        setPayday={setPayday}
                        setStepButton={setStepButton}
                    />
                );
            case 1:
                return (
                    <OwnerForm
                        ownerName={ownerName}
                        setOwnerName={setOwnerName}
                        ownerLast={ownerLast}
                        setOwnerLast={setOwnerLast}
                        documentType={documentType}
                        setDocumentType={setDocumentType}
                        document={document}
                        setDocument={setDocument}
                        email={email}
                        setEmail={setEmail}
                        phone={phone}
                        setPhone={setPhone}
                        optPhone={optPhone}
                        setOptPhone={setOptPhone}
                        ownerAddress={ownerAddress}
                        setOwnerAddress={setOwnerAddress}
                        bankAccount={bankAccount}
                        setBankAccount={setBankAccount}
                        bank={bank}
                        setBank={setBank}
                        accountNumber={accountNumber}
                        setAccountNumber={setAccountNumber}
                        setStepButton={setStepButton}
                        confirmEmail={confirmEmail}
                        setConfirmEmail={setConfirmEmail}
                    />
                );
            case 2:
                return (
                    <PropertyForm
                        propertyType={propertyType}
                        setPropertyType={setPropertyType}
                        city={city}
                        setCity={setCity}
                        sector={sector}
                        setSector={setSector}
                        construction={construction}
                        setConstruction={setConstruction}
                        address={address}
                        setAddress={setAddress}
                        bedrooms={bedrooms}
                        setBedrooms={setBedrooms}
                        bathrooms={bathrooms}
                        setBathrooms={setBathrooms}
                        parking={parking}
                        setParking={setParking}
                        revenue={revenue}
                        setRevenue={setRevenue}
                        sectors={props.sectors}
                        setStepButton={setStepButton}
                        setContract={setContract}
                        contract={contract}
                        setFiles={setFiles}
                        files={files}
                    />
                );
            default:
                throw new Error('Unknown step');
        }
    }
    return (
        <React.Fragment>
            <FullPageHeader />
            {/* <PageHeader title="Configuración de Pagos" /> */}
            {activeStep !== steps.length && (
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                        />
                        <Stepper
                            activeStep={activeStep}
                            className={classes.stepper}
                        >
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {getStepContent(activeStep)}
                            <div className={classes.back}>
                                {activeStep !== 0 && (
                                    <Button
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Volver
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={stepButton}
                                    className={classes.button}
                                >
                                    <span style={{ color: 'white' }}>
                                        {activeStep === steps.length - 1
                                            ? 'Guardar'
                                            : 'Siguente'}
                                    </span>
                                </Button>
                            </div>
                            <PaymentsBrand />
                        </React.Fragment>
                    </Paper>
                </main>
            )}
            {isSaved && (
                <React.Fragment>
                    <main className={classes.layoutSuccess}>
                        <Paper className={classes.paper}>
                            <img
                                alt="Remax"
                                className={classes.img}
                                src={require('../../../assets/images/estimado-inquilino.png')}
                            />
                            <Typography variant="h5">
                                <div style={{ textAlign: 'justify' }}>
                                    <br />
                                    Estimado inquilino,
                                    <br />
                                    <br />
                                    En este momento el propietario recibirá un
                                    correo para confirmar sus datos.
                                    <br />
                                    <br />
                                    Tan pronto el propietario valide la
                                    información, se te notificará vía correo
                                    electrónico y podrás realizar el pago. Por
                                    favor comunícate con tu propietario e
                                    infórmale que va a recibir un correo.
                                    <br />
                                    <br />
                                    En caso de no visualizar el{' '}
                                    <strong>"correo de confirmación"</strong> en
                                    la Bandeja de Entrada, por favor revisar la{' '}
                                    <strong>"Carpeta de Spam"</strong> del
                                    correo.
                                </div>
                            </Typography>
                            <center>
                                <br />
                                <img
                                    alt="Remax"
                                    className={classes.imgLogo}
                                    src={require('../../../assets/images/logo.png')}
                                />
                            </center>
                        </Paper>
                    </main>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    sectors: state.publicReducer.sectors,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    getSectors: () => {
        dispatch(publicActions.getSectors());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPayment);
