import {
    MainPage,
    Maintenance,
    OwnerHistory,
    TenantsHistory,
    UserPermissions,
} from 'app/pages/admin';
import {
    AddProperty,
    AssignProperty,
    ViewProperties,
} from 'app/pages/properties';
import EditProperty from 'app/pages/properties/EditProperty';
import { NotFound, Contact } from 'app/pages/public';
import React from 'react';
import { permissions, adminPermissions } from '../helpers/';

const routes = [
    {
        path: '/',
        exact: true,
        component: MainPage,
    },
    {
        path: '/historial',
        exact: true,
        component: TenantsHistory,
    },
    {
        path: '/historial-propietarios',
        exact: true,
        component: OwnerHistory,
        //eslint-disable-next-line react/display-name
        render: props => {
            if (permissions() !== false) {
                return permissions();
            } else {
                return <OwnerHistory {...props} />;
            }
        },
    },
    {
        path: '/informacion-de-pagos',
        exact: true,
        component: Maintenance,
        //eslint-disable-next-line react/display-name
        render: props => {
            adminPermissions(props);
            return <Maintenance {...props} />;
        },
    },
    {
        path: '/permisos',
        exact: true,
        component: UserPermissions,
        //eslint-disable-next-line react/display-name
        render: props => {
            adminPermissions(props);
            return <UserPermissions {...props} />;
        },
    },
    {
        path: '/contacto',
        exact: true,
        component: Contact,
    },
    {
        path: '/registrar-propiedad',
        exact: true,
        component: AddProperty,
        //eslint-disable-next-line react/display-name
        render: props => {
            // return permissions();
            if (permissions() !== false) {
                return permissions();
            } else {
                return <AddProperty {...props} />;
            }
        },
    },
    {
        path: '/editar-propiedad',
        exact: true,
        component: EditProperty,
        //eslint-disable-next-line react/display-name
        render: props => {
            // return permissions();
            if (permissions() !== false) {
                return permissions();
            } else {
                return <EditProperty {...props} />;
            }
        },
    },
    {
        path: '/asignar-propiedad',
        exact: true,
        component: AssignProperty,
        //eslint-disable-next-line react/display-name
        render: props => {
            // return permissions();
            if (permissions() !== false) {
                return permissions();
            } else {
                return <AssignProperty {...props} />;
            }
        },
    },
    {
        path: '/propiedades',
        exact: true,
        component: ViewProperties,
        //eslint-disable-next-line react/display-name
        render: props => {
            // return permissions();
            if (permissions() !== false) {
                return permissions();
            } else {
                return <ViewProperties {...props} />;
            }
        },
    },
    { component: NotFound },
];

export default routes;
