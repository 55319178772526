import * as React from 'react';
import { Redirect } from 'react-router';
import { isAuthorized, checkTimeOut, getAuthUri } from 'app/service/auth';
import { NotFound } from 'app/pages/public';
import { Admin } from 'app/pages/admin';
import {
    Login,
    Logout,
    Callback,
    Register,
    SuccessRegistered,
    ActivateUser,
    ConfirmTransaction,
    ResetPassword,
} from 'app/pages/auth';
import { RouteConfig } from 'react-router-config';
import { Checkout, PaymentLink } from 'app/pages/payments';
import ConfigPayment from 'app/pages/payments/ConfigPayment';
import AcceptPayment from 'app/pages/payments/AcceptPayment';
import { LandingPage } from 'app/pages/public';
import MissingInfo from 'app/pages/auth/MissingInfo';
import OnMaintenance from 'app/pages/public/OnMaintenance';
// import SelectConfigAssign from 'app/pages/payments/SelectConfigAssign';

export const routes: RouteConfig[] = [
    {
        path: '/login',
        // `render()` method support in react-router-config v5.0
        /* eslint-disable */
        component: Login,
        render: () => {
            if (!isAuthorized()) {
                return <Login />;
            } else {
                if (
                    getAuthUri() &&
                    !getAuthUri()
                        .toLowerCase()
                        .startsWith('http')
                ) {
                    return <Redirect to="/" />;
                }
            }
            location.href = getAuthUri();
            return null;
        },
    },
    {
        path: '/logout',
        component: Logout,
    },
    {
        path: '/success',
        component: SuccessRegistered,
        render: () => {
            if (!isAuthorized()) {
                return <SuccessRegistered />;
            } else {
                if (
                    getAuthUri() &&
                    !getAuthUri()
                        .toLowerCase()
                        .startsWith('http')
                ) {
                    return <Redirect to="/" />;
                }
            }
            location.href = getAuthUri();
            return null;
        },
    },
    {
        path: '/confirmation',
        component: ActivateUser,
    },
    {
        path: '/Register',
        component: Register,
        render: () => {
            if (!isAuthorized()) {
                return <Register />;
            } else {
                if (
                    getAuthUri() &&
                    !getAuthUri()
                        .toLowerCase()
                        .startsWith('http')
                ) {
                    return <Redirect to="/" />;
                }
            }
            location.href = getAuthUri();
            return null;
        },
    },
    {
        path: '/confirm-payment',
        component: ConfirmTransaction,
    },
    {
        path: '/cambiar-contraseña',
        component: ResetPassword,
    },
    {
        path: '/completar-datos',
        component: MissingInfo,
    },
    {
        path: '/auth/callback',
        component: Callback,
    },
    {
        path: '/configurar-pagos',
        component: ConfigPayment,
    },
    {
        path: '/enlace-pago',
        component: PaymentLink,
    },
    {
        path: '/accept-payment',
        component: AcceptPayment,
    },
    {
        path: '/pago',
        component: Checkout,
    },
    {
        path: '/inicio',
        // component: LandingPage,
        component: OnMaintenance,
        // `render()` method support in react-router-config v5.0
        /* eslint-disable */
        render: () => {
            if (isAuthorized()) {
                checkTimeOut();
                return <Redirect to="/" />;
            } else {
                if (
                    getAuthUri() &&
                    !getAuthUri()
                        .toLowerCase()
                        .startsWith('http')
                ) {
                    return <LandingPage />;
                    // return <OnMaintenance />;
                }
            }
            location.href = getAuthUri();
            return null;
        },
    },
    {
        path: '/',
        // `render()` method support in react-router-config v5.0
        /* eslint-disable */
        render: () => {
            if (isAuthorized()) {
                checkTimeOut();
                return <Admin />;
            } else {
                if (
                    getAuthUri() &&
                    !getAuthUri()
                        .toLowerCase()
                        .startsWith('http')
                ) {
                    return <Redirect to={getAuthUri()} />;
                }
            }
            location.href = getAuthUri();
            return null;
        },
    },
    {
        component: NotFound,
    },
];
