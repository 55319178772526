import * as React from 'react';
import { loading } from '@bndynet/dialog';
import { service as resourceService } from 'app/service/resource';
import PaymentIcon from '@material-ui/icons/Payment';
import {
    DataTable,
    PageHeader,
    DataTableRequestParameters,
    DataTablePageMeta,
} from 'app/ui';
import utils from 'app/helpers/utils';
import storage from 'app/helpers/storage';
import { ApiConstants } from 'app/config';
import EditIcon from '@material-ui/icons/Edit';
import {
    Button,
    createStyles,
    Grid,
    Paper,
    Typography,
    withStyles,
} from '@material-ui/core';
import MobileCardHistory from '../admin/maintenance/cardHistory';
export interface DataTableProps {
    rows: [];
}
const styles = () =>
    createStyles({
        helpPanel: {
            backgroundColor: '#007dc3',
            padding: 25,
            color: 'white',
        },
        advice: {
            fontSize: '1.2rem',
        },
        icons: {
            fontSize: 40,
            marginRight: 10,
        },
        table: {
            '& tr td': {
                height: 'auto',
            },
        },
    });

class ViewProperties extends React.Component<DataTableProps> {
    public constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            data: null,
        };
        this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.PopoverOptions = this.PopoverOptions.bind(this);
    }
    public handlePopoverOpen = event => {
        this.setState({
            data: this.state.data,
            anchorEl: event.currentTarget,
        });
    };

    public handlePopoverClose = () => {
        this.setState({
            data: this.state.data,
            anchorEl: null,
        });
    };

    public PopoverOptions = (dataIndex, rowIndex) => {
        const id = rowIndex?.rowData[1];
        return (
            <>
                <Button
                    variant="outlined"
                    style={{ width: '2%', marginTop: 1 }}
                    onClick={() =>
                        this.props.history.push(`/asignar-propiedad?id=${id}`)
                    }
                >
                    <span style={{ fontSize: '0.80em' }}>Pagos</span>
                </Button>{' '}
                <Button
                    variant="outlined"
                    style={{ width: '1%', background: '#003DA5' }}
                    onClick={() =>
                        this.props.history.push(`/editar-propiedad?id=${id}`)
                    }
                >
                    <span style={{ fontSize: '0.80em', color: 'white' }}>
                        Editar
                    </span>
                </Button>
            </>
        );
    };
    public render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.helpPanel}>
                    <Grid container>
                        <PaymentIcon className={classes.icons} />
                        <Grid
                            container
                            justify="flex-start"
                            item
                            xl={6}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <Typography className={classes.advice}>
                                {/* eslint-disable-next-line react/no-unescaped-entities*/}{' '}
                                Haz clic en el botón “pagos” de la propiedad
                                correspondiente para generar el enlace mediante
                                el cual tu inquilino podrá realizar el pago del
                                alquiler.
                            </Typography>
                            <br />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <EditIcon className={classes.icons} />
                        <Grid
                            container
                            justify="flex-start"
                            item
                            xl={6}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <Typography className={classes.advice}>
                                {/* eslint-disable-next-line react/no-unescaped-entities*/}
                                {''}Para editar una propiedad debes hacer clic
                                en el botón "editar" en la propiedad deseada.
                            </Typography>
                            <br />
                        </Grid>
                    </Grid>
                </Paper>

                {window.innerWidth > 650 && (
                    <div className={classes.table}>
                        <PageHeader title="" />
                        <DataTable
                            title="Propiedades Registradas"
                            dataPromise={this.tableOwnerProperties}
                            scrollable={true}
                            pagination={false}
                            haveFilters={false}
                            editRecord={{
                                name: 'Opciones',
                                options: {
                                    filter: false,
                                    sort: false,
                                    empty: true,
                                    customBodyRender: (dataIndex, rowIndex) =>
                                        this.PopoverOptions(
                                            dataIndex,
                                            rowIndex,
                                        ),
                                },
                            }}
                        />
                        <br />
                    </div>
                )}
                {window.innerWidth < 650 && this.state.data && (
                    <MobileCardHistory
                        data={this.state.data}
                        handleLoadData={e => this.loadData(e)}
                        titleLabel="Propiedades Registradas"
                        buttons={[
                            {
                                label: 'Pagos',
                                color: '#003DA5',
                                action: (x: any) => {
                                    this.props.history.push(
                                        `/asignar-propiedad?id=${x['Código de Propiedad']}`,
                                    );
                                },
                            },
                            {
                                label: 'Editar',
                                color: '',
                                textColor: 'black',
                                action: (x: any) => {
                                    this.props.history.push(
                                        `/editar-propiedad?id=${x['Código de Propiedad']}`,
                                    );
                                },
                            },
                        ]}
                        headerHorizontalProps={[
                            {
                                title: 'Código',
                                propsRef: 'Código de Propiedad',
                            },
                            {
                                title: 'Precio',
                                propsRef: 'Precio',
                            },
                            {
                                title: 'Tipo',
                                propsRef: 'Tipo de propiedad',
                            },
                        ]}
                        VerticalSumaryProps={['Dirección', 'Día de pago']}
                    />
                )}
            </div>
        );
    }

    componentDidMount = () => {
        if (window.innerWidth < 650) {
            this.loadData({ searchText: '' });
        }
    };

    public async loadData(args?: DataTableRequestParameters) {
        const respose = await this.tableOwnerProperties(args);
        this.setState({
            anchorEl: this.state.anchorEl,
            data: respose,
        });
    }

    public tableOwnerProperties(
        args: DataTableRequestParameters,
    ): Promise<any> {
        let endpoint = ApiConstants.GET_OWNER_PROPERTIES_ENDPOINT;
        if (args) {
            endpoint += `?page=${args.page || 1}`;
            endpoint += `&pageSize=${args.pageSize || 10}`;

            if (args.sort) {
                endpoint += `&sort=${args.sort}&sortDirection=${args.sortDirection}`;
            }
            if (args.searchText) {
                endpoint += `&search=${args.searchText}`;
            }
        } else {
            endpoint = '';
        }

        const ajax = resourceService
            .post(endpoint, {
                id: storage.get('user').id,
                identification: storage.get('user').id_identity,
            })
            .then((res: any) => {
                const result: DataTablePageMeta = {
                    data: res.data,
                    page: (args && args.page) || 1,
                    count: res.count,
                };
                return result;
            });

        const promiseLoading = new Promise<void>(resolve => {
            loading();
            setTimeout(() => {
                loading(false);
                resolve();
            }, 3000);
        });

        return utils
            .delay(3, ajax, promiseLoading)
            .then((values: any[]) => values[0]);
    }
}

export default withStyles(styles)(ViewProperties);
