import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { loadCSS } from 'fg-loadcss';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';

const LogoAzul = 'images/logo-azul.png';

const useStyles = makeStyles(theme =>
    createStyles({
        footer: {
            backgroundColor: '#232323',
            color: '#fff',
            paddingTop: '4em',
            paddingBottom: '4em',
        },
        gridContainer: {
            [theme.breakpoints.up(960)]: {
                padding: 0,
            },
        },
        gridItem: {
            margin: '0',
        },
        imgContainer: {
            maxWidth: '100px',
            '& > *': {
                width: '100%',
            },
            [theme.breakpoints.down(600)]: {
                width: 69,
                height: 69,
            },
        },
        header: {
            marginBottom: 12,
            [theme.breakpoints.down(600)]: {
                fontSize: 11,
            },
        },
        social: {
            color: '#fff',
            '& > *': {
                fontSize: 40,
                [theme.breakpoints.down(600)]: {
                    fontSize: 20,
                },
            },
            marginTop: theme.spacing(1),
        },
        link: {
            color: '#6592e6',
            '&:hover': {
                color: '#007bff',
            },
            textDecoration: 'none',
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down(600)]: {
                fontSize: 10,
            },
        },
        orderOne: {
            [theme.breakpoints.down(960)]: {
                order: 1,
            },
            marginBottom: 50,
            [theme.breakpoints.down(600)]: {
                marginBottom: 25,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        orderTwo: {
            [theme.breakpoints.down(960)]: {
                order: 2,
                paddingRight: '0.5em',
            },
            marginBottom: 50,
            [theme.breakpoints.down(600)]: {
                marginBottom: 25,
            },
        },
        orderThree: {
            [theme.breakpoints.down(960)]: {
                order: 3,
            },
            marginBottom: 50,
            [theme.breakpoints.down(600)]: {
                marginBottom: 25,
            },
        },
        orderFour: {
            [theme.breakpoints.down(960)]: {
                order: 4,
            },
            [theme.breakpoints.down(600)]: {
                marginBottom: 25,
            },
        },
        orderFive: {
            [theme.breakpoints.down(960)]: {
                order: 5,
            },
        },
        orderSix: {
            [theme.breakpoints.down(960)]: {
                order: 6,
            },
            '& > h6': {
                [theme.breakpoints.down(600)]: {
                    fontSize: 11,
                },
            },
        },
        contact: {
            [theme.breakpoints.down(600)]: {
                fontSize: 11,
            },
        },
    }),
);

export default function Footer() {
    const classes = useStyles({});
    const theme = useTheme();

    React.useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.15.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );

        return () => {
            node.parentNode.removeChild(node);
        };
    }, []);

    return (
        <Container
            component={'footer'}
            className={classes.footer}
            maxWidth={false}
        >
            <Container className={classes.gridContainer}>
                <Grid container>
                    <Grid
                        item
                        className={clsx(classes.gridItem, classes.orderOne)}
                        md={2}
                        sm={4}
                        xs={4}
                    >
                        <Grid container direction="column">
                            <Grid item className={classes.imgContainer}>
                                <img src={LogoAzul} alt="logo compañía Azul" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.gridItem, classes.orderFour)}
                        md={2}
                        sm={4}
                        xs={4}
                    >
                        <Grid container direction="column">
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.header}
                                >
                                    RE/MAX
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                component="a"
                                href="https://blog.remaxrd.com/?lang=es"
                                target="__blank"
                            >
                                Noticias
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                component="a"
                                href="https://remaxrd.com/"
                                target="__blank"
                            >
                                Leadership
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                component="a"
                                href="https://remaxrd.com/"
                                target="__blank"
                            >
                                Careers
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.gridItem, classes.orderTwo)}
                        md={3}
                        sm={4}
                        xs={4}
                    >
                        <Grid container direction="column">
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.header}
                                >
                                    RE/MAX BUSINESS OPPORTUNITIES
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                component="a"
                                href="https://remaxrd.com/franquicia-remax/?lang=es"
                                target="__blank"
                            >
                                Invierte en tu RE/MAX Franquicia
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                component="a"
                                href="https://remaxrd.com/informacion-de-agentes/?lang=es"
                                target="__blank"
                            >
                                Unete al equipo RE/MAX
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.gridItem, classes.orderThree)}
                        md={3}
                        sm={4}
                        xs={4}
                    >
                        <Grid container direction="column">
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.header}
                                >
                                    SERVICIOS
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                style={{ marginBottom: theme.spacing(2) }}
                                component="a"
                                href="https://remaxrd.com/prestamos-hipotecarios/?lang=es"
                                target="__blank"
                            >
                                Prestamos hipotecarios
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.contact}
                                >
                                    CONTACTO
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                component="a"
                                href="mailto:info@remaxrd.com"
                                className={classes.link}
                            >
                                info@remaxrd.com
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.gridItem, classes.orderFive)}
                        md={2}
                        sm={4}
                        xs={4}
                    >
                        <Grid container direction="column">
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.header}
                                >
                                    PROPIEDADES
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.link}
                                style={{ marginBottom: '40px' }}
                                component="a"
                                href="https://remaxrd.com/propiedades/?lang=es"
                                target="__blank"
                            >
                                Busqueda de propiedades
                            </Grid>
                            <Hidden smDown>
                                <Grid item>
                                    <Typography variant="h6">
                                        Redes sociales
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        className={classes.social}
                                    >
                                        <Grid item>
                                            <Link
                                                href="https://www.facebook.com/remaxRD"
                                                target="__blank"
                                                color="inherit"
                                            >
                                                <Icon
                                                    className="fab fa-facebook"
                                                    fontSize="inherit"
                                                ></Icon>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                href="https://twitter.com/remaxrd"
                                                target="__blank"
                                                color="inherit"
                                            >
                                                <Icon
                                                    className="fab fa-twitter"
                                                    fontSize="inherit"
                                                ></Icon>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                href="https://www.instagram.com/remax_rd"
                                                target="__blank"
                                                color="inherit"
                                            >
                                                <Icon
                                                    className="fab fa-instagram"
                                                    fontSize="inherit"
                                                ></Icon>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Hidden mdUp>
                        <Grid
                            item
                            container
                            direction="column"
                            className={clsx(classes.gridItem, classes.orderSix)}
                            sm={4}
                            xs={4}
                        >
                            <Typography variant="h6">Redes sociales</Typography>
                            <Grid
                                container
                                direction="row"
                                spacing={1}
                                className={classes.social}
                            >
                                <Grid item>
                                    <Link
                                        href="https://www.facebook.com/remaxRD"
                                        target="__blank"
                                        color="inherit"
                                    >
                                        <Icon
                                            className="fab fa-facebook"
                                            fontSize="inherit"
                                        ></Icon>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        href="https://twitter.com/remaxrd"
                                        target="__blank"
                                        color="inherit"
                                    >
                                        <Icon
                                            className="fab fa-twitter"
                                            fontSize="inherit"
                                        ></Icon>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        href="https://www.instagram.com/remax_rd"
                                        target="__blank"
                                        color="inherit"
                                    >
                                        <Icon
                                            className="fab fa-instagram"
                                            fontSize="inherit"
                                        ></Icon>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </Container>
    );
}
