import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import InputMask from 'react-input-mask';
import { SearchPropertiesProps } from '../types/ISearchProperties';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '11%',
            minWidth: 560,
            border: '1px solid rgba(0, 0, 0, 0.27)',
            borderRadius: '4px',
            [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
                minWidth: 420,
            },
            [theme.breakpoints.down(475 + theme.spacing(2) * 2)]: {
                minWidth: 395,
            },
            [theme.breakpoints.down(411 + theme.spacing(2) * 2)]: {
                minWidth: 300,
            },
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            height: 40,
            fontSize: '1.5em',
            [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
                fontSize: '1.2em',
            },
            [theme.breakpoints.down(475 + theme.spacing(2) * 2)]: {
                fontSize: '1.1em',
            },
            [theme.breakpoints.down(411 + theme.spacing(2) * 2)]: {
                fontSize: '1em',
            },
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        buttonLetter: {
            color: 'white',
        },
        searchIcon: {
            color: 'white',
        },
        documentType: {
            marginBottom: '5px',
            height: 45,
        },
    }),
);

export default function SearchProperties(props: SearchPropertiesProps) {
    // const [document, setDocument] = React.useState('');
    const classes = useStyles('paper');
    return (
        <>
            {props.type !== 'document' ? (
                <div className={classes.root}>
                    <InputBase
                        className={classes.input}
                        placeholder="Ingresa el código de propiedad."
                        value={props.propertyCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            props.handlePropertyCode(e.target.value)
                        }
                    />
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={() => props.searchProperty('property_id')}
                    >
                        <SearchIcon
                            fontSize="large"
                            className={classes.searchIcon}
                        />
                        <span className={classes.buttonLetter}>Buscar</span>
                    </Button>
                </div>
            ) : (
                <>
                    <Grid item xs={12} xl={12} md={12} sm={12}>
                        <InputLabel id="demo-simple-select-placeholder-label-label">
                            Tipo de documento
                        </InputLabel>
                        <FormControl variant="outlined" hiddenLabel>
                            <Select
                                className={classes.documentType}
                                value={props.documentType}
                                onChange={e =>
                                    props.handleDocumentOption(e.target.value)
                                }
                            >
                                <MenuItem value={'Cédula'}>Cédula</MenuItem>
                                <MenuItem value={'Pasaporte'}>
                                    Pasaporte
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {props.documentType === 'Cédula' ? (
                        <div className={classes.root}>
                            <InputMask
                                mask="999-9999999-9"
                                value={props.document}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => props.setDocument(e.target.value)}
                            >
                                {() => (
                                    <InputBase
                                        className={classes.input}
                                        placeholder="Ingresa el documento"
                                    />
                                )}
                            </InputMask>
                            <Button
                                className={classes.button}
                                disabled={props.searchDisabled}
                                variant="contained"
                                color="primary"
                                onClick={() => props.searchProperty('local_id')}
                            >
                                <SearchIcon
                                    fontSize="large"
                                    className={classes.searchIcon}
                                />
                                <span className={classes.buttonLetter}>
                                    Buscar
                                </span>
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.root}>
                            <InputBase
                                className={classes.input}
                                placeholder="Ingresa el pasaporte del propietario"
                                value={props.document}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => handleDocument(e.target.value)}
                            />
                            <Button
                                className={classes.button}
                                disabled={props.searchDisabled}
                                variant="contained"
                                color="primary"
                                onClick={() => props.searchProperty('passport')}
                            >
                                <SearchIcon
                                    fontSize="large"
                                    className={classes.searchIcon}
                                />
                                <span className={classes.buttonLetter}>
                                    Buscar
                                </span>
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
