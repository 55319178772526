import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import validator from 'validator';
import InputMask from 'react-input-mask';
import storage from 'app/helpers/storage';

interface OwnerFormProps {
    ownerLast: string;
    bank: string;
    bankAccount: string;
    setStepButton(arg0: boolean);
    setOwnerName(value: string);
    setOwnerLast(value: string);
    setEmail(value: string);
    setConfirmEmail(value: string);
    email: string;
    setAccountNumber(value: string);
    setPhone(value: string);
    setOptPhone(value: string);
    confirmEmail: unknown;
    optPhone: any;
    setOwnerAddress(value: string): void;
    setBank(value: unknown): void;
    setBankAccount(value: unknown): void;
    document: string;
    setDocument: Function;
    setDocumentType: Function;
    ownerName: string;
    accountName: string;
    ownerAddress: string;
    phone: string;
    accountNumber: string;
    documentType: string;
}

export default function OwnerForm(props: OwnerFormProps) {
    const [emailValidation, setEmailValidation] = React.useState('');
    const [emailConfirmValidation, setEmailConfirmValidation] = React.useState(
        '',
    );
    const [isPassport, selectPassport] = React.useState(false);

    function setDocumentType(value) {
        props.setDocument('');
        if (value === 'Pasaporte') {
            props.setDocumentType(value);
            selectPassport(true);
        } else {
            props.setDocumentType(value);
            selectPassport(false);
        }
    }

    function fieldsFilled() {
        if (
            props.ownerName !== '' &&
            props.ownerLast !== '' &&
            props.accountName !== '' &&
            props.phone.replace(/\D/g, '').length === 10 &&
            props.ownerAddress !== '' &&
            props.document !== '' &&
            emailValidation.length === 0 &&
            emailConfirmValidation.length === 0 &&
            ((props.document.replace(/\D/g, '').length === 11 &&
                props.documentType === 'Cédula') ||
                (props.document.length > 5 &&
                    props.documentType === 'Pasaporte')) &&
            props.bank !== '' &&
            props.bankAccount !== '' &&
            props.accountNumber !== ''
        ) {
            props.setStepButton(false);
        } else {
            props.setStepButton(true);
        }
    }
    useEffect(() => {
        fieldsFilled();
    }, [fieldsFilled()]);
    function handleValidations(
        e: React.ChangeEvent<HTMLInputElement>,
        name: string,
    ) {
        let value = e.target.value;
        switch (name) {
            case 'ownerName':
                if (value.match(/^[a-zA-Z\s]*$/)) {
                    props.setOwnerName(value);
                }
                break;
            case 'ownerLast':
                if (value.match(/^[a-zA-Z\s]*$/)) {
                    props.setOwnerLast(value);
                }
                break;
            case 'document':
                if (value && props.documentType === 'Cédula') {
                    props.setDocument(value);
                } else if (props.documentType !== 'Cédula') {
                    props.setDocument(value);
                }
                break;
            case 'email':
                props.setEmail(e.target.value);
                if (validator.isEmail(value) === false) {
                    props.setEmail(e.target.value);
                    setEmailValidation('Debes agregar un correo válido');
                } else if (value === storage.get('user').email) {
                    setEmailValidation(
                        'No puedes colocar tu correo registrado.',
                    );
                } else {
                    props.setEmail(e.target.value);
                    setEmailValidation('');
                }
                break;
            case 'confirmEmail':
                props.setConfirmEmail(e.target.value);
                if (value !== props.email) {
                    props.setConfirmEmail(e.target.value);
                    setEmailConfirmValidation('Los correos no coinciden');
                } else {
                    props.setConfirmEmail(e.target.value);
                    setEmailConfirmValidation('');
                }
                break;
            case 'accountNumber':
                if (value.match(/^[0-9]*$/) && value.length < 14) {
                    props.setAccountNumber(value);
                }
                break;
            case 'phone':
                props.setPhone(value);
                break;
            case 'optPhone':
                props.setOptPhone(value);
                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Información del Propietario
            </Typography>
            <Typography variant="body2" style={{ color: 'red' }} gutterBottom>
                Campos obligatorios *
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cardName"
                        label="Nombre del Propietario"
                        value={props.ownerName}
                        fullWidth
                        autoComplete="cc-name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'ownerName')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        value={props.ownerLast}
                        id="cardlastName"
                        label="Apellido del Propietario"
                        fullWidth
                        autoComplete="cc-last"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'ownerLast')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel id="demo-simple-select-label">
                        Tipo de documento
                    </InputLabel>
                    <Select
                        id="demo-simple-select"
                        label="Tipo de documento"
                        value={props.documentType}
                        onChange={e => setDocumentType(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={'Cédula'}>Cédula</MenuItem>
                        <MenuItem value={'Pasaporte'}>Pasaporte</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                    {isPassport ? (
                        <TextField
                            required
                            id="cardlastName"
                            label="Documento de Identidad"
                            fullWidth
                            autoComplete="cc-id"
                            value={props.document}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleValidations(e, 'document')}
                        />
                    ) : (
                        <InputMask
                            mask="999-9999999-9"
                            value={props.document}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleValidations(e, 'document')}
                        >
                            {() => (
                                <TextField
                                    required
                                    id="cardlastName"
                                    label="Documento de Identidad"
                                    fullWidth
                                    autoComplete="cc-id"
                                />
                            )}
                        </InputMask>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={emailValidation.length === 0 ? false : true}
                        id="cardMail"
                        label="Correo"
                        type="email"
                        fullWidth
                        autoComplete="cc-mail"
                        helperText={emailValidation}
                        value={props.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'email')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={
                            emailConfirmValidation.length === 0 ? false : true
                        }
                        id="cardMail"
                        type="email"
                        label="Confirmación de correo"
                        fullWidth
                        autoComplete="cc-mailConfirm"
                        value={props.confirmEmail}
                        helperText={emailConfirmValidation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'confirmEmail')
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputMask
                        mask="(999)-999-9999"
                        value={props.phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'phone')
                        }
                    >
                        {() => (
                            <TextField
                                required
                                id="cardOwnerTel"
                                label="Teléfono"
                                fullWidth
                                autoComplete="cc-last"
                            />
                        )}
                    </InputMask>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputMask
                        mask="(999)-999-9999"
                        value={props.optPhone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'optPhone')
                        }
                    >
                        {() => (
                            <TextField
                                id="cardOwnerTel"
                                label="Teléfono (opcional)"
                                fullWidth
                                autoComplete="cc-last"
                            />
                        )}
                    </InputMask>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        id="cardAddress"
                        label="Dirección"
                        fullWidth
                        autoComplete="cc-address"
                        value={props.ownerAddress}
                        onChange={e => props.setOwnerAddress(e.target.value)}
                    />
                </Grid>
            </Grid>
            <br />
            <Typography variant="h6" gutterBottom>
                Cuenta del Propietario
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <InputLabel id="demo-simple-select-label">
                        Banco *
                    </InputLabel>
                    <Select
                        id="demo-simple-select"
                        value={props.bank}
                        onChange={e => props.setBank(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={'Asociación Cibao'}>
                            Asociación Cibao
                        </MenuItem>
                        <MenuItem value={'Asociación La Nacional'}>
                            Asociación La Nacional
                        </MenuItem>
                        <MenuItem value={'Asociación La Vega Real (Alaver)'}>
                            Asociación La Vega Real (Alaver)
                        </MenuItem>
                        <MenuItem value={'Asociación Popular'}>
                            Asociación Popular
                        </MenuItem>
                        <MenuItem value={'Banco Activo'}>Banco Activo</MenuItem>
                        <MenuItem value={'Banco Ademi'}>Banco Ademi</MenuItem>
                        <MenuItem value={'Banco BDI'}>Banco BDI</MenuItem>
                        <MenuItem value={'Banco BHD León'}>
                            Banco BHD León
                        </MenuItem>
                        <MenuItem value={'Banco Caribe'}>Banco Caribe</MenuItem>
                        <MenuItem value={'Banco Dominicano del Progreso'}>
                            Banco Dominicano del Progreso
                        </MenuItem>
                        <MenuItem value={'Banco Popular'}>
                            Banco Popular
                        </MenuItem>
                        <MenuItem value={'Banco Santa Cruz'}>
                            Banco Santa Cruz
                        </MenuItem>
                        <MenuItem value={'Banesco'}>Banesco</MenuItem>
                        <MenuItem value={'Banreservas'}>Banreservas</MenuItem>
                        <MenuItem value={'Scotiabank'}>Scotiabank</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel id="demo-simple-select-label">
                        Tipo de cuenta *
                    </InputLabel>
                    <Select
                        id="demo-simple-select"
                        value={props.bankAccount}
                        onChange={e => props.setBankAccount(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={'Corriente'}>Corriente</MenuItem>
                        <MenuItem value={'Ahorros'}>De ahorros</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="cardCity"
                        label="Número de Cuenta"
                        fullWidth
                        autoComplete="cc-city"
                        value={props.accountNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValidations(e, 'accountNumber')
                        }
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
