import { createStyles, makeStyles } from '@material-ui/core';

export const color = {
    darkGray: '#404041',
    lightGray: '#626366',
    blue: '#007DC3',
    lighterGray: 'rgba(196, 198, 200, 0.6)',
};

export const useStyle = makeStyles(theme =>
    createStyles({
        mainContainer: {
            width: '100%',
            backgroundColor: '#FFF',
        },
        Labels: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginBottom: '10px',
            '& p': {
                [theme.breakpoints.down(600)]: {
                    fontSize: '16px',
                },
                color: '#0781C5',
                fontSize: 'xx-large',
                margin: '23px 0px',
                fontWeight: '500',
            },
        },
        carrusel: {
            '& .content_slide': {
                margin: '10p',
                width: '100%',
                height: '9em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                    width: '60px',
                },
                '& .legend': {
                    color: '#0781C5',
                    width: '90%',
                    margin: 'auto',
                    padding: '10px',
                    position: 'static',
                    fontSize: '0.8em',
                    background: 'none',
                    textAlign: 'center',
                    transition: 'opacity .35s ease-in-out',
                    fontWeight: '500',
                    borderRadius: '10px',
                    opacity: '1',
                },
            },
            '& .carousel .control-dots .dot': {
                transition: 'opacity .25s ease-in',

                boxShadow: '0px 1px 7px #7e7b7b',
                background: 'red',
            },
        },
        iconText: {
            [theme.breakpoints.down(600)]: {
                display: 'none',
            },
            textAlign: 'center',
            color: '#0781c5',
            fontWeight: '500',
        },
        button_red: {
            margin: '10px 0px',
            background: '#DC1C2E',
            color: 'white',
            borderRadius: '20px',
            width: '150px',
            textTransform: 'capitalize',
        },
        cardSteps: {
            margin: '10px 0px',
            background: '#F5F7FA',
            borderRadius: '0px',
            padding: '19px 0px',
        },
        cart_title: {
            color: '#616263',
            fontSize: 'x-large',
            margin: '31px 0px',
            fontWeight: '500',
            [theme.breakpoints.down(600)]: {
                width: 'auto',
                margin: '0px 0px',
            },
        },
        step_content_item: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        step_img: {
            width: '60px',
            [theme.breakpoints.down(600)]: {
                width: '54px',
            },
        },
        step_text: {
            [theme.breakpoints.down(700)]: {
                textAlign: 'left',
                width: 'auto',
            },
            [theme.breakpoints.down(800)]: {
                float: 'left',
                display: 'flex',
                textAlign: 'left',
                alignItems: 'center',
            },
            height: '10vh',
            fontSize: '1.2em',
            width: 'calc(15em + 1vw)',
            margin: '10px 0px',
            color: '#616263',
            fontWeight: '400',
            textAlign: 'center',
        },
        line: {
            fontSize: '1vw',
            fontWeight: '600',
            marginBottom: '12vh',
            letterSpacing: '2px',
            textAlign: 'center',
        },
        cards_Descriptions: {
            [theme.breakpoints.down(1000)]: {
                width: '100%',
                height: 'auto',
                margin: '5px',
                padding: '15px',
                height: '3em',
            },
            display: 'flex',
            background: '#E8E8E8',
            textAlign: 'center',
            borderRadius: '10px',
            justifyContent: 'center',
            height: '15vh',
            alignContent: 'center',
            alignItems: 'center',
            padding: '0px 15px',
            flexDirection: 'column',

            '& .text': {
                color: '#616263',
                fontWeight: '500',
                fontSize: '1.2em',
            },
            '& img': {
                width: '10em',
            },
        },
        message: {
            [theme.breakpoints.down(600)]: {
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '1em',
                lineHeight: '16px',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
            },

            fontWeight: '400',
            fontSize: '25px',
            color: '#3F4445',
        },
        hasQuestions: {
            [theme.breakpoints.down(600)]: {
                padding: '25px 0px',
            },
            marginTop: '10px',
            padding: '50px 0px',
            background: '#F5F7FA',
            textAlign: 'center',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',

            '& .title': {
                fontSize: '25px',
                fontWeight: '500',
                color: '#4D4D4D',
                margin: '7px 0px',
            },
            '& .body': {
                fontSize: '18px',
                fontWeight: '300',
                color: '#4D4D4D',
                margin: '9px 0px',
                '& .ref': {
                    color: '#6592e6',
                },
            },
        },
    }),
);
