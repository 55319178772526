import * as React from 'react';
import { loading } from '@bndynet/dialog';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { paymentsService } from 'app/service/admin';
import esLocale from 'date-fns/locale/es';
import {
    DataTable,
    DataTablePageMeta,
    DataTableRequestParameters,
    PageHeader,
} from 'app/ui';
import utils from 'app/helpers/utils';
import { service as resourceService } from 'app/service/resource';
import {
    CircularProgress,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AppTheme } from 'app/theme';
import DateFnsUtils from '@date-io/date-fns';
import Icon from '@material-ui/core/Icon';
import { CalendarToday } from '@material-ui/icons';
import { ApiConstants } from 'app/config';
import storage from 'app/helpers/storage';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import MobileCardHistory from './cardHistory';
const styles = (theme: AppTheme) =>
    createStyles({
        '@global': {
            body: {
                color: theme.palette.text.primary,
                maxHeight: '20px',
            },
            '.markdown-body pre': {
                backgroundColor: theme.palette.background.paper,
            },
        },
        overrides: {
            MuiTableCell: {
                root: {
                    padding: '18px 0px 20px 16px',
                },
            },
        },
    });

interface MaintenanceProps {
    tenants: [];
    tenantsPayments: [];
    open: boolean;
    openStatus: boolean;
    id: string;
    changingStatus: boolean;
    paymentRow: {};
    data: [];
    statusChangedDate: Date;
    downloadCSVButton: () => JSX.Element;
    downloadCSV: () => void;
}
const localeMap = {
    es: esLocale,
};
const getDate = new Date();
class Maintenance extends React.Component<MaintenanceProps> {
    public constructor(props) {
        super(props);
        this.state = {
            tenants: [],
            tenantPayments: this.tableDataPayments,
            open: false,
            openStatus: false,
            id: '',
            data: null,
            paymentRow: {},
            changingStatus: false,
            statusChangedDate: getDate,
        };
        this.handleStatusPayments = this.handleStatusPayments.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.DatatableRef = React.createRef();
    }

    public handleCloseStatus = () => {
        this.setState({ openStatus: false });
    };
    public downloadCSV = () => {
        return paymentsService.downloadCSVFromAPI(storage.get('user').id);
    };
    public changeStatus = async () => {
        this.setState({
            changingStatus: true,
        });
        const paymentStatus = await paymentsService.changePaymentStatus(
            this.state.paymentRow.ID,
            this.state.statusChangedDate,
        );
        loading();
        if (paymentStatus.status === 200) {
            this.setState({
                changingStatus: false,
                openStatus: false,
            });
            await this.DatatableRef.current.getData();
            setTimeout(() => {
                loading(false);
            }, 3500);
        }
    };
    public async handleStatusPayments(rowData: any) {
        this.setState({
            paymentRow: rowData,
            openStatus: true,
        });
    }

    public statusPaymentsModal() {
        return (
            <div>
                <Dialog
                    open={this.state.openStatus}
                    onClose={this.handleCloseStatus}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {this.state.changingStatus !== true && (
                        <DialogTitle id="alert-dialog-title">
                            {'Cambiar estatus'}
                        </DialogTitle>
                    )}
                    <DialogContent>
                        {this.state.changingStatus !== true ? (
                            <>
                                <DialogContentText id="alert-dialog-description">
                                    {`¿Deseas cambiar el estatus de la transacción  
                                ${this.state.paymentRow['Transacción']} a "DEPOSITADO"?`}
                                </DialogContentText>
                                <Grid item xs={window.innerWidth < 520 ? 6 : 3}>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                        locale={localeMap['es']}
                                    >
                                        <DatePicker
                                            disableFuture
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            value={this.state.statusChangedDate}
                                            label="Agregar fecha"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            onChange={date =>
                                                this.setState({
                                                    statusChangedDate: date,
                                                })
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position={'end'}
                                                    >
                                                        <Icon>
                                                            <CalendarToday />
                                                        </Icon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </>
                        ) : (
                            <center>
                                <Typography
                                    gutterBottom={true}
                                    component="h1"
                                    variant="h6"
                                >
                                    Cambiando Estatus..
                                    <br />
                                    <CircularProgress />
                                </Typography>
                            </center>
                        )}
                    </DialogContent>
                    {this.state.changingStatus !== true && (
                        <DialogActions>
                            <Button
                                onClick={this.handleCloseStatus}
                                color="primary"
                                autoFocus
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => this.changeStatus()}
                                color="primary"
                            >
                                Cambiar
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
        );
    }

    public downloadCSVButton() {
        return (
            <Button onClick={this.downloadCSV} download color="black">
                <CloudDownloadIcon />
            </Button>
        );
    }
    public render() {
        return (
            <>
                {window.innerWidth > 650 && (
                    <div style={{ padding: '0px 10px' }}>
                        <PageHeader title="" />
                        <DataTable
                            title="Información de Pagos"
                            dataPromise={this.tableDataPayments}
                            dataPromiseFiltered={this.tableDataPaymentsFiltered}
                            scrollable={true}
                            downloadCSVButton={this.downloadCSVButton()}
                            ref={this.DatatableRef}
                            downloadCSV={this.downloadCSV}
                            selectable={false}
                            haveFilters={true}
                            onRowClick={this.handleStatusPayments}
                        />
                        <br />
                        {this.statusPaymentsModal()}
                    </div>
                )}
                {window.innerWidth < 650 && this.state.data && (
                    <MobileCardHistory
                        data={this.state.data}
                        handleLoadData={e => this.loadData(e)}
                        titleLabel="Informacion de Pago"
                        handlePrint={this.downloadCSV}
                        headerHorizontalProps={[
                            { title: 'ID', propsRef: 'ID' },
                            {
                                title: 'Fecha',
                                propsRef: 'Fecha',
                            },
                            {
                                title: 'Transacción',
                                propsRef: 'Transacción',
                            },
                        ]}
                        VerticalSumaryProps={[
                            'Monto Total',
                            'Comisión',
                            'Monto a Depositar',
                        ]}
                    />
                )}
            </>
        );
    }

    componentDidMount = () => {
        if (window.innerWidth < 650) {
            this.loadData();
        }
    };

    public async loadData(args?: DataTableRequestParameters) {
        const response = await this.tableDataPayments(args, []);
        this.setState({
            data: response,
        });
    }

    private tableDataPayments(args: DataTableRequestParameters): Promise<any> {
        let endpoint = ApiConstants.GET_PAYMENTS_ENDPOINT;
        if (args) {
            endpoint += `?page=${args.page || 1}`;
            endpoint += `&pageSize=${args.pageSize || 10}`;

            if (args.sort) {
                endpoint += `&sort=${args.sort}&sortDirection=${args.sortDirection}`;
            }
            if (args.searchText) {
                endpoint += `&search=${args.searchText}`;
            }
        }
        const ajax = resourceService.get(endpoint).then((res: any) => {
            // res.data.forEach(item => {
            //     item.name = item.name + ' for page #' + args.page;
            // });
            const result: DataTablePageMeta = {
                data: res.data,
                page: (args && args.page) || 1,
                count: res.count,
            };
            return result;
        });
        const promiseLoading = new Promise<void>(resolve => {
            loading();
            setTimeout(() => {
                loading(false);
                resolve();
            }, 3000);
        });

        return utils
            .delay(3, ajax, promiseLoading)
            .then((values: any[]) => values[0]);
    }

    private tableDataPaymentsFiltered(
        args: DataTableRequestParameters,
        column,
    ): Promise<any> {
        let endpoint = ApiConstants.GET_PAYMENTS_FILTERED_ENDPOINT;
        if (args) {
            endpoint += `?page=${args.page || 1}`;
            endpoint += `&pageSize=${args.pageSize || 10}`;
            if (args.sort) {
                endpoint += `&sort=${args.sort}&sortDirection=${args.sortDirection}`;
            }
            if (args.searchText) {
                endpoint += `&search=${args.searchText}`;
            }
            if (column) {
                endpoint += `&column=${column}`;
            }
        } else {
            endpoint = '';
        }

        const ajax = resourceService.get(endpoint).then((res: any) => {
            const result: DataTablePageMeta = {
                data: res.data,
                page: (args && args.page) || 1,
                count: res.count,
            };
            return result;
        });
        const promiseLoading = new Promise<void>(resolve => {
            loading();
            setTimeout(() => {
                loading(false);
                resolve();
            }, 1000);
        });

        return utils
            .delay(3, ajax, promiseLoading)
            .then((values: any[]) => values[0]);
    }
}

export default withStyles(styles)(Maintenance);
