import { Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import React, { useEffect } from 'react';
const LeavingPageAdvice = props => {
    const [unblock, setUnblock] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleBack = () => {
        window.location.href = '/propiedades';
    };
    useEffect(() => {
        const unblock = props.prompt.history.block((location, action) => {
            handleOpen();
            return false;
        });
        return () => {
            unblock();
        };
    });

    const checkIfLeaving = () => {
        return (
            <div>
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ backgroundColor: '#007dc3' }}>
                        <DialogTitle
                            id="alert-dialog-title"
                            style={{ textAlign: 'justify', color: 'white' }}
                        >
                            {'¿Deseas salir sin guardar los cambios?'}
                        </DialogTitle>
                        <DialogActions>
                            <Button
                                style={{ textAlign: 'justify', color: 'white' }}
                                onClick={handleBack}
                                autoFocus
                            >
                                Descartar cambios
                            </Button>
                            <Button
                                style={{ textAlign: 'justify', color: 'white' }}
                                onClick={handleClose}
                            >
                                Completar campos
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    };
    return <>{checkIfLeaving()}</>;
};

export default LeavingPageAdvice;
