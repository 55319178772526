import {
    AppBar,
    CssBaseline,
    Link,
    makeStyles,
    Toolbar,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
}));
export default function FullPageHeader() {
    const classes = useStyles('paper');
    return (
        <div>
            <React.Fragment>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    color="default"
                    className={classes.appBar}
                >
                    <Toolbar>
                        <Link href="/">
                            {'   '}
                            <ArrowBackIcon />
                            Volver al Inicio
                        </Link>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        </div>
    );
}
