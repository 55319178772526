import { MenuItem } from 'app/types';
const subMenuUser: MenuItem[] = [
    {
        icon: 'fa fa-home',
        text: `Agregar Inmueble y 
         Propietario`,
        params: 'tenant-config-payment',
        link: '/configurar-pagos',
    },
    {
        icon: 'fa fa-key',
        text: `Cambiar Contraseña`,
        description: '',
        link: '/cambiar-contraseña',
    },
    {
        icon: 'fa fa-key',
        text: `Completar los datos faltantes`,
        params: 'missing-info',
        link: '/completar-datos',
    },
];

export default subMenuUser;
