import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Tooltip, Typography, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';

interface Props {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
}
const useStyles = makeStyles(() => ({
    subTitle: {
        color: '#3f51b5',
        fontSize: 'medium',
        margin: '10px 0px',
    },
}));
export default function GenerateInstructionalLink(props: Props) {
    const classes = useStyles();

    React.useEffect(() => {}, []);

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Compartir enlace de pago.
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid container item xs={12}>
                            <Grid>
                                <Typography component="h3">
                                    Genera y comparte un enlace de pago para tu
                                    inquilino.
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography
                                    component="h3"
                                    className={classes.subTitle}
                                >
                                    Sigue los siguiente pasos para generar el
                                    enlace de pago.
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography component="p">
                                    1) Coloca el correo electrónico de tu
                                    inquilino.
                                </Typography>
                                <Typography component="p">
                                    2) Confirma el correo electrónico colocado.
                                </Typography>

                                <Typography component="p">
                                    3) Coloca el número telefónico de tu
                                    inquilino.
                                </Typography>
                                <Typography component="p">
                                    4) Haz click en el botón de “Generar
                                    Enlace”.
                                </Typography>

                                <Typography component="p">
                                    5) Acepta los términos y condiciones.
                                </Typography>
                                <Typography component="p">
                                    6) Copia el enlace y compártelo por la vía
                                    de tu preferencia.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.handleClose}
                        color="primary"
                        autoFocus
                    >
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
