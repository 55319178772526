import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { propertiesService } from '../../service/properties';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import Select from 'react-select';
import storage from 'app/helpers/storage';
const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    paper: {
        textAlign: 'justify',
        padding: 20,
        marginTop: 30,
    },
    total: {
        fontWeight: 700,
    },
    buttonContainer: {
        marginTop: theme.spacing(3),
        justifyContent: 'flex-end',
        display: 'flex',
    },
    loading: {
        marginTop: theme.spacing(3),
    },
    media: {
        height: 140,
    },
}));

export default function SelectProperty(props) {
    const [property, setProperty] = React.useState([]);
    const [userId, setUserId] = React.useState(storage.get('user')?.id);
    const [loading, setLoading] = React.useState(true);
    const [panelInfo, setPanelInfo] = React.useState(false);
    const [optionSelected, setOptionSelected] = React.useState(true);
    const [isDisabledButton, setIsDisabledButton] = React.useState(true);

    //getting assigned properties
    async function getProperties() {
        let properties = [];
        const response = await propertiesService.getPropertiesAssigned(userId);
        if (response.status === 200) {
            response.data.data.map(data => {
                properties.push({
                    value: data.address,
                    label: data.address,
                    id: data.id_property,
                });
                properties.sort(function(a, b) {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
            });
            setProperty(properties);
        }
        setLoading(false);
    }
    useEffect((): void => {
        if (loading) {
            getProperties();
        }
    });

    function renderLoading() {
        return (
            <center className={classes.loading}>
                <Typography gutterBottom={true} component="h1" variant="h6">
                    Cargando información
                    <br />
                    <CircularProgress />
                </Typography>
            </center>
        );
    }

    function selectPropertyAssigned(e) {
        setOptionSelected(e);
        setPanelInfo(true);
        setIsDisabledButton(false);
        props.setPropertyId(e.id);
    }
    const classes = useStyles('paper');
    return (
        <>
            {loading ? (
                renderLoading()
            ) : (
                <React.Fragment>
                    <br />
                    <center>
                        <Typography variant="h6" gutterBottom>
                            Escoge la propiedad a pagar
                        </Typography>
                    </center>
                    <Grid item xs={12} md={12}>
                        <Select
                            inputId="react-select-single"
                            TextFieldProps={{
                                label: '',
                                InputLabelProps: {
                                    htmlFor: 'react-select-single',
                                    shrink: true,
                                },
                            }}
                            value={optionSelected}
                            onChange={e => selectPropertyAssigned(e)}
                            options={property}
                            placeholder="Escoge una opción"
                        />
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            name="submit"
                            disabled={isDisabledButton}
                            onClick={() => props.handleNext()}
                        >
                            <span style={{ color: 'white' }}>Siguente</span>
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </>
    );
}
