import {
    Button,
    CircularProgress,
    Grid,
    Link,
    makeStyles,
    Paper,
    // Radio,
    // RadioGroup,
    Typography,
} from '@material-ui/core';
import storage from 'app/helpers/storage';
import React, { useEffect } from 'react';
import { FullPageHeader } from './components';
import SearchProperties from './components/SearchProperties';
import { propertiesService } from '../../service/properties';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Select from 'react-select';
import Stepper from '@material-ui/core/Stepper';
import OwnerBankInputs from './components/OwnerBankInputs';
import Inquilino from '../../../assets/images/estimado-inquilino.png';
import Logo from '../../../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    buttons: {
        flexGrow: 1,
        marginTop: 50,
        minWidth: 50,
    },
    layoutSuccess: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1138,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    back: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    smallRadioButton: {
        '& svg': {
            width: '1.5em',
            height: '1.5em',
        },
        search: {
            marginTop: '20px',
        },
    },
    documentType: {
        minWidth: 130,
    },
    stepper: {
        padding: theme.spacing(2, 0, 4),
    },
    ownerIdLink: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        float: 'right',
    },
}));

const steps = ['Buscar propiedad', 'Escoger propiedad'];

export default function SelectConfigAssign(props) {
    let getDate = new Date();
    const [tenantId, setTenantId] = React.useState('');
    const [type, setType] = React.useState('document');
    const [documentType, setDocumentType] = React.useState('Cédula');
    const [document, setDocument] = React.useState('');
    const [properties, setProperties] = React.useState([]);
    const [propertyCode, setPropertyCode] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [propertySelected, setPropertySelected] = React.useState(0);
    const [bankAccount, setBankAccount] = React.useState('');
    const [bank, setBank] = React.useState('');
    const [accountNumber, setAccountNumber] = React.useState('');
    const [ownerName, setOwnerName] = React.useState('');
    const [ownerEmail, setOwnerEmail] = React.useState('');
    const [revenue, setRevenue] = React.useState(getDate);
    const [payday, setPayday] = React.useState(getDate);
    const [amount, setAmount] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const sortSelect = array => {
        array.sort(function(a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    };
    const selectProperty = value => {
        setPropertySelected(value);
    };
    const cleanFields = () => {
        setBank('');
        setAccountNumber('');
        setPropertySelected(0);
        setOwnerName('');
        setOwnerEmail('');
    };

    // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     cleanFields();
    //     let type = (event.target as HTMLInputElement).value;
    //     setType(type);
    // };

    const handleDocumentOption = async value => {
        setDocumentType(value);
    };

    const handlePropertyCode = async value => {
        if (value.match(/^[0-9]*$/)) {
            setPropertyCode(value);
        }
    };

    /*handleBack*/

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    /*handleNext*/

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const saveConfigPayment = async () => {
        setSaving(true);
        let addressee = ownerEmail;
        let isTenant = true;
        const assign = await propertiesService.assignProperty(
            document,
            tenantId,
            propertySelected.id,
            addressee,
            revenue,
            payday,
            amount,
            isTenant,
            accountNumber,
            bankAccount,
            bank,
        );
        if (assign.status === 200) {
            setSaving(false);
            setSuccess(true);
            cleanFields();
        }
    };

    /** get properties from owner **/

    const searchProperty = async field => {
        cleanFields();
        setLoading(true);
        let response: Record<string, any>;
        let properties = [];
        if (field === 'local_id' || field === 'passport') {
            response = await propertiesService.findOwnerProperties(document);
        } else {
            response = await propertiesService.findOwnerProperties(
                propertyCode,
            );
        }
        if (response.status === 200 && response.data.data.address !== null) {
            let name = response.data.owner.name;
            let email = response.data.owner.email;
            response.data.data.map(data => {
                properties.push({
                    value: data.address,
                    label: data.address,
                    id: data.id,
                });
            });
            sortSelect(properties);
            setProperties(properties);
            setPropertyCode(propertyCode);
            setOwnerEmail(email);
            setOwnerName(name);
            setMessage('Datos encontrados del propietario');
        } else if (response.status !== 200) {
            setMessage('No se ha encontrado el propietario');
        }
        setLoading(false);
    };
    function inputsFilled() {
        if (
            bank !== '' &&
            accountNumber !== '' &&
            bankAccount !== '' &&
            amount !== '' &&
            propertySelected !== 0 &&
            document !== ''
        ) {
            return true;
        }
    }
    useEffect(() => {
        inputsFilled();
        if (storage.get('user') === null) {
            props.history.push('/inicio');
            return;
        }
        setTenantId(storage.get('user').id);
    }, [inputsFilled]);

    // function RenderOptions() {
    //     return (
    //         <>
    //             <FormControl
    //                 component="fieldset"
    //                 className={classes.formControl}
    //             >
    //                 <RadioGroup value={type} onChange={handleRadioChange}>
    //                     <FormControlLabel
    //                         value="document"
    //                         control={
    //                             <Radio className={classes.smallRadioButton} />
    //                         }
    //                         label="Por documento de identidad."
    //                     />
    //                     <FormControlLabel
    //                         value="property_id"
    //                         control={
    //                             <Radio className={classes.smallRadioButton} />
    //                         }
    //                         label="Por código de propiedad."
    //                     />
    //                 </RadioGroup>
    //             </FormControl>
    //         </>
    //     );
    // }

    function PaymentsBrand() {
        return (
            <Typography variant="body1" color="textPrimary" align="center">
                Aceptamos las tarjetas <br />
                <div style={{ display: 'inline-flex' }}>
                    <img src="../images/brands/visa.png" />
                    <img src="../images/brands/mastercard.png" />
                    <img src="../images/brands/discover.png" />
                    <img src="../images/brands/diners.png" />
                    <img src="../images/brands/american.png" />
                </div>
            </Typography>
        );
    }
    {
        /*Never use getStepContent as a component who receive props, use it as a function in render*/
    }
    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Busca la propiedad
                        </Typography>
                        {/* <RenderOptions /> */}
                        <SearchProperties
                            type={type}
                            documentType={documentType}
                            document={document}
                            setDocumentType={setDocumentType}
                            setDocument={setDocument}
                            handlePropertyCode={handlePropertyCode}
                            propertyCode={propertyCode}
                            handleDocumentOption={handleDocumentOption}
                            searchProperty={searchProperty}
                        />

                        <React.Fragment>
                            <br />
                            {loading && (
                                <center>
                                    <Typography variant="h6" gutterBottom>
                                        Cargando datos...
                                    </Typography>
                                    <br />
                                    <CircularProgress />
                                </center>
                            )}
                            <RenderOwnerInfo />
                        </React.Fragment>
                    </>
                );
            case 1:
                return (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Escoge la propiedad del propietario:
                        </Typography>
                        <br />
                        <Grid item xs={12} md={12}>
                            <Select
                                inputId="react-select-single"
                                TextFieldProps={{
                                    label: '',
                                    InputLabelProps: {
                                        htmlFor: 'react-select-single',
                                        shrink: true,
                                    },
                                }}
                                value={propertySelected}
                                onChange={e => selectProperty(e)}
                                options={properties}
                                placeholder="Escoge una opción"
                            />
                        </Grid>
                        <OwnerBankInputs
                            bank={bank}
                            setBank={setBank}
                            accountNumber={accountNumber}
                            bankAccount={bankAccount}
                            setBankAccount={setBankAccount}
                            setAccountNumber={setAccountNumber}
                            revenue={revenue}
                            setRevenue={revenue}
                            payday={payday}
                            setPayday={setPayday}
                            amount={amount}
                            setAmount={setAmount}
                        />
                    </>
                );
            default:
                throw new Error('Unknown step');
        }
    }

    function RenderOwnerInfo() {
        return (
            !loading &&
            properties.length > 0 && (
                <>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" gutterBottom>
                            <center>{message}</center>
                        </Typography>
                        {ownerName !== '' && ownerEmail !== '' && (
                            <Grid item xs={12} md={12}>
                                <h3>
                                    Nombre:{' '}
                                    <Typography variant="body1">
                                        {ownerName}
                                    </Typography>
                                </h3>
                                <h3>
                                    Correo:{' '}
                                    <Typography variant="body1">
                                        {ownerEmail}
                                    </Typography>
                                </h3>
                            </Grid>
                        )}
                    </Grid>
                    <br />
                </>
            )
        );
    }

    function RenderSuccessAssignment() {
        return (
            <React.Fragment>
                <main className={classes.layoutSuccess}>
                    <Paper className={classes.paper}>
                        <img
                            alt="Remax"
                            className={classes.img}
                            src={Inquilino}
                        />
                        <Typography variant="h5">
                            <div style={{ textAlign: 'justify' }}>
                                <br />
                                Estimado inquilino,
                                <br />
                                <br />
                                En este momento el propietario recibirá un
                                correo para confirmar sus datos.
                                <br />
                                <br />
                                Tan pronto el propietario valide la información,
                                se te notificará vía correo electrónico y podrás
                                realizar el pago. Por favor comunícate con tu
                                propietario e infórmale que va a recibir un
                                correo.
                                <br />
                                <br />
                                En caso de no visualizar el{' '}
                                <strong>"correo de confirmación"</strong> en la
                                Bandeja de Entrada, por favor revisar la{' '}
                                <strong>"Carpeta de Spam"</strong> del correo.
                            </div>
                        </Typography>
                        <center>
                            <br />
                            <img
                                alt="Remax"
                                className={classes.imgLogo}
                                src={Logo}
                            />
                        </center>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }

    const classes = useStyles('paper');
    return (
        <>
            <FullPageHeader />
            {success !== true && (
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Stepper
                            activeStep={activeStep}
                            className={classes.stepper}
                        >
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {getStepContent(activeStep)}

                        <div className={classes.buttons}>
                            <Grid container spacing={3}>
                                <Grid item xs={7}>
                                    {activeStep === 0 && (
                                        <Link
                                            href="/configurar-pagos"
                                            className={classes.ownerIdLink}
                                        >
                                            ¿El propietario no esta en RE/MAX?{' '}
                                            <br />
                                            Haz clic aquí
                                        </Link>
                                    )}
                                </Grid>
                                <Grid item xs={5}>
                                    {!loading && properties.length > 0 && (
                                        <React.Fragment>
                                            <div className={classes.back}>
                                                {activeStep !== 0 && (
                                                    <Button
                                                        onClick={handleBack}
                                                        className={
                                                            classes.button
                                                        }
                                                    >
                                                        Volver
                                                    </Button>
                                                )}
                                                {properties.length > 0 &&
                                                    activeStep === 0 && (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={false}
                                                            onClick={
                                                                activeStep === 0
                                                                    ? handleNext
                                                                    : saveConfigPayment
                                                            }
                                                            className={
                                                                classes.button
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        'white',
                                                                }}
                                                            >
                                                                Siguente
                                                            </span>
                                                        </Button>
                                                    )}
                                                {properties.length > 0 &&
                                                    activeStep !== 0 && (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                inputsFilled()
                                                                    ? false
                                                                    : true
                                                            }
                                                            onClick={
                                                                saveConfigPayment
                                                            }
                                                            className={
                                                                classes.button
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        'white',
                                                                }}
                                                            >
                                                                {!saving ? (
                                                                    'Guardar'
                                                                ) : (
                                                                    <CircularProgress
                                                                        size={
                                                                            25
                                                                        }
                                                                        color="white"
                                                                    />
                                                                )}
                                                            </span>
                                                        </Button>
                                                    )}
                                                {activeStep > 1 && (
                                                    <RenderSuccessAssignment />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <PaymentsBrand />
                    </Paper>
                </main>
            )}
            {success && <RenderSuccessAssignment />}
        </>
    );
}
