import React from 'react';
import { Advice } from 'app/pages/public';
import storage from './storage';
import { paymentsService as admin } from 'app/service/admin';
export function permissions() {
    if (storage.get('missing_info')?.data?.missingInfo.length > 0) {
        return (
            <Advice
                hasActions={true}
                subBody={``}
                body={`Para acceder a esta sección debes completar los datos faltantes en "configuración".`}
            />
        );
    } else {
        return false;
    }
}

export async function adminPermissions(props) {
    // loading();
    let hasPermissions = await admin.checkAdmin(storage.get('user').id);
    if (hasPermissions.data.isAdmin !== 1) {
        props.history.push('/');
    }
    // loading(false);
}
