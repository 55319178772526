import React from 'react';
import ResponsiveTable from 'material-ui-next-responsive-table';
import { createStyles } from '@material-ui/core';
import { AppTheme } from 'app/theme';

const styles = (theme: AppTheme) =>
    createStyles({
        '@global': {
            body: {
                color: theme.palette.text.primary,
                maxHeight: '20px',
            },
            '.markdown-body pre': {
                backgroundColor: theme.palette.background.paper,
            },
        },
        overrides: {
            MuiTableCell: {
                root: {
                    padding: '18px 0px 20px 16px',
                },
            },
        },
    });

export default function PropertyInfo({ propertyInfo, loading }: any) {
    const columns = [
        {
            key: 'id',
            label: 'ID',
            primary: true,
            render: value => {
                return value;
            },
        },
        {
            key: 'property_id',
            label: 'Codigo',
            render: value => {
                return value;
            },
        },
        {
            key: 'city',
            label: 'Ciudad',
            render: value => {
                return value;
            },
        },
        {
            key: 'sector',
            label: 'Sector',
            render: value => {
                return value;
            },
        },
        {
            key: 'business_type',
            label: 'Tipo de Negocio',
            render: value => {
                return value;
            },
        },
        {
            key: 'property_type',
            label: 'Tipo de Propiedad',
            render: value => {
                return value;
            },
        },
        {
            key: 'revenue_date',
            label: 'Fecha de renovacíon',
            render: value => {
                return value;
            },
        },
        {
            key: 'payday',
            label: 'Día de Pago',
            render: value => {
                return value;
            },
        },
        {
            key: 'created_at',
            label: 'Creado',
            render: value => {
                return value;
            },
        },
        {
            key: 'updated_at',
            label: 'Actualizado',
            render: value => {
                return value;
            },
        },
        {
            key: 'rental_document',
            label: 'Contrato de alquiler',
            // eslint-disable-next-line react/display-name
            render: (value: any) => {
                return value !== ' - ' ? (
                    <a
                        style={{ color: 'blue' }}
                        target="_blank"
                        href={value}
                        rel="noreferrer"
                    >
                        {' '}
                        {'Link'}{' '}
                    </a>
                ) : (
                    ' - '
                );
            },
        },
    ];

    return (
        <ResponsiveTable
            columns={columns}
            data={propertyInfo}
            noContentText={
                loading ? 'Cargando datos' : ' No tiene propiedades creadas'
            }
        />
    );
}
