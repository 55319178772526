//FOR ENDPOINTS WHO DOESN'T NEED TOKEN
import axios, { AxiosResponse } from 'axios';
import ApiConstants from '../config/api.constants';

export const config = {
    headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': ApiConstants.X_API_KEY,
    },
};

export interface PromiseProps {
    data: any;
    status: unknown;
}

export const propertiesService = {
    addProperty: async (data, user) => {
        let res;
        await axios
            .post(ApiConstants.ADD_PROPERTY_ENDPOINTS, { data, user }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    sendLinkTenant: async (
        tenantPhone,
        document,
        ownerId,
        propertyId,
        addressee,
        revenue,
        payday,
        amount,
        isTenant,
        accountNumber,
        bankAccount,
        bank,
    ): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.PAYMENT_LINK_PROPERTY_ENDPOINTS,
                {
                    tenantPhone,
                    document,
                    ownerId,
                    propertyId,
                    addressee,
                    revenue,
                    payday,
                    amount,
                    isTenant,
                    accountNumber,
                    bankAccount,
                    bank,
                },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getOwnerProperties: async (id, identification): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.GET_OWNER_PROPERTIES_ENDPOINT,
                { id, identification },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getPropertiesAssigned: async (user_id): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.ASSIGNED_OWNER_PROPERTIES_ENDPOINT,
                { user_id },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    // sendLinkTenant: async (
    //     owner_id,
    //     propertyId,
    //     email,
    // ): Promise<PromiseProps> => {
    //     let res;
    //     await axios
    //         .post(uri.sendLinkTenant, { email, owner_id, propertyId }, config)
    //         .then((response: AxiosResponse) => {
    //             //handle success
    //             res = response;
    //         })
    //         .catch((error: AxiosResponse) => {
    //             //handle error
    //             res = error;
    //         });
    //     return res;
    // },
    findOwnerProperties: async (field): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.FIND_OWNER_PROPERTIES_ENDPOINT,
                { field },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getEditFieldsData: async (id, userId): Promise<PromiseProps> => {
        let res;
        await axios
            .post(
                ApiConstants.EDIT_GET_PROPERTY_ENDPOINTS,
                { id, userId },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    editProperty: async (data): Promise<PromiseProps> => {
        let res;
        await axios
            .post(ApiConstants.EDIT_PROPERTY_ENDPOINTS, { data }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
};
