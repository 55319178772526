import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper1: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
            backgroundImage: `url("/images/inicio-background.png")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '67vh',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
        },
        paper2: {
            padding: theme.spacing(2),
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            backgroundPosition: 'center center',
            height: '35vh',

            [theme.breakpoints.down('md')]: {
                height: '37vh',
            },

            [theme.breakpoints.down('sm')]: {
                height: '42vh',
            },
        },
        colorLogo: {
            width: '15%',
            [theme.breakpoints.down('md')]: {
                width: '30%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '35%',
            },
        },
        text1: {
            textAlign: 'left',
            marginLeft: '6px',
            fontWeight: 'bold',
            color: '#3f4445',
            marginTop: '5%',
            fontSize: '3vw',
            [theme.breakpoints.down('md')]: {
                marginLeft: '6px',
                fontWeight: 'bold',
                color: '#3f4445',
                marginTop: '5%',
                fontSize: '4vw',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '3px',
                fontWeight: 'bold',
                color: '#3f4445',
                marginTop: '4.5%',
                fontSize: '4.5vw',
            },
        },
        text2: {
            textAlign: 'left',
            marginLeft: '10px',
            color: '#3f4445',
            fontSize: '1vw;',
            [theme.breakpoints.down('md')]: {
                marginLeft: '6px',
                fontSize: '1.4vw;',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '6px',
                fontSize: '2.4vw;',
            },
        },
        textInfo: {
            textAlign: 'justify',
            color: '#3f4445',
            fontSize: '1vw',
            paddingLeft: '10px',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '2.6vw;',
            },
        },
        title1: {
            textAlign: 'center',
            marginLeft: '10px',
            color: '#3f4445',
            padding: '4px 0px 4px 0px',
            fontSize: '2vw',
            [theme.breakpoints.down('md')]: {
                fontSize: '2vw',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '4vw;',
            },
        },
        textSquares3: {
            textAlign: 'center',
            color: '#3f4445',
            fontSize: '0.9vw',
            fontWeight: '500',
            paddingTop: '25px',
            height: '16vh',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
                height: '14vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.7vw',
                height: '14vh',
            },
        },
        textSquares2: {
            textAlign: 'center',
            color: '#3f4445',
            fontSize: '0.9vw',
            fontWeight: '500',
            paddingTop: '15px',
            height: '16vh',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
                height: '14vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.7vw',
                height: '14vh',
            },
        },
        textSquares1: {
            textAlign: 'center',
            color: '#3f4445',
            fontSize: '0.9vw',
            paddingTop: '5px',
            fontWeight: '500',
            height: '16vh',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3vw',
                height: '14vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.7vw',
                height: '14vh',
            },
        },
        brandContainer: {
            paddingLeft: '10px',
            paddingTop: '10px',
        },
        borderStyle: {
            borderRight: '1px solid rgba(0, 0, 0, 0.22)',
            paddingRight: '90px',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '70px',
                width: '1.5vh',
            },
            [theme.breakpoints.down('md')]: {
                paddingRight: '70px',
                width: '1.5vh',
            },
        },
        logoAzul: {
            width: '3vh',
            marginLeft: '15px',
            [theme.breakpoints.down('md')]: {
                marginLeft: '15px',
                width: '3vh',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '15px',
                width: '2.5vh',
            },
        },
        logoRemax: {
            width: '9vh',
            marginLeft: '5px',
            [theme.breakpoints.down('md')]: {
                width: '7vh',
                marginLeft: '5px',
            },
            [theme.breakpoints.down('sm')]: {
                width: '7vh',
                marginLeft: '2px',
            },
        },
    }),
);

export default function WelcomeAdmin() {
    const classes = useStyles('paper1');

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper1}>
                        <img
                            className={classes.colorLogo}
                            src="/images/remax_azul.png"
                            alt="RE/MAX"
                        />
                        <Typography
                            component="h2"
                            variant="h2"
                            className={classes.text1}
                        >
                            <b>Bienvenido, administrador</b>
                            <br />
                        </Typography>
                        <Typography
                            gutterBottom={true}
                            component="h5"
                            variant="h5"
                            className={classes.text2}
                        >
                            <br />
                            <br />
                            <strong>
                                RE/MAX, la red #1 del
                                <br />
                                sector inmobiliario.
                            </strong>
                            <br />
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
