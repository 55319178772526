import {
    createStyles,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { BasicInfoProps } from '../types/MissingInfoTypes';
import React from 'react';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            marginTop: 5,
        },
        fieldTitles: {
            fontSize: '16px',
            color: 'red',
            marginBottom: '20px',
            marginTop: '20px',
        },
        formControl: {
            minWidth: 200,
        },
        selectBank: {
            minWidth: 185,
            [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
            },
        },
        selectAccountType: {
            minWidth: 185,
            [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
            },
        },
    }),
);

const BankAccountInput: React.FC<BasicInfoProps> = ({
    bank,
    bankAccount,
    bankAccountType,
    handleAccountNumber,
    handleAccountType,
    handleBank,
}) => {
    const classes = useStyles('');
    return (
        <div className={classes.root}>
            <>
                <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                        <InputLabel id="demo-simple-select-label">
                            Banco *
                        </InputLabel>
                        <Select
                            id="demo-simple-select"
                            value={bank}
                            className={classes.selectBank}
                            onChange={e => handleBank(e.target.value)}
                        >
                            <MenuItem value={'Asociación Cibao'}>
                                Asociación Cibao
                            </MenuItem>
                            <MenuItem value={'Asociación La Nacional'}>
                                Asociación La Nacional
                            </MenuItem>
                            <MenuItem
                                value={'Asociación La Vega Real (Alaver)'}
                            >
                                Asociación La Vega Real (Alaver)
                            </MenuItem>
                            <MenuItem value={'Asociación Popular'}>
                                Asociación Popular
                            </MenuItem>
                            <MenuItem value={'Banco Ademi'}>
                                Banco Ademi
                            </MenuItem>
                            <MenuItem value={'Banco BHD León'}>
                                Banco BHD León
                            </MenuItem>
                            <MenuItem value={'Banco Caribe'}>
                                Banco Caribe
                            </MenuItem>
                            <MenuItem value={'Banco Dominicano del Progreso'}>
                                Banco Dominicano del Progreso
                            </MenuItem>
                            <MenuItem value={'Banco Popular'}>
                                Banco Popular
                            </MenuItem>
                            <MenuItem value={'Banco Santa Cruz'}>
                                Banco Santa Cruz
                            </MenuItem>
                            <MenuItem value={'Banesco'}>Banesco</MenuItem>
                            <MenuItem value={'Banreservas'}>
                                Banreservas
                            </MenuItem>
                            <MenuItem value={'Scotiabank'}>Scotiabank</MenuItem>
                        </Select>
                    </Grid>
                    <Grid xs={1}></Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <InputLabel id="demo-simple-select-label">
                            Tipo de cuenta *
                        </InputLabel>
                        {/* <FormControl className={classes.formControl}> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={bankAccountType}
                            className={classes.selectAccountType}
                            onChange={e => handleAccountType(e.target.value)}
                        >
                            <MenuItem value={'Ahorros'}>Ahorros</MenuItem>
                            <MenuItem value={'Corriente'}>Corriente</MenuItem>
                        </Select>
                        {/* </FormControl> */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            id="bank_account"
                            label="Número de cuenta"
                            value={bankAccount}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleAccountNumber(e.target.value)}
                            fullWidth
                            autoComplete="cc-id"
                        />
                    </Grid>
                </Grid>
            </>
        </div>
    );
};

export default BankAccountInput;
