import { MenuItem } from 'app/types';
const menus: MenuItem[] = [
    {
        icon: 'fa fa-building',
        text: 'Mis propiedades',
        description: '',
        children: [
            {
                icon: 'fa fa-pencil',
                text: `Registrar propiedad`,
                description: '',
                link: '/registrar-propiedad',
            },
            {
                icon: 'fa fa-home',
                text: `Propiedades y pagos`,
                description: '',
                link: '/propiedades',
            },
            {
                icon: 'fa fa-list-alt',
                text: 'Historial de Pagos',
                description: '',
                link: '/historial-propietarios',
            },
        ],
    },
    {
        icon: 'fa fa-cog',
        text: 'Configuración',
        params: 'config', //it is used to notify the menu if there any requirement or change
        children: [
            {
                icon: 'fa fa-key',
                text: `Cambiar Contraseña`,
                description: '',
                link: '/cambiar-contraseña',
            },
            {
                icon: 'fa fa-key',
                text: `Completar los datos faltantes`,
                params: 'missing-info',
                link: '/completar-datos',
            },
        ],
    },
    {
        icon: 'fa fa-phone',
        text: 'Contacto',
        description: '',
        link: '/contacto',
    },
];

export default menus;
