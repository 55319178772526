import {
    createStyles,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { ComponentProps } from '../types/MissingInfoTypes';
import React from 'react';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
        },
        fieldTitles: {
            fontSize: '16px',
            color: 'red',
            marginBottom: '20px',
        },
        formControl: {
            minWidth: 150,
        },
    }),
);

const BasicUserInputs: React.FC<ComponentProps> = ({
    telOpt,
    address,
    handleTelOpt,
    handleAddress,
}) => {
    const classes = useStyles('');
    return (
        <div className={classes.root}>
            <>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <InputMask
                            mask="(999)-999-9999"
                            value={telOpt}
                            label="No. de teléfono (opcional)"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                                handleTelOpt(
                                    e.target.value.replace(/[^0-9\.]+/g, ''),
                                )
                            }
                        >
                            {() => (
                                <TextField
                                    id="cardOwnerTel"
                                    label="Teléfono (opcional)"
                                    fullWidth
                                    autoComplete="cc-last"
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            id="tel"
                            label="Dirección del propietario"
                            value={address}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleAddress(e.target.value)}
                            fullWidth
                            autoComplete="cc-id"
                        />
                    </Grid>
                </Grid>
            </>
        </div>
    );
};

export default BasicUserInputs;
