import storage from 'app/helpers/storage';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { DetailPayments } from '../types/IPayments';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import { PanoramaFishEye } from '@material-ui/icons';
import { ApiConstants } from 'app/config';

const crypto = require('crypto');
let config = {
    headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': ApiConstants.X_API_KEY,
    },
};
const initData = {
    data: {
        merchant_id: '',
        merchant_name: '',
        merchant_type: '',
        currency_code: '',
        order_number: '',
        amount: '',
        ITBIS: '',
        confirmation_url: '',
        use_custom_field1: '',
        custom_field_1label: '',
        custom_field_1value: '',
        use_custom_field2: '',
        custom_field_2label: '',
        custom_field_2value: '',
    },
    auth: '',
};
export default function InputsPayments(props) {
    const [details, setPaymentDetails] = React.useState<DetailPayments>(
        initData,
    );
    const [authHash, setAuthHash] = React.useState('');
    const [merchantName, setMercnahtName] = React.useState('');
    const [propertyId, setPropertyId] = React.useState(props.propertyId);
    const fetchData = async id => {
        await axios
            .post(
                ApiConstants.FETCH_PAYMENT_DETAILS_ENDPOINT,
                { id, propertyId },
                config,
            )
            .then(async (response: AxiosResponse) => {
                setPaymentDetails(response.data);
                let params = response.data.data;
                setMercnahtName(params.merchant_name);
                //handle success
                let hashCreated = hmacSHA512(
                    process.env.MERCHANT_ID +
                        params.merchant_name +
                        process.env.MERCHANT_TYPE +
                        process.env.CURRENCY_CODE +
                        params.order_number +
                        params.amount +
                        process.env.ITBIS +
                        process.env.CONFIRMATION_URL +
                        '?affiliate=tenant' +
                        process.env.CONFIRMATION_URL +
                        process.env.CONFIRMATION_URL +
                        params.use_custom_field1 +
                        params.custom_field_1label +
                        params.custom_field_1value +
                        params.use_custom_field2 +
                        params.custom_field_2label +
                        params.custom_field_2value +
                        process.env.ENCRYPTION_KEY,
                    process.env.ENCRYPTION_KEY,
                ).toString();
                setAuthHash(hashCreated);
                return;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                return error;
            });
        return;
    };
    useEffect(() => {
        fetchData(storage.get('user').id);
        return;
    }, []);
    return (
        <React.Fragment>
            <input
                type="hidden"
                id="MerchantId"
                name="MerchantId"
                value={process.env.MERCHANT_ID}
            />
            <input
                type="hidden"
                id="MerchantName"
                name="MerchantName"
                value={merchantName}
            />
            <input
                type="hidden"
                id="MerchantType"
                name="MerchantType"
                value={process.env.MERCHANT_TYPE}
            />
            <input
                type="hidden"
                id="CurrencyCode"
                name="CurrencyCode"
                value={process.env.CURRENCY_CODE}
            />
            <input
                type="hidden"
                id="OrderNumber"
                name="OrderNumber"
                value={details.data.order_number}
            />
            <input
                type="hidden"
                id="Amount"
                name="Amount"
                value={details.data.amount
                    .toString()
                    .split('.')
                    .join('')}
            />
            <input
                type="hidden"
                id="ITBIS"
                name="ITBIS"
                value={process.env.ITBIS}
            />
            <input
                type="hidden"
                id="ApprovedUrl"
                name="ApprovedUrl"
                value={process.env.CONFIRMATION_URL + '?affiliate=tenant'}
            />
            <input
                type="hidden"
                id="DeclinedUrl"
                name="DeclinedUrl"
                value={process.env.CONFIRMATION_URL}
            />
            <input
                type="hidden"
                id="CancelUrl"
                name="CancelUrl"
                value={process.env.CONFIRMATION_URL}
            />
            <input
                type="hidden"
                id="UseCustomField1"
                name="UseCustomField1"
                value={details.data.use_custom_field1}
            />
            <input
                type="hidden"
                id="CustomField1Label"
                name="CustomField1Label"
                value={details.data.custom_field_1label}
            />
            <input
                type="hidden"
                id="CustomField1Value"
                name="CustomField1Value"
                value={details.data.custom_field_1value}
            />
            <input
                type="hidden"
                id="UseCustomField2"
                name="UseCustomField2"
                value={details.data.use_custom_field2}
            />
            <input
                type="hidden"
                id="CustomField2Label"
                name="CustomField2Label"
                value={details.data.custom_field_2label}
            />
            <input
                type="hidden"
                id="CustomField2Value"
                name="CustomField2Value"
                value={details.data.custom_field_2value}
            />
            <input
                type="hidden"
                id="AuthHash"
                name="AuthHash"
                value={authHash}
            />
        </React.Fragment>
    );
}
