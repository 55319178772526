import { MenuItem } from 'app/types';

const menus: MenuItem[] = [
    {
        icon: 'fa fa-credit-card',
        text: 'Pagos',
        description: '',
        link: '/informacion-de-pagos',
    },
    {
        icon: 'fa fa-user',
        text: 'Permisos',
        description: '',
        link: '/permisos',
    },
];

export default menus;
