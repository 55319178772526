import * as React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
    createStyles,
    withStyles,
    List,
    Collapse,
    Badge,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Contact from 'app/pages/public/Contact';
import { MenuItem } from 'app/types';
import { themeConfig, AppTheme, ifLayout } from 'app/theme';
import { publicService } from 'app/service/public';
import storage from 'app/helpers/storage';
import { connect } from 'react-redux';
import { paymentsService } from 'app/service/payments';

const styles = (theme: AppTheme) =>
    createStyles({
        root: {
            '& .MuiListItemIcon-root': {
                color: ifLayout(theme, {
                    popular: 'inherit',
                }),
            },
            '& .MuiTypography-colorTextSecondary': {
                color: ifLayout(theme, {
                    popular: 'inherit',
                }),
            },
        },
        rootMini: {
            '& $listItem': {
                justifyContent: 'center',
                [theme.breakpoints.down('sm')]: {
                    overflow: 'hidden',
                },
            },
            '& $childList.level-1': {
                position: 'absolute',
                width: '100%',
                marginTop: -5,
                backgroundColor: ifLayout(theme, {
                    classic: theme.palette.background.paper,
                    popular: theme.palette.primary.dark,
                }),
                borderTopRightRadius: 0,
                borderBottomRightRadius: theme.shape.borderRadius,
            },
            '& > li': {
                position: 'relative',
                width: '100%',
            },
            '& > li:hover': {
                width: themeConfig.sidebarWidth,
                backgroundColor: ifLayout(theme, {
                    classic: theme.palette.background.paper,
                    popular: theme.palette.primary.dark,
                }),
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[2],
                zIndex: 100,
            },
            '& > li $listItemText': {
                visibility: 'hidden',
            },
            '& > li:hover $listItemText': {
                visibility: 'visible',
            },
            '& > li:hover $childList': {
                display: 'block',
            },

            '& > li $childList': {
                display: 'none',
            },
            '& $expandIcon': {
                display: 'none',
            },
        },
        listItem: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        listItemIcon: {
            marginRight: 0,
            justifyContent: 'center',
        },
        listItemText: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        listItemTextPrimary: {
            fontSize: '0.875rem',
        },
        listItemTextSecondary: {
            fontSize: '0.75rem',
        },
        childList: {
            '& $listItem': {
                paddingLeft: theme.spacing(),
            },
            '& $childList $listItem': {
                paddingLeft: theme.spacing(2),
            },
            '& $childList $childList $listItem': {
                paddingLeft: theme.spacing(3),
            },
        },
        expandIcon: {
            marginRight: 10,
            color: ifLayout(theme, {
                popular: 'inherit',
            }),
        },
    });

class VerticalMenu extends React.Component<
    {
        classes: any;
        mini?: boolean;
        data: MenuItem[];
        width: number | string;
        minWidth: number | string;
        setCloseMenu: () => void;
    },
    {
        itemHovered: boolean;
        menuStatusSet: { [key: string]: boolean };
        userInfo: [];
        modalMissingInfo: boolean;
        getConfigPayment: { data: { has_payment_details: any } };
    }
> {
    private itemHoveredStyle;

    public constructor(props) {
        super(props);
        this.state = {
            itemHovered: false,
            menuStatusSet: {},
            userInfo: [],
            modalMissingInfo: false,
            getConfigPayment: {},
        };
        this.itemHoveredStyle = {
            // width: this.props.width || 200,
        };

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleToggleChildMenu = this.handleToggleChildMenu.bind(this);
        this.RenderContact = this.RenderContact.bind(this);
    }
    public async componentDidMount() {
        const userInfo = await publicService.checkUserInfoRequirements(
            storage.get('user').id,
        );
        const getConfigPayment = await paymentsService.getPaymentDetailsById(
            storage.get('user').id,
            null,
        );
        storage.set('missing_info', userInfo);
        storage.set(
            'has_payment_details',
            getConfigPayment.data?.has_payment_details,
        );
        this.setState({
            userInfo: userInfo.data ? userInfo.data.missingInfo : [],
            getConfigPayment,
        });
    }
    public render() {
        const { classes, mini, data } = this.props;
        return (
            <List
                className={classNames(classes.root, mini && classes.rootMini)}
            >
                {data && data.map(menu => this.renderMenuItem(menu, classes))}
            </List>
        );
    }

    private getMenuKey(menu) {
        return `${menu.text}_${menu.link || ''}`;
    }

    private handleMenuClick(menu) {
        if (menu.children) {
            this.handleToggleChildMenu(menu);
        } else if (window.innerWidth > 959) {
            this.handleToggleChildMenu(menu);
        } else {
            this.handleToggleChildMenu(menu);
            this.props.setCloseMenu(false);
        }

        return;
    }

    private handleContact(menu) {
        this.handleToggleChildMenu(menu);
    }

    public RenderContact() {
        return <Contact />;
    }

    private handleToggleChildMenu(menu) {
        const menuKey = this.getMenuKey(menu);
        if (menu.children) {
            this.setState({
                menuStatusSet: {
                    ...this.state.menuStatusSet,
                    [menuKey]: !this.state.menuStatusSet[menuKey],
                },
            });
        }
    }

    private renderMenuItem(menu, classes, level?) {
        const menuKey = this.getMenuKey(menu);
        const mini = this.props.mini;
        const getLink = React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
            (props, ref) => <Link to={menu.link} {...props} ref={ref as any} />,
        );
        getLink.displayName = 'ListItemLink';
        if (!level) {
            level = 1;
        }
        const ListItemComponent = menu.link ? getLink : null;
        return this.state.userInfo.length > 0 ? (
            <li
                key={menuKey}
                style={this.state.itemHovered ? this.itemHoveredStyle : null}
                onMouseEnter={() => this.setState({ itemHovered: true })}
            >
                <ListItem
                    button={true}
                    className={classes.listItem}
                    onClick={() => this.handleMenuClick(menu)}
                    component={ListItemComponent}
                >
                    <ListItemIcon
                        className={classes.listItemIcon}
                        style={{ width: this.props.minWidth }}
                    >
                        {typeof menu.icon !== 'string' ? (
                            menu.icon
                        ) : (
                            <i className={menu.icon} />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItemText}
                        classes={{
                            primary: classes.listItemTextPrimary,
                            secondary: classes.listItemTextSecondary,
                        }}
                        primary={
                            menu.params === 'missing-info' ||
                            menu.params === 'config' ? (
                                <Badge
                                    className={classes.badge}
                                    badgeContent={
                                        <span
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            !
                                        </span>
                                    }
                                    color="primary"
                                >
                                    {menu.text}
                                </Badge>
                            ) : (
                                menu.text
                            )
                        }
                        secondary={menu.description}
                    />

                    {menu.children &&
                        !mini &&
                        (this.state.menuStatusSet[menuKey] ? (
                            <ExpandLessIcon
                                className={classes.expandIcon}
                                onClick={() => this.handleToggleChildMenu(menu)}
                            />
                        ) : (
                            <ExpandMoreIcon
                                className={classes.expandIcon}
                                onClick={() => this.handleToggleChildMenu(menu)}
                            />
                        ))}
                </ListItem>
                {menu.children &&
                    (mini ? (
                        <List
                            className={classNames(
                                classes.childList,
                                `level-${level}`,
                            )}
                        >
                            {menu.children.map(child =>
                                this.renderMenuItem(child, classes, level + 1),
                            )}
                        </List>
                    ) : (
                        <Collapse
                            in={!!this.state.menuStatusSet[menuKey]}
                            timeout="auto"
                        >
                            <List
                                className={classNames(
                                    classes.childList,
                                    `level-${level}`,
                                )}
                            >
                                {menu.children.map(child =>
                                    this.renderMenuItem(
                                        child,
                                        classes,
                                        level + 1,
                                    ),
                                )}
                            </List>
                        </Collapse>
                    ))}
            </li>
        ) : (
            this.state.userInfo.length === 0 && menu.params !== 'missing-info' && (
                <li
                    key={menuKey}
                    style={
                        this.state.itemHovered ? this.itemHoveredStyle : null
                    }
                    onMouseEnter={() => this.setState({ itemHovered: true })}
                >
                    <ListItem
                        button={true}
                        className={classes.listItem}
                        onClick={() => this.handleMenuClick(menu)}
                        component={ListItemComponent}
                    >
                        <ListItemIcon
                            className={classes.listItemIcon}
                            style={{ width: this.props.minWidth }}
                        >
                            {typeof menu.icon !== 'string' ? (
                                menu.icon
                            ) : (
                                <i className={menu.icon} />
                            )}
                        </ListItemIcon>

                        <ListItemText
                            className={classes.listItemText}
                            classes={{
                                primary: classes.listItemTextPrimary,
                                secondary: classes.listItemTextSecondary,
                            }}
                            primary={
                                (menu.params === 'tenant-config-payment' ||
                                    menu.params === 'config') &&
                                storage.get('has_payment_details') ===
                                    'false' &&
                                !this.state.getConfigPayment.data?.data?.id &&
                                this.props.role === 1 ? (
                                    <Badge
                                        className={classes.badge}
                                        badgeContent={
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                !
                                            </span>
                                        }
                                        color="error"
                                    >
                                        {menu.text}
                                    </Badge>
                                ) : (
                                    menu.text
                                )
                            }
                            secondary={menu.description}
                        />
                        {menu.children &&
                            !mini &&
                            (this.state.menuStatusSet[menuKey] ? (
                                <ExpandLessIcon
                                    className={classes.expandIcon}
                                    onClick={() =>
                                        this.handleToggleChildMenu(menu)
                                    }
                                />
                            ) : (
                                <ExpandMoreIcon
                                    className={classes.expandIcon}
                                    onClick={() =>
                                        this.handleToggleChildMenu(menu)
                                    }
                                />
                            ))}
                    </ListItem>
                    {menu.children &&
                        (mini ? (
                            <List
                                className={classNames(
                                    classes.childList,
                                    `level-${level}`,
                                )}
                            >
                                {menu.children.map(child =>
                                    this.renderMenuItem(
                                        child,
                                        classes,
                                        level + 1,
                                    ),
                                )}
                            </List>
                        ) : (
                            <Collapse
                                in={!!this.state.menuStatusSet[menuKey]}
                                timeout="auto"
                            >
                                <List
                                    className={classNames(
                                        classes.childList,
                                        `level-${level}`,
                                    )}
                                >
                                    {menu.children.map(child =>
                                        this.renderMenuItem(
                                            child,
                                            classes,
                                            level + 1,
                                        ),
                                    )}
                                </List>
                            </Collapse>
                        ))}
                </li>
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        role: state.global.role,
    };
};
export default connect(mapStateToProps, null)(withStyles(styles)(VerticalMenu));
