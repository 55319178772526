import * as React from 'react';
import * as intl from 'react-intl-universal';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import logo from '/assets/images/remax_azul.png';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import {
    TextField,
    Link,
    CircularProgress,
    AppBar,
    Toolbar,
} from '@material-ui/core';
import { ResetPasswordProps, ResetPasswordState } from './types/RegisterTypes';
import { resetPassword, config } from '../../service/reset-password';
import validator from 'validator';
import { Panel } from 'app/ui';
import classNames from 'classnames';
import { themeConfig } from 'app/theme';
import axios, { AxiosResponse } from 'axios';
import storage from 'app/helpers/storage';
import { ApiConstants } from 'app/config';

const styles = (theme: Theme) =>
    createStyles({
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(6))]: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        panel: {
            marginTop: theme.spacing(8),
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            width: 460,
            textAlign: 'center',
            [theme.breakpoints.down(460)]: {
                width: 300,
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)}px ${theme.spacing(
                3,
            )}px ${theme.spacing(3)}px`,
        },
        // avatar: {
        //     margin: theme.spacing(),
        //     backgroundColor: theme.palette.secondary.main,
        //     width: 100,
        //     height: 100,
        //     fontSize: 110,
        // },
        form: {
            width: '100%',
            marginTop: theme.spacing(),
        },
        submit: {
            marginTop: theme.spacing(3),
        },
        icon: {
            marginBottom: 30,
        },
        success: {
            color: themeConfig.palette.success,
        },
        rounded: {
            width: '70%',
            margin: '40px 0 40px 0',
        },
        textColor: {
            color: 'blue',
        },
        buttonProgress: {
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    });

class ResetPassword extends React.Component<
    ResetPasswordProps,
    ResetPasswordState
> {
    public constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isResettingPassword: false,
            isRequestingEmail: false,
            isDisabled: false,
            isSended: false,
            loading: true,
            loadingButton: false,
            wasReset: false,
            newPassword: '',
            newConfirmPassword: '',
            hasToken: '',
        };
        this.onResetPassword = this.onResetPassword.bind(this);
        this.onSendMail = this.onSendMail.bind(this);
        this.submitSendMail = this.submitSendMail.bind(this);
    }
    public componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let hasToken = storage.get('token') || '';
        this.setState({
            hasToken: hasToken,
        });
        if (params.get('token') !== null) {
            axios
                .post(
                    ApiConstants.CHECK_USER_ENDPOINT,
                    { token: params.get('token') },
                    config,
                )
                .then(response => {
                    if (response.data) {
                        this.setState({
                            isResettingPassword: true,
                            isRequestingEmail: false,
                            isDisabled: false,
                            isSended: false,
                            loading: false,
                        });
                    } else {
                        this.props.history.push('/inicio');
                    }
                    //handle success
                    return response.data;
                })
                .catch((error: AxiosResponse) => {
                    //handle error
                    return error;
                });
        } else {
            this.setState({
                isResettingPassword: false,
                isRequestingEmail: true,
                isDisabled: true,
                isSended: false,
                loading: false,
                loadingButton: false,
            });
        }
        return;
    }

    public onSendMail(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) {
        if (validator.isEmail(event.target.value)) {
            this.setState({
                isDisabled: false,
                email: event.target.value,
            });
        } else {
            this.setState({
                isDisabled: true,
                email: '',
            });
        }
    }

    public async submitSendMail() {
        this.setState({
            loadingButton: true,
        });
        const response = await resetPassword.sendMail(this.state.email);
        if (response.status === 200) {
            this.setState({
                isResettingPassword: false,
                isRequestingEmail: false,
                isDisabled: false,
                isSended: true,
                loading: false,
                loadingButton: false,
                wasReset: false,
            });
        }
    }
    public async onResetPassword() {
        let params = new URLSearchParams(this.props.location.search);
        if (
            this.state.newPassword === this.state.newConfirmPassword &&
            this.state.newPassword.length > 3 &&
            this.state.newConfirmPassword.length > 3
        ) {
            this.setState({
                loadingButton: true,
            });
            const response = await resetPassword.changePassword(
                this.state.newPassword,
                params.get('email'),
            );
            if (response.status === 200) {
                this.setState({
                    isResettingPassword: true,
                    isRequestingEmail: false,
                    isDisabled: false,
                    isSended: false,
                    loading: false,
                    loadingButton: false,
                    wasReset: true,
                });
            }
        }

        return;
    }
    public render() {
        const { classes } = this.props;
        const {
            isResettingPassword /*resetting the password */,
            isDisabled /*Disable the button */,
            isSended /* email sended confirmation */,
            isRequestingEmail /*Requestin email to send the reset password*/,
            loading /*Verifying if user exists and if is real after proceed to reset password*/,
            loadingButton /*Loading button before send mail*/,
            wasReset /*finally set was reset if was successfull*/,
        } = this.state;
        return (
            <main className={classes.main}>
                <CssBaseline />
                {storage.get('token') && (
                    <AppBar
                        position="absolute"
                        color="default"
                        className={classes.appBar}
                    >
                        <Toolbar>
                            <Link href="/">
                                {'   '}
                                <ArrowBackIcon />
                                Volver al Inicio
                            </Link>
                        </Toolbar>
                    </AppBar>
                )}
                {/*Checking if user exists*/}
                {loading && (
                    <Paper className={classes.paper}>
                        <div>
                            <Typography
                                gutterBottom={true}
                                component="h1"
                                variant="h6"
                            >
                                Cargando...
                                <br />
                                <br />
                                <center>
                                    <CircularProgress />
                                </center>
                            </Typography>
                        </div>
                    </Paper>
                )}
                {/*Send email to reset password*/}
                {isResettingPassword === false &&
                    isRequestingEmail === true &&
                    loading === false && (
                        <Paper className={classes.paper}>
                            <img
                                alt="Remax"
                                src={'../../../images/remax_azul.png'}
                                className={classes.rounded}
                            />
                            <br />
                            {window.innerWidth < 360 ? (
                                <Typography component="h1" variant="body1">
                                    {'Restablece tu contraseña'}
                                </Typography>
                            ) : (
                                <Typography component="h1" variant="h5">
                                    {'Restablece tu contraseña'}
                                </Typography>
                            )}
                            <form className={classes.form}>
                                <FormControl
                                    margin="normal"
                                    required={true}
                                    fullWidth={true}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        name="email"
                                        variant="outlined"
                                        type="email"
                                        placeholder="Ingresa el correo electrónico asociado a tu cuenta."
                                        autoComplete="email"
                                        autoFocus={true}
                                        onChange={(
                                            e: React.ChangeEvent<
                                                | HTMLInputElement
                                                | HTMLTextAreaElement
                                            >,
                                        ) => this.onSendMail(e)}
                                    />
                                </FormControl>
                                <Button
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    disabled={isDisabled}
                                    className={classes.submit}
                                    onClick={this.submitSendMail}
                                >
                                    {loadingButton === false && 'Enviar'}
                                    {loadingButton && (
                                        <>
                                            <br />
                                            <center>
                                                <CircularProgress
                                                    size={34}
                                                    className={
                                                        classes.buttonProgress
                                                    }
                                                />
                                            </center>
                                        </>
                                    )}
                                </Button>
                                <br />
                                <br />
                                {this.state.hasToken === '' ? (
                                    <>
                                        <Link href="/register">
                                            ¿No estás registrado? Haz click acá
                                        </Link>
                                        <br />
                                        <Link href="/login">{'Regresar'}</Link>
                                    </>
                                ) : (
                                    <Link href="/">{'Regresar'}</Link>
                                )}
                            </form>
                        </Paper>
                    )}
                {/*Reset password form*/}
                {isResettingPassword &&
                    loading === false &&
                    isRequestingEmail === false &&
                    isSended === false && (
                        <Paper className={classes.paper}>
                            {wasReset && (
                                <div>
                                    <br />
                                    <center>
                                        <i
                                            className={classNames(
                                                'fa fa-send fa-5x',
                                                classes.icon,
                                                classes.success,
                                            )}
                                        />
                                    </center>
                                    <center>
                                        <Typography variant="body1">
                                            {
                                                'Tu contraseña ha sido Re-establecida'
                                            }
                                            <br />
                                            <br />
                                            <Link
                                                className={classes.textColor}
                                                href="/login"
                                            >
                                                Iniciar sesión
                                            </Link>
                                        </Typography>
                                    </center>
                                </div>
                            )}
                            {wasReset === false && (
                                <>
                                    <Avatar
                                        alt="Remax"
                                        src={logo}
                                        className={classes.rounded}
                                    />
                                    <br />
                                    <Typography component="h1" variant="h5">
                                        {'Restablece tu contraseña'}
                                    </Typography>
                                    <form className={classes.form}>
                                        <FormControl
                                            margin="normal"
                                            required={true}
                                            fullWidth={true}
                                            variant="outlined"
                                        >
                                            <TextField
                                                id="outlined-basic"
                                                name="password"
                                                variant="outlined"
                                                type="password"
                                                autoComplete="password"
                                                placeholder="Ingresa tu nueva contraseña"
                                                required
                                                autoFocus={true}
                                                onChange={e => {
                                                    this.setState({
                                                        newPassword:
                                                            e.target.value,
                                                    });
                                                }}
                                            />
                                            <br />
                                            <TextField
                                                id="outlined-basic"
                                                name="confirmPassword"
                                                variant="outlined"
                                                type="password"
                                                placeholder="Ingresa nuevamente"
                                                required
                                                autoComplete="confirmPassword"
                                                autoFocus={true}
                                                onChange={e => {
                                                    this.setState({
                                                        newConfirmPassword:
                                                            e.target.value,
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                        <Button
                                            fullWidth={true}
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            onClick={this.onResetPassword}
                                        >
                                            {loadingButton === false &&
                                                'Enviar'}
                                            {loadingButton && (
                                                <>
                                                    <br />
                                                    <center>
                                                        <CircularProgress
                                                            size={34}
                                                            className={
                                                                classes.buttonProgress
                                                            }
                                                        />
                                                    </center>
                                                </>
                                            )}
                                        </Button>
                                        <br />
                                        <br />
                                        <Link href="/register">
                                            ¿No estás registrado? Haz click acá
                                        </Link>
                                        <br />
                                        <Link href="/login">
                                            {'Iniciar sesión'}
                                        </Link>
                                    </form>
                                </>
                            )}
                        </Paper>
                    )}
                {/*Confirm the request to reset password was sended to email*/}
                {isSended === true &&
                    isResettingPassword === false &&
                    loading === false &&
                    isRequestingEmail === false && (
                        <>
                            <br />
                            <Panel
                                variant={'success'}
                                className={classes.panel}
                            >
                                <br />
                                <div>
                                    <center>
                                        <i
                                            className={classNames(
                                                'fa fa-send fa-4x',
                                                classes.icon,
                                                classes.success,
                                            )}
                                        />
                                    </center>
                                    <center>
                                        <Typography variant="h6">
                                            {
                                                'Te hemos enviado un correo para restablecer'
                                            }
                                            <br />
                                            {'tu contraseña.'}
                                            <br />
                                        </Typography>
                                        <br />
                                        <span style={{ fontSize: '18px' }}>
                                            {intl.get('checkSpam')}
                                        </span>
                                        <br />
                                        <br />
                                        <Link
                                            className={classes.textColor}
                                            href="/login"
                                        >
                                            Volver
                                        </Link>
                                    </center>
                                </div>
                            </Panel>
                        </>
                    )}
            </main>
        );
    }
}

export default withStyles(styles)(ResetPassword);
