import * as React from 'react';
import { Theme, createStyles, withStyles } from '@material-ui/core';
import storage from 'app/helpers/storage';
import { Welcome, WelcomeAdmin } from './welcome-layouts';
import { connect } from 'react-redux';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        contentHeader: {
            display: 'flex',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            marginBottom: theme.spacing(),
            '& h2': {
                flex: 1,
            },
        },
        breadcrumb: {
            display: 'flex',
            '& > *': {
                alignSelf: 'flex-end',
                textDecoration: 'none',
            },
            '& > *:not(:last-child):after': {
                content: '">"',
                display: 'inline-block',
                marginLeft: 0,
                marginRight: 0,
            },
        },
        subTitleWelcome: {
            color: 'red',
        },
    });
class MainPage extends React.Component<{
    classes: any;
    isAdmin: number;
}> {
    public constructor(props) {
        super(props);
    }
    public render() {
        return <>{this.props.isAdmin === 1 ? <WelcomeAdmin /> : <Welcome />}</>;
    }
}
const mapStateToProps = state => ({
    isAdmin: state.global.isAdmin,
});
export default connect(mapStateToProps, null)(withStyles(styles)(MainPage));
