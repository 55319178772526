import { notify } from '@bndynet/dialog';
import storage from 'app/helpers/storage';
import { getConfig } from '../config';
import { Ajax } from '../helpers/ajax';
import { getState } from './auth';
import { EXPIRATION_DATE, checkTimeOut } from './auth';
import ApiConstants from '../config/api.constants';

const refreshSession = () => {
    checkTimeOut();
    storage.set(EXPIRATION_DATE, new Date().getTime() * 1.00000093);
    return;
};

class ResourceService extends Ajax {
    public constructor() {
        const config = getConfig();
        console.debug(config);
        super({
            headerAuthorization: () => {
                refreshSession();
                if (getState().token) {
                    return `${getState().token.token_type} ${
                        getState().token.access_token
                    }`;
                }
                return '';
            },
            baseURL: config.resourceBaseUri,
            headers: {
                'X-API-KEY': ApiConstants.X_API_KEY,
            },
            onResponseError: error => {
                notify(JSON.stringify(error), 'error');
            },
        });
    }
}

export const service = new ResourceService();
