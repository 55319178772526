import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Badge,
    createStyles,
    Theme,
    withStyles,
} from '@material-ui/core';
const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: -15,
            top: -100,
            border: `2px solid ${theme.palette.background.paper}`,
            borderRadius: 200,
            padding: '0 4px',
            width: '40px',
            height: '40px',
        },
    }),
)(Badge);
const useStyles = makeStyles(theme => ({
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: '#cccccc',
        borderStyle: 'dashed',
        backgroundColor: '#e5e5e5',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #cccccc',
        marginBottom: 8,
        marginRight: 8,
        padding: 4,
        boxSizing: 'border-box',
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        height: 180,
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        display: 'block',
        width: '120px',
        height: '120x',
    },
    infoDropzone: {
        fontSize: '1rem',
        color: '#808080',
        textAlign: 'center',
    },
    infoFile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderSpacing: '10px',
        gap: '10px 20px',
        rowGap: '10px',
        columnGap: '20px',
    },
    customerBadge: {
        width: '25px',
        height: '25px',
        background: 'blue',
        color: 'white',
        fontWeight: '500',
        borderRadius: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: ' center',
        margin: ' 0px 10px',
        cursor: 'pointer',
    },
    pdf: {
        width: '120px',
        height: '120x',
        left: '50%',
        right: '50%',
    },
    errorInfo: {
        color: 'red',
        fontSize: '1rem',
        textAlign: 'center',
    },
}));
export default function Dropzone(props: {
    setContract: Function;
    contract: any[];
    files: any[];
    setFiles: Function;
    setBack?: Function;
    fieldsFilled: Function;
}) {
    const classes = useStyles('paper');
    const [files, setFiles] = useState([]);
    const [advice, setAdvice] = useState(false);
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: 'image/*, .pdf',
        maxSize: 9000000,
        maxFiles: 20,
        noDragEventsBubbling: true,
        onDrop: acceptedFiles => {
            if (props.hasOwnProperty('setBack')) {
                props.setBack(false);
            }
            if (
                files.length == 20 &&
                acceptedFiles[0].type.substring(0, 5) === 'image'
            ) {
                setAdvice(true);
                return;
            }
            acceptedFiles.map(file => {
                let dummyFiles = [];
                if (file.type.substring(0, 5) !== 'image') {
                    props.contract.splice(0, props.contract.length);

                    setFiles([
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        }),
                    ]);
                    // setFileType(file.type.substring(0, 5));
                } else {
                    (dummyFiles = files.filter(
                        file => file.type.substring(0, 5) === 'image',
                    )),
                        dummyFiles.push(
                            Object.assign(file, {
                                preview: URL.createObjectURL(file),
                            }),
                        );
                    setFiles(dummyFiles);
                    // setFileType(file.type.substring(0, 5));
                }
            });
            acceptedFiles.forEach(file => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    const fileContents = reader;
                    props.contract.push([file.name, fileContents.result]);
                    props.fieldsFilled();
                };
            });
        },
    });

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    function removeImg() {
        setFiles([]);
        props.setContract([]);
    }
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            <ul>
                {errors.map(e => (
                    <li className={classes.errorInfo} key={e.code}>
                        {
                            'El archivo no debe exceder el tamaño permitido de 9mb por archivo'
                        }
                    </li>
                ))}
            </ul>
        </li>
    ));
    const closeAdvice = () => {
        setAdvice(false);
    };
    const Advice = () => {
        return (
            <Dialog
                open={advice}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ backgroundColor: '#007dc3' }}>
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{ textAlign: 'justify', color: 'white' }}
                    >
                        {'Solo puedes agregar 7 imágenes o un archivo tipo PDF'}
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            style={{ textAlign: 'justify', color: 'white' }}
                            onClick={() => closeAdvice()}
                            autoFocus
                        >
                            Volver
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    };

    const thumbs = files.map((file, index) =>
        file?.type?.substring(0, 5) === 'image' ? (
            <>
                <div className={classes.infoFile} key={file.preview}>
                    <span
                        onClick={() => {
                            setFiles(files.filter((x, i) => i != index));
                            props.setContract(
                                props.contract.filter((x, i) => i != index),
                            );
                        }}
                        className={classes.customerBadge}
                        style={{ background: '#e72828', padding: '10px' }}
                    >
                        X
                    </span>
                    <div className={classes.thumbInner}>
                        <a
                            href={file.preview}
                            style={{
                                color: 'blue',
                                textDecoration: 'none',
                                textAlign: 'center',
                            }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={file.preview} className={classes.img} />
                            {/* {file.path} */}
                        </a>
                    </div>
                    <span className={classes.customerBadge}>
                        {`#${index + 1}`}
                    </span>
                </div>
            </>
        ) : (
            <div className={classes.infoFile} key={file.preview}>
                <div className={classes.thumbInner}>
                    <img
                        src={require('../../../../assets/images/pdf-icon.svg')}
                        className={classes.pdf}
                    />
                </div>
                <a
                    href={file.preview}
                    style={{
                        color: 'blue',
                        textDecoration: 'none',
                        textAlign: 'center',
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    {file.path}
                </a>
            </div>
        ),
    );
    return (
        <>
            <section className={classes.dropzone}>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p className={classes.infoDropzone}>
                        Arrastra o haz clic aquí y selecciona el contrato de
                        alquiler <br />
                        (jpg, jpeg, png o pdf). Tamaño máximo: 9MB.
                    </p>
                </div>
                {fileRejectionItems}
            </section>
            <br />
            {files.length > 0 && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => removeImg()}
                >
                    {'Remover archivos'}
                </Button>
            )}
            {Advice()}
            <aside className={classes.thumbsContainer}>{thumbs}</aside>
        </>
    );
}
