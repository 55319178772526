abstract class ApiConstants {
    public static readonly API_URL: string = process.env.API_URL;
    public static readonly X_API_KEY: string = process.env.API_KEY;

    /* API ENDPOINTS */

    // Public
    public static readonly MAXCORE_SECTORS_ENDPOINT: string =
        this.API_URL + 'provinces/sectors';

    public static readonly PAYMENT_ADMIN_CHANGE_ROLE: string =
        this.API_URL + 'admin/payments/change-role/';
    public static readonly PAYMENT_ADMIN_CHECK_INFO: string =
        this.API_URL + 'admin/payments/check-info/';
    public static readonly PAYMENT_ADMIN_FILL_INFO: string =
        this.API_URL + 'admin/payments/fill-info/';

    // Auth
    public static readonly LOGIN_ENDPOINT: string =
        this.API_URL + 'admin/payments/login';
    public static readonly REGISTER_ENDPOINT: string =
        this.API_URL + 'admin/payments/register';
    public static readonly SEND_RESET_EMAIL_ENDPOINT: string =
        this.API_URL + 'admin/payments/send-reset';
    public static readonly CHECK_USER_ENDPOINT: string =
        this.API_URL + 'admin/payments/check-user';
    public static readonly CHANGE_PASSWORD_ENDPOINT: string =
        this.API_URL + 'admin/payments/change-password';

    // Payment
    public static readonly ACCEPTED_PAYMENT: string =
        this.API_URL + 'admin/payments/accept-payment/';
    public static readonly CANCEL_PAYMENT_ENDPOINT: string =
        this.API_URL + 'admin/payments/cancel-payment/';
    public static readonly CHANGE_PAYMENT_STATUS_ENDPOINT: string =
        this.API_URL + 'admin/payments/change-status/';
    public static readonly GET_PAYMENTS_ENDPOINT: string =
        this.API_URL + 'admin/payments/get';
    public static readonly GET_PAYMENTS_FILTERED_ENDPOINT: string =
        this.API_URL + 'admin/payments/getFiltered';
    public static readonly CONFIRMATION_ENDPOINT: string =
        this.API_URL + 'admin/payments/confirmation';
    public static readonly SAVE_CONFIG_PAYMENT_ENDPOINT: string =
        this.API_URL + 'admin/payments/save-config';
    public static readonly RESEND_CONFIG_PAYMENT_ENDPOINT: string =
        this.API_URL + 'admin/payments/resend-config';
    public static readonly SAVE_PAYMENT_ENDPOINT: string =
        this.API_URL + 'admin/payments/save-transaction';
    public static readonly FETCH_PAYMENT_DETAILS_ENDPOINT: string =
        this.API_URL + 'admin/payments/fetch-payment';
    public static readonly GET_PAYMENTS_DETAILS_ENDPOINT: string =
        this.API_URL + 'admin/payments/get-payment';
    public static readonly GET_PAYMENTS_LINK_ENDPOINT: string =
        this.API_URL + 'admin/payments/get-payment-link';

    //Tenants
    public static readonly GET_TENANT_PAYMENTS_ENDPOINT: string =
        this.API_URL + 'admin/payments/tenant/all';
    public static readonly GET_TENANT_PAYMENTS_FILTERED_ENDPOINT: string =
        this.API_URL + 'admin/payments/tenant/allFiltered';
    public static readonly GET_TENANT_BY_ID_ENDPOINT: string =
        this.API_URL + 'admin/payments/tenant/';

    //owners
    public static readonly GET_OWNER_PAYMENTS_ENDPOINT: string =
        this.API_URL + 'admin/payments/owners/all';
    public static readonly GET_OWNER_PAYMENTS_FILTERED_ENDPOINT: string =
        this.API_URL + 'admin/payments/owners/allFiltered';

    // Properties
    public static readonly ADD_PROPERTY_ENDPOINTS: string =
        this.API_URL + 'admin/payments/properties/create';
    public static readonly GET_OWNER_PROPERTIES_ENDPOINT: string =
        this.API_URL + 'admin/payments/properties/all';
    public static readonly EDIT_PROPERTY_ENDPOINTS: string =
        this.API_URL + 'admin/payments/properties/edit';
    public static readonly ASSIGN_OWNER_PROPERTIES_ENDPOINT: string =
        this.API_URL + 'admin/payments/properties/assign';
    public static readonly ASSIGNED_OWNER_PROPERTIES_ENDPOINT: string =
        this.API_URL + 'admin/payments/properties/properties-assigned';
    public static readonly EDIT_GET_PROPERTY_ENDPOINTS: string =
        this.API_URL + 'admin/payments/properties/edit/get';
    public static readonly PAYMENT_LINK_PROPERTY_ENDPOINTS: string =
        this.API_URL + 'admin/payments/properties/payment-link';
    public static readonly FIND_OWNER_PROPERTIES_ENDPOINT: string =
        this.API_URL + 'admin/payments/properties/owner-properties';

    // Admin
    public static readonly DOWNLOAD_CSV_FROM_API: string =
        this.API_URL + 'admin/payments/allPaymentsCSV';
    public static readonly GET_USERS_PAGINATED_ENDPOINT: string =
        this.API_URL + 'admin/payments/user/get';
    public static readonly FIND_USERS_ENDPOINT: string =
        this.API_URL + 'admin/payments/user/find';
    public static readonly SET_ACTIVATE_PAYMENTS_ENDPOINT: string =
        this.API_URL + 'admin/payments/user/activate';
    public static readonly FIND_PAYMENT_INFO_ENDPOINT: string =
        this.API_URL + 'admin/payments/user/info';
    public static readonly FIND_PROPERTY_INFO_ENDPOINT: string =
        this.API_URL + 'admin/payments/user/properties';
    public static readonly CHECK_IS_USER_ADMIN: string =
        this.API_URL + 'admin/payments/user/admin';
    public static readonly CHECK_IS_USER_CAN_PAY: string =
        this.API_URL + 'admin/payments/user/can-pay';
    /* API ENDPOINTS */
}

export default ApiConstants;
