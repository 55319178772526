import React, { CSSProperties, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import esLocale from 'date-fns/locale/es';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Icon, InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { Dropzone } from './components';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 190,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

interface OptionType {
    label: string;
    value: string;
}

const colourStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: '#ffffff',
        cursor: 'pointer',
    }),
    option: () => {
        return {
            backgroundColor: '#ffffff',
            cursor: 'pointer',
        };
    },
};

export default function PropertyForm(props) {
    const [citiesData, setCitiesData] = React.useState([]);
    const [sectorsData, setSectorsData] = React.useState([
        {
            label: '',
            value: '',
        },
    ]);
    const [activeSector, setActiveSector] = React.useState(false);
    const theme = useTheme();
    const selectStyles = {
        input: (base: CSSProperties) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };
    function fieldsFilled() {
        if (
            props.sector !== '' &&
            props.city !== '' &&
            props.propertyType !== '' &&
            props.address !== '' &&
            props.parking !== '' &&
            props.bathrooms !== '' &&
            props.bedrooms !== '' &&
            props.construction !== '' &&
            props.revenue !== null &&
            props.contract.length > 0 &&
            props.files.length > 0
        ) {
            props.setStepButton(false);
        } else {
            props.setStepButton(true);
        }
    }

    useEffect(() => {
        async function getCitiesData() {
            setCitiesData(
                props.sectors.map(({ id, name }) => ({
                    id: id,
                    city: name,
                })),
            );
        }
        fieldsFilled();
        getCitiesData();
    }, [fieldsFilled()]);

    useEffect(() => {
        if (props.contract.length > 0 && props.files.length > 0) {
            fieldsFilled();
        }
    }, [props.files]);

    function handleSelect(e) {
        props.setCity(e);
        props.setSector('Cargando...');
        setActiveSector(false);
        setTimeout(function() {
            props.sectors.map(data => {
                if (data.name === e.value) {
                    const cities: OptionType[] = data.cities[0].sectors.map(
                        data => ({
                            value: data.name,
                            label: data.name,
                        }),
                    );
                    //Uses to organize alphabetically the select options
                    cities.sort(function(a, b) {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    });
                    setSectorsData(cities);
                }
            });
            setActiveSector(true);
        }, 500);
    }

    function handleValidations(name, value) {
        switch (name) {
            case 'parking':
                if (value.match(/^[0-9]*$/)) {
                    props.setParking(value);
                }
                break;
            case 'bedrooms':
                if (value.match(/^[0-9]*$/)) {
                    props.setBedrooms(value);
                }
                break;

            default:
                break;
        }
    }

    const cities: OptionType[] = citiesData.map(element => ({
        value: element.city,
        label: element.city,
    }));

    const propertyType: OptionType[] = [
        {
            value: 'Apartamento',
            label: 'Apartamento',
        },
        {
            value: 'Casa',
            label: 'Casa',
        },

        {
            value: 'Local Comercial',
            label: 'Local Comercial u Oficina',
        },
    ];

    const localeMap = {
        es: esLocale,
    };
    const RenderSectorsSelect = () => {
        return (
            <Grid item xs={12} md={6}>
                <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                <Select
                    inputId="react-select-single"
                    styles={colourStyles}
                    TextFieldProps={{
                        label: 'Sector',
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    value={props.sector}
                    onChange={values => props.setSector(values)}
                    options={sectorsData}
                    placeholder="Escoge un sector"
                />
            </Grid>
        );
    };
    const RenderCitySelect = () => {
        return (
            <Grid item xs={12} md={6}>
                <InputLabel id="demo-simple-select-label">Ciudad</InputLabel>
                <Select
                    inputId="react-select-single"
                    styles={colourStyles}
                    TextFieldProps={{
                        label: 'Ciudad',
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    value={props.city}
                    onChange={e => handleSelect(e)}
                    options={cities}
                    placeholder="Escoge una ciudad"
                />
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Información de la propiedad
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <InputLabel id="demo-simple-select-label">
                        Tipo de Propiedad
                    </InputLabel>
                    <Select
                        id="demo-simple-select"
                        styles={colourStyles}
                        value={props.propertyType}
                        onChange={values => props.setPropertyType(values)}
                        options={propertyType}
                        placeholder="Escoge un tipo"
                    />
                </Grid>
                {RenderCitySelect()}
                {activeSector && RenderSectorsSelect()}
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="meters"
                        type="number"
                        label="MTS² de construcción"
                        fullWidth
                        autoComplete="cc-meters"
                        value={props.construction}
                        onChange={e => props.setConstruction(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="bedrooms"
                        label="Habitaciones"
                        fullWidth
                        autoComplete="cc-bedrooms"
                        value={props.bedrooms}
                        onChange={e =>
                            handleValidations('bedrooms', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="bathrooms"
                        type="number"
                        label="Baños"
                        fullWidth
                        autoComplete="cc-bathrooms"
                        value={props.bathrooms}
                        onChange={e => props.setBathrooms(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        id="parking"
                        label="Parqueos"
                        fullWidth
                        autoComplete="cc-parking"
                        value={props.parking}
                        onChange={e =>
                            handleValidations('parking', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginBottom: '5px' }}
                    >
                        Dirección
                    </InputLabel>
                    <TextField
                        id="address"
                        required={true}
                        variant="outlined"
                        name="address"
                        multiline
                        fullWidth={true}
                        rows={5}
                        value={props.address}
                        onChange={e => props.setAddress(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Contrato de alquiler
                    </Typography>
                    <Dropzone
                        setContract={props.setContract}
                        contract={props.contract}
                        fieldsFilled={fieldsFilled}
                        setFiles={props.setFiles}
                        files={props.files}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Fecha de renovación de contrato
                    </Typography>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            style={{ cursor: 'pointer' }}
                            locale={localeMap['es']}
                        >
                            <DatePicker
                                disablePast
                                required
                                openTo="year"
                                views={['year', 'month', 'date']}
                                value={props.revenue}
                                style={{ cursor: 'pointer' }}
                                format="dd/MM/yyyy"
                                label="Escoge la fecha"
                                id="date-picker-inline"
                                onChange={e => props.setRevenue(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <Icon>
                                                <CalendarToday />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
