import * as React from 'react';
import { isClassic } from 'app/theme';
import { default as Header } from './Header';
import { default as Sidebar } from './Sidebar';

interface FrameProps {
    onSidebarToggle?: (open: boolean) => void;
    setCloseMenu?: (close: any) => void;
}

interface FrameState {
    sidebarOpen: boolean;
    userInfo: unknown;
}

class Frame extends React.Component<FrameProps, FrameState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            sidebarOpen: window.innerWidth <= 959 ? false : true,
            userInfo: null,
        };
        this.setCloseMenu = this.setCloseMenu.bind(this);
    }
    private setCloseMenu(close) {
        this.setState({
            sidebarOpen: close,
        });
    }
    public render() {
        const hasHeader = isClassic();
        return (
            <div>
                {hasHeader && (
                    <Header
                        hideBrand={!this.state.sidebarOpen}
                        onToggleClick={this.handleSidebarToggle}
                    />
                )}
                <Sidebar
                    open={this.state.sidebarOpen}
                    onToggleClick={this.handleSidebarToggle}
                    setCloseMenu={this.setCloseMenu}
                    userMissingInfo={
                        this.state.userInfo !== null
                            ? this.state.userInfo
                            : null
                    }
                />
            </div>
        );
    }

    private handleSidebarToggle = () => {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen,
        });
    };
}

export default Frame;
