import * as React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import globo from '/assets/images/globo.png';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import {
    TextField,
    Link,
    CircularProgress,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Toolbar,
    Dialog,
    DialogContent,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { red, green } from '@material-ui/core/colors';
import { themeConfig } from 'app/theme';
import storage from 'app/helpers/storage';
import { InfoStates } from './types/MissingInfoTypes';
import { publicService } from 'app/service/public';
import classNames from 'classnames';
import BankAccountInput from './components/BankAccountInput';
import BasicUserInputs from './components/BasicUserInputs';
import dialogBackground from '../../../assets/images/owner-dialog-background.jpg';

const styles = (theme: Theme) =>
    createStyles({
        main: {
            width: 'auto',
            display: 'block',
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(6))]: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: `${theme.spacing(2)}px ${theme.spacing(
                3,
            )}px ${theme.spacing(3)}px`,
        },
        avatar: {
            margin: theme.spacing(),
            backgroundColor: theme.palette.secondary.main,
            width: 100,
            height: 100,
            fontSize: 110,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(),
        },
        submit: {
            marginTop: theme.spacing(3),
        },
        icon: {
            marginBottom: 30,
        },
        success: {
            color: themeConfig.palette.success,
        },
        rounded: {
            width: '25%',
            height: '25%',
        },
        textColor: {
            color: 'blue',
        },
        buttonProgress: {
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        dialogPaper: {
            [theme.breakpoints.down(996)]: {
                maxWidth: '426px',
                minHeight: '264px',
            },
        },
        fieldTitles: {
            fontSize: '16px',
            color: 'red',
            marginBottom: '20px',
            marginTop: '60px',
        },
        centerSuccess: {
            textAlign: 'center',
        },
        back: {
            marginTop: '2%',
        },
        title: {
            fontSize: '2rem',
        },
        link: {
            fontSize: '1.25rem',
            fontFamily: 'Roboto, Helvetica',
            marginLeft: '30px',
            fontWeight: 400,
            [theme.breakpoints.down(996)]: {
                fontSize: '0.95rem',
                fontFamily: 'Roboto, Helvetica',
                fontWeight: 400,
                marginLeft: '10px',
            },
            [theme.breakpoints.down(421)]: {
                fontSize: '0.70rem',
                textAlign: 'center',
                marginLeft: '13%',
            },
            [theme.breakpoints.down(380)]: {
                marginLeft: '7%',
                fontSize: '0.75rem',
            },
        },
        dialogError: {
            textAlign: 'center',
        },
        avatarFailure: {
            backgroundColor: red[400],
            color: 'white',
            textAlign: 'center',
            marginTop: 10,
            width: 120,
            height: 120,
            margin: '0 auto',
        },
        dialog: {
            backgroundImage: `url("${dialogBackground}")`,
            backgroundSize: '960px 540px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            overflow: 'hidden',
            height: '540px',
            [theme.breakpoints.down(996)]: {
                backgroundImage: `url("${dialogBackground}")`,
                backgroundSize: '448px 281px',
                height: '31vh',
                minHeight: '281px',
            },
            [theme.breakpoints.down(519)]: {
                backgroundImage: `none`,
            },
            [theme.breakpoints.down(420)]: {
                backgroundImage: `none`,
            },
        },
        typographyOne: {
            fontSize: '2.30rem',
            fontFamily: 'Roboto, Helvetica',
            fontWeight: 'bold',
            marginLeft: '30px',
            [theme.breakpoints.down(996)]: {
                fontSize: '1rem',
                marginTop: '20px',
                marginLeft: '10px',
            },
            [theme.breakpoints.down(421)]: {
                marginLeft: '0px',
                fontSize: '1.2rem',
                textAlign: 'center',
            },
        },
        typographyTwo: {
            marginTop: '70px',
            fontSize: '1.90rem',
            fontFamily: 'Roboto, Helvetica',
            marginLeft: '30px',
            fontWeight: 400,
            [theme.breakpoints.down(996)]: {
                marginTop: '40px',
                fontSize: '1rem',
                marginLeft: '10px',
            },
            [theme.breakpoints.down(421)]: {
                marginTop: '0px',
                fontSize: '0.90rem',
                textAlign: 'center',
            },
        },
        backButton: {
            marginLeft: '30px',
            borderRadius: '25px',
            width: '100px',
            border: '3px solid #007dc3',
            color: '#007dc3',
            [theme.breakpoints.down(996)]: {
                width: '245px',
                border: '2px solid #007dc3',
                marginLeft: '10px',
            },
            [theme.breakpoints.down(421)]: {
                left: '4%',
                marginBottom: '5px',
            },
            [theme.breakpoints.down(380)]: {
                left: '-4%',
            },
        },
        backButtonProperties: {
            marginLeft: '30px',
            borderRadius: '25px',
            width: '245px',
            border: '3px solid #007dc3',
            color: '#007dc3',
            [theme.breakpoints.down(996)]: {
                width: '245px',
                border: '2px solid #007dc3',
                marginLeft: '10px',
                marginBottom: '5px',
                marginTop: '-20px',
            },
            [theme.breakpoints.down(421)]: {
                left: '4%',
            },
            [theme.breakpoints.down(380)]: {
                left: '-4%',
            },
        },
    });

class MissingInfo extends React.Component<InfoStates> {
    public constructor(props) {
        super(props);
        this.state = {
            missingFields: Object.values(storage.get('missing_info').data),
            userId: storage.get('user')?.id,
            loadingButton: false,
            isPassport: false,
            documentType: '',
            document: '',
            isDisabled: true,
            isSuccess: false,
            bankAccount: '',
            /*Bank info*/
            bankAccountType: '',
            bank: 'Ahorros',
            handleAccountType: '',
            /*Owner's basic info*/
            tel: '',
            telOpt: '',
            address: '',
            fieldsVerified: false,
            isTenant: true,
            isFilled: false,

            /*validatons*/
            documentValidation: false,
            documentTypeValidation: false,
            bankAccountValidation: false,
            bankTypeValidation: false,
            accountNumberValidation: false,
            telValidation: false,
            addressValidation: false,
        };
        this.handleTel = this.handleTel.bind(this);
        this.handleTelOpt = this.handleTelOpt.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.onSendMissingInfo = this.onSendMissingInfo.bind(this);
        this.handleAccountNumber = this.handleAccountNumber.bind(this);
        this.handleAccountType = this.handleAccountType.bind(this);
        this.handleBank = this.handleBank.bind(this);
    }

    public checkIfEmpty() {
        if (storage.get('user')?.user_type === 2) {
            if (this.state.missingFields[0].includes('id_identity')) {
                return !(
                    this.state.documentValidation &&
                    this.state.documentTypeValidation &&
                    this.state.bankAccountValidation &&
                    this.state.bankTypeValidation &&
                    this.state.accountNumberValidation &&
                    this.state.addressValidation
                );
            }
        } else {
            return !(
                this.state.documentValidation &&
                this.state.documentTypeValidation &&
                this.state.addressValidation
            );
        }
    }
    // public async checkIfValidated() {
    public componentDidMount() {
        storage.get('user') === null ? this.props.history.push('/') : '';
        if (storage.get('user')?.user_type !== 1) {
            this.setState({ isTenant: false });
        }
    }
    public async onSendMissingInfo() {
        this.setState({
            loadingButton: true,
            isSuccess: true,
        });
        let inputs = [
            {
                document: this.state.missingFields[0].includes('id_identity')
                    ? this.state.document
                    : '',
                documentType:
                    this.state.documentType === 'Cédula' ? 'local' : 'passport',
                bank: this.state.bank,
                bankAccount: this.state.bankAccount,
                bankAccountType: this.state.bankAccountType,
                telOpt: this.state.telOpt,
                address: this.state.address,
            },
        ];

        const filledMissingInfo = await publicService.fillUserInfoRequirements(
            inputs,
            this.state.userId,
        );
        if (filledMissingInfo.data.data) {
            let user = storage.get('user');
            user.id_identity = this.state.document
                .replace('-', '')
                .replace('-', '');
            storage.set('user', user);
            storage.set('missing_info', []);
            this.setState({
                loadingButton: false,
                isDisabled: false,
                isSuccess: true,
            });
        }
    }
    public handleDocument(value, name) {
        this.setState({
            document: value,
        });
        if (name === 'passport') {
            if (value !== '') {
                this.setState({
                    documentValidation: true,
                });
            } else {
                this.setState({
                    documentValidation: false,
                });
            }
        } else if (name === 'local') {
            if (
                value !== '' &&
                value !== '___-_______-_' &&
                value.replace(/\D/g, '').length === 11
            ) {
                this.setState({
                    documentValidation: true,
                });
            } else {
                this.setState({
                    documentValidation: false,
                });
            }
        }
    }
    /*Account number*/
    public handleAccountNumber(value) {
        if (value.match(/^[0-9]*$/)) {
            this.setState({
                bankAccount: value,
            });
        }
        if (value !== '') {
            this.setState({
                accountNumberValidation: true,
            });
        } else {
            this.setState({
                accountNumberValidation: false,
            });
        }
    }
    /*Account number*/
    public handleAccountType(value) {
        this.setState({
            bankAccountType: value,
        });
        if (value !== '') {
            this.setState({
                bankTypeValidation: true,
            });
        } else {
            this.setState({
                bankTypeValidation: false,
            });
        }
    }
    /*Account number*/
    public handleBank(value) {
        this.setState({
            bank: value,
        });
        if (value !== '') {
            this.setState({
                bankAccountValidation: true,
            });
        } else {
            this.setState({
                bankAccountValidation: false,
            });
        }
    }
    /*Tel */
    public handleTel(value) {
        if (value.match(/^[0-9]*$/)) {
            this.setState({
                tel: value,
            });
        }
        if (value !== '' && value.length === 10) {
            this.setState({
                telValidation: true,
            });
        } else {
            this.setState({
                telValidation: false,
            });
        }
    }

    /*Address */
    public handleAddress(value) {
        this.setState({
            address: value,
        });
        if (value !== '') {
            this.setState({
                addressValidation: true,
            });
        } else {
            this.setState({
                addressValidation: false,
            });
        }
    }

    /*Tel Opt */
    public handleTelOpt(value) {
        this.setState({
            telOpt: value,
        });
    }

    public setDocumentType(value) {
        this.setState({ document: '' });
        if (value === 'Pasaporte') {
            this.setState({ isPassport: true });
            this.setState({
                documentType: 'Pasaporte',
            });
        } else if (value === 'Cédula') {
            this.setState({
                isPassport: false,
                documentType: 'Cédula',
            });
        }
        this.setState({
            documentTypeValidation: true,
        });
    }
    public render() {
        const { classes } = this.props;
        //Modal rendering
        const {
            missingFields /*array with all missing fields */,
            loadingButton /*loading button */,
            isPassport,
            document,
            isSuccess,
        } = this.state;
        return (
            <main className={classes.main}>
                <CssBaseline />
                {storage.get('token') && (
                    <AppBar
                        position="absolute"
                        color="default"
                        className={classes.appBar}
                    >
                        <Toolbar>
                            <Link
                                href="#"
                                onClick={() => (window.location.href = '/')}
                            >
                                {'   '}
                                <ArrowBackIcon />
                                Volver al Inicio
                            </Link>
                        </Toolbar>
                    </AppBar>
                )}
                {!this.state.isSuccess && (
                    <Paper className={classes.paper}>
                        <img
                            alt="Remax"
                            src={globo}
                            className={classes.rounded}
                        />
                        <br />
                        <Typography
                            // component="h2"
                            // variant="h6"
                            classes={classes.title}
                            gutterBottom
                        >
                            {'Completa los campos faltantes'}
                        </Typography>
                        <div className={classes.form}>
                            {missingFields[0].includes('id_identity') && (
                                <>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        className={classes.fieldTitles}
                                    >
                                        No. de identificación
                                    </InputLabel>
                                    <Grid container>
                                        <Grid item xs={12} sm={4}>
                                            <InputLabel id="demo-simple-select-label">
                                                Documento *
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                id="demo-simple-select"
                                                value={this.state.documentType}
                                                onChange={e =>
                                                    this.setDocumentType(
                                                        e.target.value,
                                                    )
                                                }
                                            >
                                                <MenuItem value={'Cédula'}>
                                                    Cédula
                                                </MenuItem>
                                                <MenuItem value={'Pasaporte'}>
                                                    Pasaporte
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid xs={1}></Grid>
                                        <Grid item xs={12} sm={7}>
                                            {isPassport ? (
                                                <TextField
                                                    required
                                                    id="cardlastName"
                                                    label="No. de pasaporte"
                                                    fullWidth
                                                    autoComplete="cc-id"
                                                    value={document}
                                                    onChange={(
                                                        e: React.ChangeEvent<
                                                            HTMLInputElement
                                                        >,
                                                    ) =>
                                                        this.handleDocument(
                                                            e.target.value,
                                                            'passport',
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <InputMask
                                                    mask="999-9999999-9"
                                                    value={document}
                                                    onChange={(
                                                        e: React.ChangeEvent<
                                                            HTMLInputElement
                                                        >,
                                                    ) =>
                                                        this.handleDocument(
                                                            e.target.value,
                                                            'local',
                                                        )
                                                    }
                                                >
                                                    {() => (
                                                        <TextField
                                                            required
                                                            id="cardlastName"
                                                            label="No. de cédula"
                                                            fullWidth
                                                            autoComplete="cc-id"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {missingFields[0].includes('bank_account') &&
                                !this.state.isTenant && (
                                    <>
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            className={classes.fieldTitles}
                                        >
                                            Datos bancarios para recibir el pago
                                        </InputLabel>
                                        <BankAccountInput
                                            handleAccountNumber={
                                                this.handleAccountNumber
                                            }
                                            bank={this.state.bank}
                                            bankAccount={this.state.bankAccount}
                                            bankAccountType={
                                                this.state.bankAccountType
                                            }
                                            handleBank={this.handleBank}
                                            handleAccountType={
                                                this.handleAccountType
                                            }
                                        />
                                    </>
                                )}
                            {missingFields[0].includes('basic_info') && (
                                <>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        className={classes.fieldTitles}
                                    >
                                        Información de contacto
                                    </InputLabel>
                                    <BasicUserInputs
                                        handleAccountNumber={
                                            this.handleAccountNumber
                                        }
                                        telOpt={this.state.telOpt}
                                        address={this.state.address}
                                        handleAddress={this.handleAddress}
                                        handleTelOpt={this.handleTelOpt}
                                    />
                                </>
                            )}
                        </div>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            disabled={this.checkIfEmpty()}
                            className={classes.submit}
                            onClick={this.onSendMissingInfo}
                        >
                            {loadingButton === false && 'Enviar'}
                            {loadingButton && (
                                <>
                                    <br />
                                    <center>
                                        <CircularProgress
                                            size={34}
                                            className={classes.buttonProgress}
                                        />
                                    </center>
                                </>
                            )}
                        </Button>
                    </Paper>
                )}
                <Dialog
                    open={this.state.isSuccess}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    PaperProps={{ classes: { root: classes.dialogPaper } }}
                    maxWidth={'md'}
                >
                    <DialogContent className={classes.dialog}>
                        <h3 className={classes.typographyTwo}>
                            Información completada <br /> correctamente.
                        </h3>
                        <h3 className={classes.typographyOne}>
                            ¡Ya puedes registrar tus <br />
                            propiedades!
                        </h3>
                        <br />
                        <Button
                            className={classes.backButtonProperties}
                            onClick={() =>
                                this.props.history.push('/registrar-propiedad')
                            }
                            variant="outlined"
                        >
                            Registra tus propiedades
                        </Button>
                        <Button
                            className={classes.backButton}
                            onClick={() => this.props.history.push('/')}
                            variant="outlined"
                        >
                            Regresar
                        </Button>
                    </DialogContent>
                </Dialog>
            </main>
        );
    }
}

export default withStyles(styles)(MissingInfo);
