import * as React from 'react';
import { loading } from '@bndynet/dialog';
import { service as resourceService } from 'app/service/resource';
import {
    DataTable,
    PageHeader,
    DataTableRequestParameters,
    DataTablePageMeta,
} from 'app/ui';
import utils from 'app/helpers/utils';
import storage from 'app/helpers/storage';
import { ApiConstants } from 'app/config';
import { createStyles, withStyles } from '@material-ui/styles';
import { AppTheme } from 'app/theme';
import MobileCardHistory from './cardHistory';
export interface DataTableProps {
    rows: [];
    data: any;
}
const styles = (theme: AppTheme) =>
    createStyles({
        container: {
            padding: '0px 10px',

            '& tr td': {
                height: 'auto',
            },
        },
    });
class TenantsHistory extends React.Component<DataTableProps> {
    public constructor(props) {
        super(props);
        this.state = {
            rows: [],
            data: null,
        };
    }
    public render() {
        const { classes } = this.props;

        return (
            <>
                {window.innerWidth > 650 && (
                    <div className={classes.container}>
                        <PageHeader title="" />
                        <DataTable
                            title="Historial de Pago"
                            dataPromise={this.tableDataPayments}
                            dataPromiseFiltered={this.tableDataPaymentsFiltered}
                            scrollable={true}
                            haveFilters={false}
                            rowsPerPageOptions={[8]}
                        />
                        <br />
                    </div>
                )}
                {window.innerWidth < 650 && this.state.data && (
                    <MobileCardHistory
                        data={this.state.data}
                        handleLoadData={e => this.loadData(e)}
                        titleLabel="Historial de Pago"
                        headerHorizontalProps={[
                            { title: 'ID', propsRef: 'ID' },
                            {
                                title: 'Fecha',
                                propsRef: 'Fecha',
                            },
                            {
                                title: 'Transacción',
                                propsRef: 'Número de Factura',
                            },
                        ]}
                        VerticalSumaryProps={['Nombre Propietario', 'Monto']}
                    />
                )}
            </>
        );
    }

    componentDidMount = () => {
        if (window.innerWidth < 650) {
            this.loadData();
        }
    };

    public async loadData(args?: DataTableRequestParameters) {
        const respose = await this.tableDataPayments(args);
        this.setState({
            rows: [],
            data: respose,
        });
    }

    public tableDataPayments(args: DataTableRequestParameters): Promise<any> {
        let endpoint = ApiConstants.GET_TENANT_PAYMENTS_ENDPOINT;
        if (args) {
            endpoint += `?page=${args.page || 1}`;
            endpoint += `&pageSize=${args.pageSize || 8}`;

            if (args.sort) {
                endpoint += `&sort=${args.sort}&sortDirection=${args.sortDirection}`;
            }
            if (args.searchText) {
                endpoint += `&search=${args.searchText}`;
            }
        }

        const ajax = resourceService
            .post(endpoint, {
                id: storage.get('user').id,
                email: storage.get('user').email,
            })
            .then((res: any) => {
                const result: DataTablePageMeta = {
                    data: res.data,
                    page: (args && args.page) || 1,
                    count: res.count,
                };
                return result;
            });

        const promiseLoading = new Promise(resolve => {
            loading();
            setTimeout(() => {
                loading(false);
                resolve();
            }, 3000);
        });

        return utils
            .delay(3, ajax, promiseLoading)
            .then((values: any[]) => values[0]);
    }

    private tableDataPaymentsFiltered(
        args: DataTableRequestParameters,
        column,
    ): Promise<any> {
        let endpoint = ApiConstants.GET_TENANT_PAYMENTS_FILTERED_ENDPOINT;
        if (args) {
            endpoint += `?page=${args.page || 1}`;
            endpoint += `&pageSize=${args.pageSize || 8}`;

            if (args.sort) {
                endpoint += `&sort=${args.sort}&sortDirection=${args.sortDirection}`;
            }
            if (args.searchText) {
                endpoint += `&search=${args.searchText}`;
            }
            if (column) {
                endpoint += `&column=${column}`;
            }
        }

        const ajax = resourceService
            .post(endpoint, {
                id: storage.get('user').id,
                email: storage.get('user').email,
            })
            .then((res: any) => {
                const result: DataTablePageMeta = {
                    data: res.data,
                    page: (args && args.page) || 1,
                    count: res.count,
                };
                return result;
            });
        const promiseLoading = new Promise(resolve => {
            loading();
            setTimeout(() => {
                loading(false);
                resolve();
            }, 3000);
        });

        return utils
            .delay(3, ajax, promiseLoading)
            .then((values: any[]) => values[0]);
    }
}

export default withStyles(styles)(TenantsHistory);
