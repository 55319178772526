/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import * as intl from 'react-intl-universal';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Dialog from '@material-ui/core/Dialog';
import Zoom from '@material-ui/core/Zoom';
import { TransitionProps } from '@material-ui/core/transitions';
import { Dispatch, Action } from 'redux';
import {
    AppBar,
    IconButton,
    Toolbar,
    Avatar,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Theme,
    createStyles,
    withStyles,
    withWidth,
    Popover,
    FormControlLabel,
    Switch,
    Grid,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { HorizontalMenu } from 'app/ui';
import { themeConfig } from 'app/theme';
import { getConfig } from 'app/config';
import { actions as globalActions } from 'app/service/global';
import { publicService } from '../../../service/public';
import storage from 'app/helpers/storage';
interface HeaderProps {
    user?: any;
    classes?: any;
    history?: any;
    isDarkTheme?: boolean;
    width?: Breakpoint;
    push?: (path: string) => void;
    onThemeChange?: (toDark: boolean) => void;
    onToggleClick?: () => void;
    onChangeRole?: (role) => void;
    hideBrand?: boolean;
    role: string;
    isAdmin: number;
}

interface HeaderState {
    avatarPopupAnchor: any;
    sidePanelOpen: boolean;
    checkedB: boolean;
    open: boolean;
    roleText: string;
    colorDialog: string;
    role: string;
}
const styles = (theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#ffffff',
            flexDirection: 'row',
            $IconButton: {
                borderRadius: 0,
            },
        },
        menuButton: {
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
            color: '#003DA5',
            borderRadius: 0,
            // minWidth: 45,
            // width: themeConfig.sidebarWidthMini,
            marginRight: '0%',
            marginLeft: '1%',
            [theme.breakpoints.down(500)]: {},
        },
        menuButtonHidden: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inherit',
            },
        },
        roles: {
            alignItems: 'stretch',
            display: 'flex',
            marginLeft: -185,
        },
        brandTitle: {
            color: '#ffffff',
            width: '144px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',

            fontSize: 22,
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                '& img': {
                    width: '6em !important',
                },
                // display: 'none',
            },
            '&.hidden': {
                // display: 'none',
            },
            '& img': {
                width: '100%',
            },
        },
        toolbar: {
            flex: 'inherit',
            minHeight: themeConfig.headerHeight,
            paddingRight: 0,
            display: 'flex',
            alignItems: 'stretch',
        },
        avatar: {
            width: 30,
            height: 30,
            backgroundColor: theme.palette.grey[100],
            color: '#003DA5',
        },
        bigAvatar: {
            width: 100,
            height: 100,
            margin: '0 auto 5px auto',
            fontSize: 70,
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.primary.main,
        },
        avatarPopup: {
            minWidth: 200,
            maxHeight: '50vh',
        },
        iconButton: {
            padding: theme.spacing(),
            color: theme.palette.primary.main,
        },
        badge: {
            fontSize: '11px',
            right: 5,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
        },
        roleContainer: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
        },
        roleLabel: {
            color: 'black',
            marginTop: '20px',
            paddingLeft: theme.spacing(),
            paddingRight: theme.spacing(),
        },
        dialog: {
            width: '100vw',
            height: '100vh',
            opacity: 0.9,
        },
        roleContent: {
            backgroundColor: '#FFF',
            height: '15vh',
            opacity: 0.2,
            width: '100vw',
            top: '40%',
            position: 'absolute',
            textAlign: 'center',
        },
    });
const IOSSwitch = withStyles(theme => ({
    root: {
        width: 55,
        height: 26,
        padding: 0,
        margin: theme.spacing(),
        flex: 'inherit',
        paddingRight: 0,
        display: 'flex',
        alignItems: 'stretch',
        marginLeft: '40px',
    },
    switchBase: {
        padding: 1,
        backgroundColor: '#DC1C2E',
        opacity: 1,
        border: 'none',
        '&$checked': {
            transform: 'translateX(29px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#DC1C2E',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#DC1C2E',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: '#003DA5',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
    },
    focusVisible: {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
    },
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
class Header extends React.PureComponent<HeaderProps, HeaderState> {
    // public isDev = process.env.NODE_ENV != 'production';
    public isDev = true;

    public constructor(props: HeaderProps) {
        super(props);
        this.state = {
            avatarPopupAnchor: null,
            sidePanelOpen: false,
            open: false,
            role: this.props.role,
            colorDialog: '',
            checkedB: storage.get('user')?.user_type === 1 ? true : false,
            roleText:
                storage.get('user')?.user_type === 1
                    ? 'inquilino'
                    : 'propietario',
        };
        this.handleClose = this.handleClose.bind(this);

        // eslint-disable-next-line no-console
    }

    public componentDidMount() {
        let roleId = storage.get('user')?.user_type;
        if (!storage.get('showRoleOnLogin')) {
            switch (roleId) {
                case 1:
                    this.setState({ colorDialog: '#DC1C2E' });
                    this.setState({ open: true });
                    setTimeout(() => {
                        this.setState({ open: false });
                    }, 1700);
                    this.setState({
                        roleText: 'inquilino',
                    });
                    break;
                case 2:
                    this.setState({ colorDialog: '#003DA5' });
                    this.setState({ open: true });
                    setTimeout(() => {
                        this.setState({ open: false });
                    }, 1700);
                    this.setState({
                        roleText: 'propietario',
                    });
                    break;
                default:
                    break;
            }
            storage.set('showRoleOnLogin', true);
        }
    }
    private Transition = React.forwardRef(function Transition(
        props: TransitionProps & { children?: React.ReactElement },
    ) {
        return <Zoom in={true} {...props} />;
    });
    private handleChange = (name: string) => async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        this.setState({ ...this.state, [name]: event.target.checked });
        const user = storage.get('user');
        const id = user.id;
        if (this.state.checkedB) {
            this.setState({ colorDialog: '#003DA5' });
            await publicService.changeUserRole(2, id);
            user.user_type = 2;
            storage.set('user', user);
            setTimeout(() => {
                this.props.onChangeRole(2);
                this.props.push('/');
            }, 700);
            this.showRoleDialog();
            this.setState({
                roleText: 'propietario',
            });
        } else {
            await publicService.changeUserRole(1, id);
            user.user_type = 1;
            storage.set('user', user);
            this.setState({ colorDialog: '#DC1C2E' });
            setTimeout(() => {
                this.props.onChangeRole(1);
                this.props.push('/');
            }, 700);
            this.props.push('/');
            this.showRoleDialog();
            this.setState({
                roleText: 'inquilino',
            });
        }
    };
    private showRoleDialog = () => {
        this.setState({ open: true });
        setTimeout(() => {
            this.setState({ open: false });
        }, 700);
    };

    public handleClose = () => {
        this.setState({ open: false });
    };
    public render() {
        const config = getConfig();
        const { classes, hideBrand } = this.props;

        const { avatarPopupAnchor } = this.state;
        const user = storage.get('user') || {};
        return (
            <AppBar position="absolute" className={classes.appBar}>
                <Link
                    to="/"
                    className={classNames('clickable', classes.brandTitle, {
                        hidden: hideBrand,
                    })}
                >
                    <img
                        src={config.logoUri}
                        // style={
                        //     {
                        //         // maxHeight: '49px',
                        //         // width: '100%',
                        //         // marginRight: '30%',
                        //     }
                        // }
                    />
                </Link>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleToggleClick}
                    className={classNames(classes.menuButton)}
                >
                    <>
                        <i
                            className={classNames(
                                'fa fa-bars animated',
                                hideBrand && 'rotateIn',
                            )}
                        />
                    </>
                </IconButton>
                <HorizontalMenu data={[]} />
                {window.innerWidth > 468 &&
                    this.isDev &&
                    storage.get('user')?.is_admin === 0 && (
                        <div className={classNames(classes.roles)}>
                            <Grid
                                item
                                className={classNames(classes.roleLabel)}
                            >
                                Propietario
                            </Grid>
                            <FormControlLabel
                                control={
                                    <IOSSwitch
                                        checked={this.state.checkedB}
                                        className={classNames(classes.switch)}
                                        name="checkedB"
                                        onChange={this.handleChange('checkedB')}
                                    />
                                }
                            />
                            <Grid
                                item
                                className={classNames(classes.roleLabel)}
                            >
                                Inquilino
                            </Grid>
                        </div>
                    )}
                {(this.isDev ? window.innerWidth < 468 : true) &&
                    storage.get('user')?.is_admin === 0 && (
                        <div className={classNames(classes.roles)}>
                            <Grid
                                item
                                style={{
                                    width: 'auto',
                                    background:
                                        this.props.role == 1
                                            ? 'rgb(220, 28, 46)'
                                            : '#3f51b5',
                                    height: 'min-content',
                                    padding: '2px 10px',
                                    borderRadius: '15px',
                                    color: 'white',
                                    fontWeight: '600',
                                }}
                                className={classNames(classes.roleLabel)}
                            >
                                {this.props.role == 1
                                    ? 'Inquilino'
                                    : 'Propietario'}
                            </Grid>
                        </div>
                    )}

                <Toolbar disableGutters={hideBrand} className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-haspopup="true"
                        aria-owns={
                            avatarPopupAnchor ? 'avatar-popup' : undefined
                        }
                        className={classNames(classes.menuButton)}
                        onClick={this.handleAvatarClick}
                    >
                        <Avatar
                            alt={user.name}
                            src={user.avatar}
                            className={classes.avatar}
                        >
                            {!user.avatar &&
                                user.name &&
                                user.name[0] &&
                                user.name[0].toUpperCase()}
                        </Avatar>
                    </IconButton>
                    {/* </Tooltip> */}
                    <Popover
                        id="avatar-popup"
                        classes={{ paper: classes.avatarPopup }}
                        anchorEl={avatarPopupAnchor}
                        open={Boolean(avatarPopupAnchor)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={this.handleavatarPopupClose}
                    >
                        <div className="text-center padding-2">
                            <Avatar
                                alt={user.name}
                                src={user.avatar}
                                className={classes.bigAvatar}
                            >
                                {!user.avatar &&
                                    user.name &&
                                    user.name[0] &&
                                    user.name[0].toUpperCase()}
                            </Avatar>
                            <Typography variant="subtitle1">
                                {user.name}
                            </Typography>
                            <Typography>{user.email}</Typography>
                        </div>
                        <Divider />
                        <List component="nav">
                            <ListItem button={true} onClick={this.handleLogout}>
                                <ListItemText primary={intl.get('signOut')} />
                            </ListItem>
                        </List>
                    </Popover>
                </Toolbar>
                <div>
                    <Dialog
                        className={classes.dialog}
                        fullScreen
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={this.Transition}
                    >
                        <div
                            className={classes.roleContainer}
                            style={{
                                backgroundColor: this.state.colorDialog,
                            }}
                        >
                            <h1
                                style={{
                                    width: '100%',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    padding: '1rem',
                                    background: 'rgba(255, 255, 255, 0.2)',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    textShadow: '1px 2px black',
                                    color: 'white',
                                    fontSize:
                                        window.innerWidth >= 401
                                            ? '4.5rem'
                                            : '3.2rem',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {`Perfil ${this.state.roleText}`}
                            </h1>
                        </div>
                    </Dialog>
                </div>
            </AppBar>
        );
    }

    private handleToggleClick = () => {
        if (this.props.onToggleClick) {
            this.props.onToggleClick();
        }
    };

    private handleAvatarClick = e => {
        if (!this.props.user) {
            this.props.push('/login');
            return;
        }
        this.setState({ avatarPopupAnchor: e.currentTarget });
    };

    private handleavatarPopupClose = () => {
        this.setState({ avatarPopupAnchor: null });
    };

    private handleLogout = () => {
        this.handleavatarPopupClose();
        this.props.push('/logout');
    };
}

const mapStateToProps = state => ({
    user: state.auth.user,
    isDarkTheme:
        state.global.theme &&
        state.global.theme.palette &&
        state.global.theme.palette.type === 'dark',
    role: state.global.role,
    isAdmin: state.global.isAdmin,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    push: (path: string) => {
        dispatch(push(path));
    },
    onChangeRole: () => {
        dispatch(globalActions.changeRole());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(withWidth()(Header)));
