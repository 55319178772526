import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import esLocale from 'date-fns/locale/es';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import Icon from '@material-ui/core/Icon';
import { makeStyles, Theme, createStyles, Link } from '@material-ui/core';
import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 190,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);
export interface AmountProps {
    amount: string;
    payday: string;
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}

export default function AddAmount(props: unknown) {
    const classes = useStyles();
    const localeMap = {
        es: esLocale,
    };
    useEffect(() => {
        if (props.amount.length < 2) {
            props.setStepButton(true);
        } else {
            props.setStepButton(false);
        }
    }, []);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 2) {
            props.setAmount(e.target.value);
            props.setStepButton(false);
        } else {
            props.setAmount(e.target.value);
            props.setStepButton(true);
        }
    };
    const handleDateChange = (date: Date | null) => {
        props.setPayday(date);
    };
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Indica el monto en pesos del alquiler
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="amount"
                        name="amount"
                        value={props.amount}
                        disabled={false}
                        fullWidth
                        autoComplete="shipping address-line2"
                        onChange={handleChange}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                        Día de pago del alquiler
                    </Typography>
                    <Grid item xs={window.innerWidth < 520 ? 6 : 3}>
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={localeMap['es']}
                        >
                            <DatePicker
                                value={props.payday}
                                format="dd"
                                id="date-picker-inline"
                                onChange={e => handleDateChange(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <Icon>
                                                <CalendarToday />
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
