/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import classNames from 'classnames';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import {
    Drawer,
    createStyles,
    withStyles,
    Typography,
    IconButton,
    Dialog,
    Button,
    CircularProgress,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { push } from 'connected-react-router';
import { VerticalMenu } from 'app/ui';
import { themeConfig, getCurrentTheme, ifLayout, AppTheme } from 'app/theme';
import { getConfig, adminMenus, subMenuUser, ownerMenus } from 'app/config';
import { UserInfo } from 'app/service/auth';
import { MenuItem } from 'app/types/MenuItem';
import storage from 'app/helpers/storage';
import { publicService } from 'app/service/public';
import { actions as globalActions } from 'app/service/global';
interface SidebarProps {
    classes: any;
    open?: boolean;
    user?: UserInfo;
    isAdmin?: number;
    userType?: number;
    onToggleClick?: () => void;
    setCloseMenu?: (close: any) => void;
    onChangeRole?: (role) => void;
    userMissingInfo?: unknown;
}

const styles = (theme: AppTheme) => {
    return createStyles({
        root: {
            height: '100vh',
            '& .MuiIconButton-root': {
                color: ifLayout(theme, { popular: 'inherit' }),
            },
            overscrollBehaviorX: 'none',
        },
        brandBlock: {
            textAlign: 'center',
            margin: 15,
            '& img': {
                width: '100%',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '50%',
            },
        },
        brandBlockMini: {
            margin: '10px 5px 5px 5px',
        },
        avatar: {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.primary.main,
        },
        avatarMini: {
            width: 30,
            height: 30,
        },
        drawerPaper: {
            position: 'relative',
            paddingTop: ifLayout(theme, {
                classic: themeConfig.headerHeight,
                popular: 0,
            }),
            paddingBottom: 45,
            whiteSpace: 'nowrap',
            color: ifLayout(theme, {
                classic: theme.palette.common.black,
                popular: theme.palette.common.white,
            }),
            backgroundColor: ifLayout(theme, {
                classic: theme.palette.background.paper,
                popular: theme.palette.primary.main,
            }),
            width: themeConfig.sidebarWidth + 1, // include right border width
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflowX: 'hidden',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
            },
        },
        drawerPaperClose: {
            overflowX: 'visible',
            overflowY: 'inherit',
            width: themeConfig.sidebarWidthMini + 1, // include right border width
            [theme.breakpoints.down('sm')]: {
                width: 0,
            },
        },
        drawerPaperFooter: {
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: ifLayout(theme, {
                classic: theme.palette.background.paper,
                popular: theme.palette.primary.dark,
            }),
            borderTopWidth: 1,
            borderTopColor: theme.palette.divider,
            borderTopStyle: 'solid',
            width: themeConfig.sidebarWidth,
            overflow: 'hidden',
            minHeight: 'inherit',
            padding: theme.spacing() / 2,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperFooterClose: {
            justifyContent: 'center',
            width: themeConfig.sidebarWidthMini,
            [theme.breakpoints.down('sm')]: {
                width: 0,
                padding: 0,
            },
        },
        copyright: {
            flex: 1,
            paddingLeft: theme.spacing(),
        },
        copyrightHidden: {
            display: 'none',
        },
        formControl: {
            marginLeft: 25,
        },
        radios: {
            marginLeft: 25,
            marginTop: 5,
        },
        Button: {
            width: '55%',
            left: '20%',
        },
        titleProfileButton: {
            fontSize: '1rem',
        },
        dialog: {
            width: '100vw',
            height: '100vh',
            opacity: 0.9,
        },
        changeRoleContainer: {
            display: 'block',
        },
    });
};

class Sidebar extends React.PureComponent<SidebarProps> {
    // public isDev = process.env.NODE_ENV != 'production';
    public isDev = true;

    public constructor(props: SidebarProps) {
        super(props);
        this.state = {
            userType: storage.get('user')?.user_type,
            checkedB: storage.get('user')?.user_type,
            roleTextButton:
                storage.get('user')?.user_type === 1
                    ? 'propietario'
                    : 'inquilino',
        };
        this.handleChange = this.handleChange.bind(this);
        this.changeProfileMobile = this.changeProfileMobile.bind(this);
        this.setState({ open: !(window.innerWidth < 468) });
    }

    private showRoleDialog = () => {
        this.setState({ open: true });
        setTimeout(() => {
            this.setState({ open: false });
        }, 700);
    };

    public handleChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const user = storage.get('user');
        const id = user.id;
        if (event.target.value === 'true') {
            this.setState({
                colorDialog: '#003DA5',
            });
            await publicService.changeUserRole(2, id);
            user.user_type = 2;
            storage.set('user', user);
            setTimeout(() => {
                this.props.onChangeRole(2);
                this.props.push('/');
            }, 700);
            this.showRoleDialog();
            // this.setState({
            //     roleText: 'propietario',
            // });
        } else {
            await publicService.changeUserRole(1, id);
            user.user_type = 1;
            storage.set('user', user);
            this.setState({
                colorDialog: '#DC1C2E',
            });
            setTimeout(() => {
                this.props.onChangeRole(1);
                this.props.push('/');
            }, 700);
            this.props.push('/');
            this.showRoleDialog();
            // this.setState({
            //     roleText: 'inquilino',
            // });
        }
    };

    public changeProfileMobile = async () => {
        const user = storage.get('user');
        const id = user.id;
        if (user.user_type === 1) {
            this.setState({
                colorDialog: '#003DA5',
            });
            await publicService.changeUserRole(2, id);
            user.user_type = 2;
            storage.set('user', user);
            setTimeout(() => {
                this.props.onChangeRole(2);
                this.props.push('/');
            }, 700);
            this.showRoleDialog();
            this.setState({
                roleTextButton: 'inquilino',
            });
        } else {
            await publicService.changeUserRole(1, id);
            user.user_type = 1;
            storage.set('user', user);
            this.setState({
                colorDialog: '#DC1C2E',
            });
            setTimeout(() => {
                this.props.onChangeRole(1);
                this.props.push('/');
            }, 700);
            this.props.push('/');
            this.showRoleDialog();
            this.setState({
                roleTextButton: 'propietario',
            });
        }
    };

    public render() {
        const config = getConfig();
        const { classes, open } = this.props;
        const { roleText, roleTextButton } = this.state;
        const theme = getCurrentTheme();
        return (
            <>
                {open && window.innerWidth < 468 && (
                    <div
                        style={{
                            width: '100%',
                            position: 'absolute',
                            height: '100vh',
                            zIndex: '500',
                        }}
                        onClick={() => this.props.setCloseMenu()}
                    ></div>
                )}
                <Drawer
                    variant="persistent"
                    className={classNames(classes.root, theme.layout)}
                    classes={{
                        paper: classNames(
                            classes.drawerPaper,
                            !open && classes.drawerPaperClose,
                        ),
                    }}
                    open={open}
                >
                    {this.props.isAdmin === 1 && (
                        <VerticalMenu
                            mini={!open}
                            width={themeConfig.sidebarWidth}
                            minWidth={themeConfig.sidebarWidthMini}
                            data={adminMenus}
                            setCloseMenu={this.props.setCloseMenu}
                        />
                    )}
                    {this.props.isAdmin === 0 &&
                        (this.props.role === 1 || this.props.role === null) && (
                            <>
                                <VerticalMenu
                                    mini={!open}
                                    width={themeConfig.sidebarWidth}
                                    minWidth={themeConfig.sidebarWidthMini}
                                    data={this.getUserMenus()}
                                    setCloseMenu={this.props.setCloseMenu}
                                />
                            </>
                        )}
                    {this.props.role === 2 && (
                        <>
                            <VerticalMenu
                                mini={!open}
                                width={themeConfig.sidebarWidth}
                                minWidth={themeConfig.sidebarWidthMini}
                                data={ownerMenus}
                                setCloseMenu={this.props.setCloseMenu}
                            />
                        </>
                    )}
                    {open &&
                        window.innerWidth < 468 &&
                        this.props.isAdmin === 0 && (
                            <div className={classes.changeRoleContainer}>
                                <Typography
                                    variant="body1"
                                    className={classes.titleProfileButton}
                                >
                                    Cambiar de perfil:
                                </Typography>
                                <br />
                                {this.isDev && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.changeProfileMobile}
                                        className={classes.Button}
                                    >
                                        {`Perfil ${roleTextButton}`}
                                    </Button>
                                )}
                            </div>
                        )}
                    <div>
                        <Dialog
                            className={classes.dialog}
                            fullScreen
                            open={this.state.open}
                            onClose={this.handleClose}
                            TransitionComponent={this.Transition}
                        >
                            <div
                                className={classes.roleContainer}
                                style={{
                                    backgroundColor: this.state.colorDialog,
                                }}
                            >
                                <h1
                                    style={{
                                        width: '100%',
                                        marginTop: 'auto',
                                        marginBottom: 'auto',
                                        padding: '1rem',
                                        background: 'rgba(255, 255, 255, 0.2)',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        textShadow: '1px 2px black',
                                        color: 'white',
                                        fontSize: '2.5rem',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {`Perfil ${
                                        storage.get('user')?.user_type === 2
                                            ? 'propietario'
                                            : 'inquilino'
                                    }`}
                                </h1>
                            </div>
                        </Dialog>
                    </div>
                    <div
                        className={classNames(
                            classes.drawerPaperFooter,
                            !open && classes.drawerPaperFooterClose,
                        )}
                    >
                        <a
                            className={classNames(
                                classes.copyright,
                                !open && classes.copyrightHidden,
                            )}
                            href="https://remaxrd.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Typography variant="caption">
                                &copy; 2021 RE/MAX RD
                            </Typography>
                        </a>
                        <IconButton
                            className={classes.iconButton}
                            onClick={this.handleToggle}
                        >
                            {open ? (
                                <ChevronLeftIcon fontSize="small" />
                            ) : (
                                <ChevronRightIcon fontSize="small" />
                            )}
                        </IconButton>
                    </div>
                </Drawer>
            </>
        );
    }

    private handleToggle = () => {
        if (this.props.onToggleClick) {
            this.props.onToggleClick();
        }
    };

    private getUserMenus(): MenuItem[] {
        return [
            {
                icon: 'fa fa-credit-card',
                text: 'Realizar Pago',
                description: '',
                link: '/pago',
            },
            {
                icon: 'fa fa-list-alt',
                text: 'Historial de Pagos',
                description: '',
                link: '/historial',
            },
            {
                icon: 'fa fa-cog',
                text: 'Configuración',
                params: 'config',
                children: subMenuUser,
            },
            {
                icon: 'fa fa-phone',
                text: 'Contacto',
                description: '',
                link: '/contacto',
            },
        ];
    }
}
const mapStateToProps = state => ({
    user: state.auth.user,
    role: state.global.role,
    isAdmin: state.global.isAdmin,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    push: (path: string) => {
        dispatch(push(path));
    },
    onChangeRole: () => {
        dispatch(globalActions.changeRole());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Sidebar));
