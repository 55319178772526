/* eslint-disable @typescript-eslint/camelcase */
import React, { Dispatch } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '/assets/images/remax_azul.png';
import Terms from '../public/Terms';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { actions as authActions } from 'app/service/auth';
import { actions as globalActions } from 'app/service/global';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputMask from 'react-input-mask';
import {
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    InputAdornment,
    Link as LinkMaterial,
    MenuItem,
    Select,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Action } from 'redux';
import validator from 'validator';
import {
    RegisterComponentProps,
    RegisterComponentState,
    Parameters,
} from './types/RegisterTypes';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow:
                'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage:
                    'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
        img: {
            width: '70%',
            margin: '40px 0 40px 0',
        },
        container: {
            textAlign: 'center',
            justifyContent: 'center',
        },
        submit: {
            backgroundColor: '#003DA5',
        },
        formControl: {
            margin: theme.spacing(3),
        },
        group: {
            margin: theme.spacing(0, 0),
        },
        idInput: {
            marginLeft: '8%',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '0%',
            },
        },
    });
class Register extends React.Component<
    RegisterComponentProps,
    RegisterComponentState
> {
    public constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            email: '',
            password: '',
            address: '',
            passwordConfirmation: '',
            emailConfirmation: '',
            isAdmin: false,
            idType: '',
            localTypeId: 1,
            canRegister: false,
            terms: false,
            userType: '',
            isPassport: false,
            documentType: 'local',
            passport: '',
            numberId: '',
            phone: '',
            showPassword: false,
            showConfirmPassword: false,
            inputErrors: {
                name: false,
                lastName: false,
                address: false,
                password: false,
                passwordConfirmation: false,
                idType: false,
                email: false,
                emailConfirmation: false,
                userType: false,
                documentType: false,
                numberId: false,
                passport: false,
                phone: false,
                terms: true,
            },
            inputErrorsMessage: {
                name: '',
                lastName: '',
                address: '',
                password: '',
                passwordConfirmation: '',
                idType: '',
                email: '',
                emailConfirmation: '',
                userType: '',
                documentType: '',
                numberId: '',
                passport: '',
                phone: '',
            },
        };
        this.onRegister = this.onRegister.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidations = this.handleValidations.bind(this);
        this.setCommonValues = this.setCommonValues.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClickShowConfirmPassword = this.handleClickShowConfirmPassword.bind(
            this,
        );
        this.handlePolicy = this.handlePolicy.bind(this);
    }

    public handlePolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { checked },
        } = event;
        let inputErrors = this.state.inputErrors;
        this.setState({
            terms: checked,
        });
        if (checked !== true) {
            inputErrors.terms = true;
            this.setState({
                inputErrors,
            });
        } else {
            inputErrors.terms = false;
            this.setState({
                inputErrors,
            });
        }
        this.handleChange(event);
    };
    public handleValidations = async (value, name) => {
        let inputErrors = this.state.inputErrors;
        let inputErrorsMessage = this.state.inputErrorsMessage;
        //handleErrors
        switch (name) {
            case 'name':
                if (value.length === 0) {
                    inputErrorsMessage.name = 'Campo Requerido';
                    inputErrors.name = true;
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                        name: '',
                    });
                } else {
                    this.setValues(name, value);
                    inputErrors.name = false;
                    inputErrorsMessage.name = '';
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                    });
                }
                break;
            case 'lastName':
                if (value.length === 0) {
                    inputErrorsMessage.lastName = 'Campo Requerido';
                    inputErrors.lastName = true;
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                        lastName: '',
                    });
                } else {
                    this.setValues(name, value);
                    inputErrors.lastName = false;
                    inputErrorsMessage.lastName = '';
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                    });
                }
                break;
            case 'email':
                this.setCommonValues(name, value);
                this.setState({
                    emailConfirmation: '',
                });
                if (validator.isEmail(value) === false) {
                    inputErrorsMessage.email = 'Agregar un correo válido';
                    inputErrors.email = true;
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                        email: '',
                    });
                } else {
                    this.setCommonValues(name, value);
                    inputErrors.email = false;
                    inputErrorsMessage.email = '';
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                    });
                }
                break;
            case 'emailConfirmation':
                this.setState({
                    emailConfirmation: value,
                });
                if (this.state.email !== value) {
                    inputErrorsMessage.emailConfirmation =
                        'los correos no coinciden';
                    inputErrors.emailConfirmation = true;
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                    });
                } else {
                    this.setCommonValues(name, value);
                    inputErrors.emailConfirmation = false;
                    inputErrorsMessage.emailConfirmation = '';
                    this.setState({
                        inputErrors,
                        inputErrorsMessage,
                        emailConfirmation: value,
                    });
                }
                break;
            case 'password':
                this.setState({ password: value });
                break;
            case 'passwordConfirmation':
                this.setState({ passwordConfirmation: value });
                break;
            case 'phone':
                this.setCommonValues(name, value);
                // if (value.replace(/\D/g, '').length <= 9) {
                //     inputErrorsMessage.phone = 'Campo requerido';
                //     inputErrors.phone = true;
                //     this.setState({
                //         inputErrors,
                //         inputErrorsMessage,
                //     });
                // } else {
                inputErrors.phone = false;
                inputErrorsMessage.phone = '';
                // }
                break;
        }
    };
    public handleClickShowPassword() {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    }
    public handleClickShowConfirmPassword() {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }
    public setValues(name, value) {
        if (name === 'name' && value.match(/^[a-zA-Z\s]*$/)) {
            this.setState({
                ...this.state,
                [name]: value,
            });
            return;
        }
        if (name === 'lastName' && value.match(/^[a-zA-Z\s]*$/)) {
            this.setState({
                ...this.state,
                [name]: value,
            });
            return;
        }
        return;
    }

    public handleSelect(event: React.ChangeEvent<HTMLInputElement>) {
        const {
            target: { value },
        } = event;
        this.setState({
            idIdentity: '',
            localTypeId: parseInt(value),
        });
    }

    public setCommonValues(name, value) {
        this.setState({
            ...this.state,
            [name]: value,
        });
        return;
    }
    public setIdentity(name, value) {
        if (
            this.state.localTypeId === 1 &&
            value.match(/^[0-9]*$/) &&
            value.length < 12
        ) {
            this.setState({
                ...this.state,
                [name]: value,
            });
        }
        return;
    }

    // public repeatPassword(event) {
    //     let inputErrorsMessage = this.state.inputErrorsMessage;
    //     let inputErrors = this.state.inputErrors;

    //     const {
    //         target: { name },
    //     } = event;
    //     if (
    //         name === 'password' &&
    //         (event.keyCode === 8 || event.keyCode === 46)
    //     ) {
    //         inputErrorsMessage.password = 'Campo requerido';
    //         inputErrors.password = true;
    //         this.setState({
    //             inputErrorsMessage,
    //             inputErrors,
    //             password: '',
    //             canRegister: false,
    //         });
    //     }
    //     if (
    //         name === 'passwordConfirmation' &&
    //         (event.keyCode === 8 || event.keyCode === 46)
    //     ) {
    //         inputErrorsMessage.passwordConfirmation =
    //             'Las contraseñas no coinciden';
    //         inputErrors.passwordConfirmation = true;
    //         this.setState({
    //             inputErrorsMessage,
    //             inputErrors,
    //             passwordConfirmation: '',
    //             canRegister: false,
    //         });
    //     }
    // }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = event;
        this.handleValidations(value, name);
        let arrayParameters = [
            this.state.name,
            this.state.lastName,
            this.state.email,
            this.state.password,
            this.state.passwordConfirmation,
            this.state.emailConfirmation,
            // this.state.phone,
        ];
        let emptyValue = arrayParameters.filter(function(params) {
            return params === '';
        });
        let errors = Object.values(this.state.inputErrors).filter(function(
            params,
        ) {
            return params === true;
        });
        if (errors.length === 0 && emptyValue.length === 0) {
            this.setState({
                canRegister: true,
            });
        } else {
            this.setState({
                canRegister: false,
            });
        }
    };
    public render() {
        const { classes } = this.props;
        const { inputErrors } = this.state;
        return (
            <Container
                component="main"
                className={classes.containerTag}
                maxWidth="xs"
            >
                <CssBaseline />
                <div className={classes.container}>
                    <div>
                        <br />
                        <img alt="Remax" src={logo} className={classes.img} />
                    </div>
                    <br />
                    <Typography component="h1" variant="h5">
                        Formulario de registro
                    </Typography>
                    <br />
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={inputErrors.name}
                                    autoComplete="fname"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nombre(s)"
                                    value={this.state.name}
                                    helperText={
                                        this.state.inputErrorsMessage.name
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    error={inputErrors.lastName}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Apellidos"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={this.state.lastName}
                                    helperText={
                                        this.state.inputErrorsMessage.lastName
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={inputErrors.email}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    name="email"
                                    autoComplete="email"
                                    helperText={
                                        this.state.inputErrorsMessage.email
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={inputErrors.emailConfirmation}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="emailConfirmation"
                                    label="Confirmación de Correo"
                                    name="emailConfirmation"
                                    autoComplete="email"
                                    value={this.state.emailConfirmation}
                                    helperText={
                                        this.state.inputErrorsMessage
                                            .emailConfirmation
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={inputErrors.password}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type={
                                        this.state.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    id="password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        this
                                                            .handleClickShowPassword
                                                    }
                                                >
                                                    {this.state.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={
                                        this.state.inputErrorsMessage.password
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={inputErrors.passwordConfirmation}
                                    value={this.state.passwordConfirmation}
                                    type={
                                        this.state.showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="passwordConfirmation"
                                    label="Repetir Contraseña"
                                    id="passwordConfirmation"
                                    helperText={
                                        this.state.inputErrorsMessage
                                            .passwordConfirmation
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        this
                                                            .handleClickShowConfirmPassword
                                                    }
                                                >
                                                    {this.state
                                                        .showConfirmPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputMask
                                    mask="(999)-999-9999"
                                    value={this.state.phone}
                                    name="phone"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => this.handleChange(e)}
                                >
                                    {() => (
                                        <TextField
                                            error={inputErrors.phone}
                                            helperText={
                                                this.state.inputErrorsMessage
                                                    .phone
                                            }
                                            variant="outlined"
                                            // required
                                            name="phone"
                                            id="cardOwnerTel"
                                            label="Teléfono"
                                            fullWidth
                                            autoComplete="cc-last"
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                        </Grid>
                        <FormControlLabel
                            label={''}
                            control={
                                <>
                                    {' '}
                                    <Checkbox
                                        checked={this.state.terms}
                                        onChange={this.handlePolicy}
                                        name="terms"
                                        color="primary"
                                    />
                                    <Terms />
                                </>
                            }
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!this.state.canRegister}
                            className={classes.submit}
                            onClick={this.onRegister}
                            style={{ marginTop: '6px' }}
                        >
                            <span style={{ color: 'white' }}>Regístrarse</span>
                        </Button>
                        <Grid container justify="center">
                            <Grid item>
                                <LinkMaterial href="/login" variant="body2">
                                    ¿Estás registrado? Inicia sesión
                                </LinkMaterial>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        );
    }
    private onRegister() {
        let parameters: Parameters = {
            name: this.state.name,
            last_name: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            address: this.state.address,
            // eslint-disable-next-line @typescript-eslint/camelcase
            password_confirmation: this.state.passwordConfirmation,
            is_admin: false,
            emailConfirmation: this.state.emailConfirmation,
            accept_terms: this.state.terms,
            tel: this.state.phone,
        };
        this.props.onRegister(parameters);
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    onRegister: (parameters: Parameters): boolean => {
        if (!parameters) {
            dispatch(
                globalActions.notify({
                    message: 'Todos los campos son requeridos',
                    variant: 'error',
                    placement: 'bottom center',
                }),
            );
            return false;
        }
        dispatch(authActions.register(parameters));
        return true;
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Register));
