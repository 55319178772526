import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { paymentsService } from 'app/service/payments';
import storage from 'app/helpers/storage';
import {
    Button,
    CircularProgress,
    IconButton,
    Snackbar,
} from '@material-ui/core';
import { PropsParameters } from './types/IPayments';
const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    button: {
        minWidth: 170,
        minHeight: 30,
    },
    snackbarStyleViaContentProps: {
        backgroundColor: '#28a745',
        color: 'white',
        fontSize: '1rem',
        height: 60,
    },
    Typography: {
        textAlign: 'center',
    },
    TitleAdvice: {
        fontSize: '1.2em',
    },
}));

const products = [
    {
        name: 'Apartamento',
    },
];

export default function Review(props: PropsParameters) {
    const [detail, setDetail] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [accepted, setAccepted] = React.useState(false);

    //states to confirm the "resend confirmation"
    const [loadingResend, setLoadingResend] = React.useState(false);
    const [resendSuccess, setResendSuccess] = React.useState(false);
    const [canPay, setCanPay] = React.useState(false);

    async function getDetails(id) {
        setLoading(true);
        const details = await paymentsService.getPaymentDetailsById(
            id,
            props.propertyId,
        );
        if (details.data.permission) {
            setDetail(details.data?.data);
            setCanPay(true);
            props.setIsAdviced(false);
        } else {
            setCanPay(false);
        }
        setLoading(false);
        if (details.data?.data?.is_accepted === 0) {
            setAccepted(false);
            props.setValidate(false);
        }
        if (details.data?.data?.is_accepted === 1) {
            setAccepted(true);
            props.setValidate(true);
        }
        let totalAmount;
        if (details.data?.data !== null) {
            totalAmount = (
                (5 / 100) * parseFloat(details.data?.data['Monto']) +
                parseFloat(details.data?.data['Monto'])
            ).toFixed(2);
            props.setTotalAmount(parseFloat(totalAmount));
        }
    }
    async function closeSnackBar() {
        setResendSuccess(false);
    }
    function SnackBarSuccess() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={resendSuccess}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        className: classes.snackbarStyleViaContentProps,
                    }}
                    autoHideDuration={5000}
                    onClose={closeSnackBar}
                    message="Solicitud enviada correctamente."
                    action={
                        <React.Fragment>
                            <Button
                                color="inherit"
                                size="medium"
                                onClick={closeSnackBar}
                            >
                                CERRAR
                            </Button>
                        </React.Fragment>
                    }
                />
            </div>
        );
    }

    async function sendEmailToOwner() {
        setLoadingResend(true);
        let response = await paymentsService.reSendPaymentConfig(
            storage.get('user').id,
        );
        if (response.status === 200) {
            setLoadingResend(false);
            setResendSuccess(true);
        }
    }

    useEffect((): void => {
        if (storage.get('user') === null) {
            props.redirect.push('/');
            return;
        }
        let id = storage.get('user').id;
        getDetails(id);
    }, []);
    const classes = useStyles('paper');
    function Loading(): JSX.Element {
        return (
            <React.Fragment>
                <br />
                <Typography
                    className={classes.Typography}
                    variant="h6"
                    gutterBottom
                >
                    Cargando datos...
                </Typography>
                <br />
                <CircularProgress />
            </React.Fragment>
        );
    }
    function PermissionMessage(): JSX.Element {
        return (
            <React.Fragment>
                <>
                    <br />
                    <Typography className={classes.Typography} gutterBottom>
                        <b className={classes.TitleAdvice}>Aviso</b>
                        <br />
                        <br />
                        Tu usuario debe ser activado por un administrador.
                        <br />
                        Para más información puedes contactarnos a
                        info@remaxrd.com.
                    </Typography>
                </>
            </React.Fragment>
        );
    }

    function RenderStatus(): JSX.Element {
        return (
            <>
                {(detail !== null &&
                    accepted === true &&
                    loading === false &&
                    RenderDetails()) ||
                    ''}
                {loading === false && detail === null && (
                    <React.Fragment>
                        <br />
                        <center>
                            <Typography variant="h6" gutterBottom>
                                Sin configuración de pagos
                            </Typography>
                            Debes ir a la sección de{' '}
                            <strong>Configuración</strong> para preparar tu
                            pago.
                            <br />
                        </center>
                    </React.Fragment>
                )}
                {loading === false && detail !== null && accepted !== true && (
                    <React.Fragment>
                        <br />
                        <center>
                            <Typography variant="h6" gutterBottom>
                                El propietario aún no ha aprobado el monto
                                indicado.
                            </Typography>
                            <br />
                            <Grid item xs={12} md={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={sendEmailToOwner}
                                    // disabled={submitDisabled}
                                    className={classes.button}
                                >
                                    {!loadingResend && (
                                        <span style={{ color: 'white' }}>
                                            Solicitar aprobación
                                        </span>
                                    )}
                                    {loadingResend && (
                                        <CircularProgress
                                            size={32}
                                            color={'inherit'}
                                        />
                                    )}
                                </Button>
                            </Grid>
                            <br />
                            Cuando el monto sea aprobado serás notificado vía
                            correo electrónico.
                        </center>
                        <SnackBarSuccess />
                    </React.Fragment>
                )}
            </>
        );
    }
    function RenderDetails(): JSX.Element | '' {
        return (
            loading === false &&
            detail !== null && (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                        Detalles de pago
                    </Typography>
                    <Grid container spacing={2}>
                        <ListItemText
                            style={{
                                maxWidth: '50%',
                                marginTop: '5%',
                                marginLeft: '10px',
                            }}
                            primary={detail['Tipo de Propiedad']}
                        />
                        <List disablePadding>
                            {products.map(product => (
                                <ListItem
                                    className={classes.listItem}
                                    key={product.name}
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                >
                                    <ListItemText
                                        secondary={
                                            <>
                                                <Typography variant="body2">
                                                    <strong>Alquiler: </strong>
                                                    {'RD$' +
                                                        detail['Monto']
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ',',
                                                            )}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>
                                                        Comisión de servicio:{' '}
                                                    </strong>
                                                    {'RD$' +
                                                        (
                                                            (5 / 100) *
                                                            parseFloat(
                                                                detail['Monto'],
                                                            )
                                                        )
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ',',
                                                            )}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>
                                                        Monto total:{' '}
                                                    </strong>
                                                    {'RD$' +
                                                        (
                                                            (5 / 100) *
                                                                parseFloat(
                                                                    detail[
                                                                        'Monto'
                                                                    ],
                                                                ) +
                                                            parseFloat(
                                                                detail['Monto'],
                                                            )
                                                        )
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ',',
                                                            )}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.title}
                            >
                                Dirección de la propiedad
                            </Typography>
                            <Typography
                                gutterBottom
                                style={{
                                    wordWrap: 'break-word',
                                    textAlign: 'justify',
                                }}
                            >
                                {detail['Dirección Propiedad']}
                            </Typography>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.title}
                            >
                                Detalles del propietario
                            </Typography>
                            <Grid container>
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Nombre:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            {detail['Nombre Prop.']}{' '}
                                            {detail['Apellido Prop.']}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Teléfono:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            {detail['Teléfono Prop.']}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Documento de identidad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            {detail['No. identidad Prop.']}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Ciudad:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            {detail['Ciudad Propietario']}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Correo:
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ wordWrap: 'break-word' }}
                                    >
                                        <Typography gutterBottom>
                                            {detail['Correo Propietario']}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Banco:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            {detail['Banco']}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom>
                                            Cuenta:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            gutterBottom
                                            style={{ wordWrap: 'break-word' }}
                                        >
                                            {detail['Cuenta']}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        );
    }
    return (
        <>
            {loading && Loading()}
            {canPay && loading === false ? (
                <RenderStatus />
            ) : (
                loading === false && <PermissionMessage />
            )}
        </>
    );
}
