import { createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textNotification: {
            fontFamily: "'Roboto'",
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '18px',
            lineHeight: '22px',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.04em',
            color: 'rgba(0, 0, 0, 0.8)',
            marginTop: '6px',
        },
        step: {
            textAlign: 'center',
            background: 'rgb(0, 125, 195)',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '22px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '10px 0px',
        },
        info: {
            marginTop: 10,
            fontFamily: "'Roboto'",
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '15px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.04em',
        },
    }),
);

const WelcomeMobile = () => {
    const classes = useStyles();
    return (
        <div>
            <Grid container>
                <Grid item sx={12}>
                    <img
                        src="/images/mobileDashboart.svg"
                        style={{
                            position: 'relative',
                            zIndex: 1,
                            width: '100vw',
                        }}
                    />
                </Grid>

                <Grid container item xs={12} justify="center">
                    <Typography className={classes.textNotification}>
                        ¡Aquí te explicamos cómo funciona!
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                >
                    <Grid
                        container
                        item
                        xs={10}
                        className={classes.step}
                        justify="center"
                    >
                        <Typography component="p" style={{ fontWeight: '700' }}>
                            1.
                        </Typography>
                        <Typography>
                            Haz clic en configuración, agrega y completa los
                            datos del inmueble y del propietario.
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={10}
                        className={classes.step}
                        justify="center"
                    >
                        <Typography component="p" style={{ fontWeight: '700' }}>
                            2.
                        </Typography>
                        <Typography>
                            El propietario recibirá un correo para confirmar los
                            datos.
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={10}
                        className={classes.step}
                        justify="center"
                    >
                        <Typography component="p" style={{ fontWeight: '700' }}>
                            3.
                            <br />
                        </Typography>

                        <Typography>
                            ¡Listo! Ya puedes realizar el pago.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justify="center">
                    <img src="/images/mailIcon.svg" />
                    <Typography className={classes.info}>
                        Tienes dudas o requieres más información escríbenos a
                        info@remaxrd.com.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default WelcomeMobile;
