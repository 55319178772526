import React from 'react';
import ResponsiveTable from 'material-ui-next-responsive-table';
import { createStyles } from '@material-ui/core';
import { AppTheme } from 'app/theme';
import { Link } from 'react-router-dom';

const styles = (theme: AppTheme) =>
    createStyles({
        '@global': {
            body: {
                color: theme.palette.text.primary,
                maxHeight: '20px',
            },
            '.markdown-body pre': {
                backgroundColor: theme.palette.background.paper,
            },
        },
        overrides: {
            MuiTableCell: {
                root: {
                    padding: '18px 0px 20px 16px',
                },
            },
        },
    });

export default function InfoContent({ userInfo, loading }: any) {
    const columns = [
        {
            key: 'id',
            label: 'ID',
            primary: true,
            render: value => {
                return value;
            },
        },
        {
            key: 'status_desc',
            label: 'Estatus de pago',
            render: value => {
                return value;
            },
        },
        {
            key: 'azul_order_id',
            label: 'Factura',
            render: value => {
                return value;
            },
        },
        {
            key: 'card_number',
            label: 'No. de Tarjeta',
            render: value => {
                return value;
            },
        },
        {
            key: 'amount',
            label: 'Monto',
            render: value => {
                return value;
            },
        },
        {
            key: 'transaction_date',
            label: 'Fecha',
            render: value => {
                return value;
            },
        },
        {
            key: 'owner_name',
            label: 'Nombre Propietario',
            render: value => {
                return value;
            },
        },
        {
            key: 'owner_last',
            label: 'Apellido Propietario',
            render: value => {
                return value;
            },
        },
        {
            key: 'owner_bank',
            label: 'Banco',
            render: value => {
                return value;
            },
        },
        {
            key: 'owner_bank_account',
            label: 'Cuenta',
            render: value => {
                return value;
            },
        },
        {
            key: 'owner_city',
            label: 'Ciudad Propietario',
            render: value => {
                return value;
            },
        },
        {
            key: 'owner_tel',
            label: 'Teléfono Propietario',
            render: value => {
                return value;
            },
        },
        {
            key: 'status_changed_date',
            label: 'Fecha de cambio de estatus',
            render: value => {
                return value;
            },
        },
        {
            key: 'rental_document',
            label: 'Contrato de alquiler',
            // eslint-disable-next-line react/display-name
            render: (value: any) => {
                return value !== ' - ' ? (
                    <a
                        style={{ color: 'blue' }}
                        target="_blank"
                        href={value}
                        rel="noreferrer"
                    >
                        {' '}
                        {'Link'}{' '}
                    </a>
                ) : (
                    ' - '
                );
            },
        },
    ];

    return (
        <ResponsiveTable
            columns={columns}
            data={userInfo}
            noContentText={
                loading ? 'Cargando datos' : ' No ha realizado pagos'
            }
        />
    );
}
