import axios, { AxiosResponse } from 'axios';
import ApiConstants from '../config/api.constants';

export interface DetailPayments {
    data: {
        merchant_id: string;
        merchant_name: string;
        merchant_type: string;
        currency_code: string;
        order_number: string;
        amount: string;
        ITBIS: string;
        confirmation_url: string;
        use_custom_field1: string;
        custom_field_1label: string;
        custom_field_1value: string;
        use_custom_field2: string;
        custom_field_2label: string;
        encryption_key: string;
    };
    auth: string;
}

let config = {
    headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': ApiConstants.X_API_KEY,
    },
};

export const paymentsService = {
    savePaymentTransaction: async (
        amount,
        TransactionDate,
        ResponseMessage,
        rrn,
        CardNumber,
        idTenant,
        AzulOrderId,
        token,
        affiliate,
        propertyId,
    ) => {
        await axios.post(
            ApiConstants.SAVE_PAYMENT_ENDPOINT,
            {
                amount,
                TransactionDate,
                ResponseMessage,
                rrn,
                CardNumber,
                idTenant,
                AzulOrderId,
                token,
                affiliate,
                propertyId,
            },
            config,
        );
    },
    getPaymentDetailsById: async (id, property_id) => {
        let res;
        await axios
            .post(
                ApiConstants.GET_PAYMENTS_DETAILS_ENDPOINT,
                { id, property_id },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    getPaymentDetailsByToken: async token => {
        let res;
        await axios
            .post(ApiConstants.GET_PAYMENTS_LINK_ENDPOINT, { token }, config)
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    fetchPaymentDetails: () => {
        axios
            .post(
                ApiConstants.FETCH_PAYMENT_DETAILS_ENDPOINT,
                { data: '' },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                return response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                return error;
            });
        return;
    },
    savePaymentConfig: async (
        amount,
        payday,
        ownerName,
        ownerLast,
        documentType,
        document,
        email,
        phone,
        optPhone,
        address,
        bank,
        bankAccount,
        accountNumber,
        ownerAddress,
        propertyType,
        city,
        sector,
        construction,
        bedrooms,
        bathrooms,
        parking,
        revenue,
        idTenant,
        tenantEmail,
        contract,
    ) => {
        let res;
        await axios
            .post(
                ApiConstants.SAVE_CONFIG_PAYMENT_ENDPOINT,
                {
                    amount,
                    payday,
                    ownerName,
                    ownerLast,
                    documentType,
                    document,
                    email,
                    phone,
                    optPhone,
                    address,
                    bank,
                    bankAccount,
                    accountNumber,
                    ownerAddress,
                    propertyType,
                    city,
                    sector,
                    construction,
                    bedrooms,
                    bathrooms,
                    parking,
                    revenue,
                    idTenant,
                    tenantEmail,
                    contract,
                },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
    reSendPaymentConfig: async idTenant => {
        let res;
        await axios
            .post(
                ApiConstants.RESEND_CONFIG_PAYMENT_ENDPOINT,
                {
                    idTenant,
                },
                config,
            )
            .then((response: AxiosResponse) => {
                //handle success
                res = response;
            })
            .catch((error: AxiosResponse) => {
                //handle error
                res = error;
            });
        return res;
    },
};
