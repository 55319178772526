import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link as LinkMaterial, Toolbar } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { ReviewLink } from './components';
import Terms from '../public/Terms';
import { connect } from 'react-redux';
import InputsPaymentsLink from './components/InputsPaymentsLink';
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <LinkMaterial color="inherit" href="https://remaxrd.com/">
                RE/MAX RD
            </LinkMaterial>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function PaymentsBrand() {
    return (
        <Typography variant="body1" color="textPrimary" align="center">
            Aceptamos las tarjetas <br />
            <div style={{ display: 'inline-flex' }}>
                <img src="../images/brands/visa.png" />
                <img src="../images/brands/mastercard.png" />
                <img src="../images/brands/discover.png" />
                <img src="../images/brands/diners.png" />
                <img src="../images/brands/american.png" />
            </div>
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
    },
}));

function PaymentLink(props) {
    const classes = useStyles('paper');
    const [activeStep, setActiveStep] = React.useState(1);
    const [validate, setValidate] = React.useState(false);
    const [totalAmount, setTotalAmount] = React.useState('');
    const [propertyId, setPropertyId] = React.useState('');
    const [token, setToken] = React.useState('');
    const [isExpired, setIsExpired] = React.useState(false);
    const [isUsed, setIsUsed] = React.useState(false);
    const [IsValidLink, setCheckIsValidLink] = React.useState(false);

    function checkExpDate(date) {
        let linkDate = new Date(date);
        let actualDate = new Date();
        let day = 1440;
        if (actualDate.getMinutes() - linkDate.getMinutes() > day) {
            setIsExpired(true);
        }
    }

    function checkLinkStatus(isUsed) {
        setIsUsed(isUsed);
    }
    function checkIsValidLink(isValid) {
        setCheckIsValidLink(isValid);
    }
    function getStepContent(step) {
        switch (step) {
            case 1:
                return (
                    <>
                        <ReviewLink
                            setValidate={setValidate}
                            setTotalAmount={setTotalAmount}
                            history={props.history}
                            setToken={setToken}
                            checkExpDate={checkExpDate}
                            checkLinkStatus={checkLinkStatus}
                            checkIsValidLink={checkIsValidLink}
                        />
                    </>
                );
            default:
                throw new Error('Unknown step');
        }
    }
    function MakePayment() {
        return (
            <>
                <div className={classes.buttons}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        name="submit"
                        disabled={!state.checkedB}
                        className={classes.button}
                    >
                        <span style={{ color: 'white' }}>Realizar Pago</span>
                    </Button>
                </div>
            </>
        );
    }
    const [state, setState] = React.useState({
        checkedB: false,
    });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    console.log(isUsed, isExpired);
    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <form
                    action={`${process.env.AZUL_URL}`}
                    method="post"
                    id="paymentForm"
                    name="paymentForm"
                    target="_blank"
                >
                    <InputsPaymentsLink
                        totalAmount={totalAmount}
                        propertyId={propertyId}
                        token={token}
                        history={props.history}
                    />

                    {!isExpired && !isUsed && (
                        <Paper className={classes.paper}>
                            <Typography
                                component="h1"
                                variant="h4"
                                align="center"
                            />
                            {getStepContent(activeStep)}
                            {IsValidLink && <MakePayment />}
                            {IsValidLink && (
                                <>
                                    <FormControlLabel
                                        control={
                                            <>
                                                <Checkbox
                                                    checked={state.checkedB}
                                                    onChange={handleChange}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                <Terms />
                                            </>
                                        }
                                        label={''}
                                    />

                                    <span
                                        style={{
                                            fontSize: '9px',
                                            color: '#808080',
                                            display: 'inline-block',
                                        }}
                                    >
                                        No aceptamos devoluciones, reembolsos o
                                        cancelaciones luego de realizado el
                                        pago.
                                    </span>
                                </>
                            )}
                        </Paper>
                    )}

                    {isExpired && !isUsed && (
                        <Paper className={classes.paper}>
                            <React.Fragment>
                                <br />
                                <center>
                                    <Typography variant="body1" gutterBottom>
                                        Tu enlace de pago ha expirado, solicita
                                        al propietario un nuevo enlace para que
                                        puedas realizar el pago de tu alquiler.
                                        <br /> <br />
                                        Comunícate con tu propietario para
                                        generar uno nuevo.
                                    </Typography>
                                    <br />
                                </center>
                            </React.Fragment>
                        </Paper>
                    )}

                    {((!isExpired && isUsed) || (isExpired && isUsed)) && (
                        <Paper className={classes.paper}>
                            <React.Fragment>
                                <br />
                                <center>
                                    <Typography variant="body1" gutterBottom>
                                        Este enlace de pago ya ha sido
                                        utilizado, solicita al propietario un
                                        nuevo enlace para que puedas realizar el
                                        pago de tu alquiler. <br /> <br />
                                        Comunícate con tu propietario para
                                        generar uno nuevo.
                                    </Typography>
                                    <br />
                                </center>
                            </React.Fragment>
                        </Paper>
                    )}

                    {IsValidLink && <PaymentsBrand />}
                </form>
            </main>
        </React.Fragment>
    );
}

export default connect(null, null)(PaymentLink);
