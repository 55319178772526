import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import {
    SearchUsers,
    UserPagination,
    InfoContent,
    PropertyInfo,
} from '../components';
import { UserProps } from '../types/index';
import { paymentsService as admin } from 'app/service/admin';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
        justifyItems: 'center',
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    searchContent: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    userName: {
        cursor: 'pointer',
        '&:hover': {
            color: '#3f51b5',
            transition: 'all 0.2s ease',
        },
    },
    cardMedia: {
        paddingTop: '100%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    dialogContent: {
        textAlign: 'center',
    },
    pagination: {
        marginTop: theme.spacing(2),
    },
}));

export default function UserPermissions(props) {
    const classes = useStyles('paper');
    const [users, setUsers] = React.useState<UserProps[]>([]);
    const [userInfo, setUserInfo] = React.useState([]);
    const [propertyInfo, setPropertyInfo] = React.useState([]);
    const [info, setInfo] = useState(false);
    const [openProperty, setOpenProperty] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [offset, setOffset] = useState(0);
    const getUsersFiltered = async (init, page, perPage, search) => {
        const res = await admin.getUsers(init, page, perPage, search);
        setUsers(res.data[0]);
        setTotal(res.data[1]);
    };
    useEffect(() => {
        getUsersFiltered('active', 0, 0, '');
    }, []);

    const toggleActivatePayment = async id => {
        await admin.activatePayment(id);
        getUsersFiltered('inactive', offset, 8, '');
    };
    const searchUser = async keyword => {
        if (keyword === null) {
            setOffset(0);
        }
        const res = await admin.getUsers('inactive', offset, 8, keyword);
        setUsers(res.data[0]);
        setTotal(res.data[1]);
    };
    const paymentInfoClose = () => {
        setInfo(false);
    };

    const propertyInfoClose = () => {
        setOpenProperty(false);
    };
    const getPaymentInfo = async id => {
        setUserInfo([]);
        setLoading(true);
        setInfo(true);
        const res = await admin.findPaymentInfo(id);
        if (res.status === 200) {
            setTimeout(() => {
                setUserInfo(res.data);
                setLoading(false);
            }, 1000);
        }
    };
    const getPropertiesInfo = async id => {
        setPropertyInfo([]);
        setLoading(true);
        setOpenProperty(true);
        const res = await admin.findPropertyInfo(id);
        if (res.status === 200) {
            setTimeout(() => {
                setPropertyInfo(res.data);
                setLoading(false);
            }, 1000);
        }
    };
    const paymentInfo = id => {
        getPaymentInfo(id);
    };
    const propertiesInfo = id => {
        getPropertiesInfo(id);
    };
    const DialogUserInfo = () => {
        return (
            <Dialog
                open={info}
                onClose={paymentInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={userInfo.length > 0 ? 'xl' : 'sm'}
            >
                <DialogTitle>{'Pagos realizados'}</DialogTitle>
                <DialogContent dividers={true}>
                    {loading && (
                        <Grid item className={classes.dialogContent} lg={12}>
                            <CircularProgress />
                        </Grid>
                    )}
                    <InfoContent userInfo={userInfo} loading={loading} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={paymentInfoClose}>Volver</Button>
                </DialogActions>
            </Dialog>
        );
    };
    const DialogPropertyInfo = () => {
        return (
            <Dialog
                open={openProperty}
                onClose={propertyInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={propertyInfo.length > 0 ? 'xl' : 'sm'}
            >
                <DialogTitle>{'Propiedades creadas'}</DialogTitle>
                <DialogContent dividers={true}>
                    {loading && (
                        <Grid item className={classes.dialogContent} lg={12}>
                            <CircularProgress />
                        </Grid>
                    )}
                    <PropertyInfo
                        propertyInfo={propertyInfo}
                        loading={loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={propertyInfoClose}>Volver</Button>
                </DialogActions>
            </Dialog>
        );
    };
    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <Paper className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Typography
                            component="h4"
                            variant="h4"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                        >
                            Permisos de usuario
                        </Typography>
                        <Typography
                            variant="h6"
                            align="center"
                            color="textSecondary"
                            paragraph
                        >
                            Haz clic en el nombre del usuario para tener mas
                            información.
                        </Typography>
                    </Container>
                </Paper>
                <Container className={classes.cardGrid} maxWidth="lg">
                    <Grid container>
                        <Grid item xs={12} lg={4} sm={12} md={6}>
                            <Paper className={classes.searchContent}>
                                <SearchUsers searchUser={searchUser} />
                            </Paper>
                        </Grid>
                        <Grid item lg={3}></Grid>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            sm={12}
                            md={11}
                            className={classes.pagination}
                        >
                            <UserPagination
                                limit={8}
                                total={total}
                                offset={offset}
                                setUsers={setUsers}
                                setOffset={setOffset}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        {users.map(user => (
                            <Grid item key={user.id} xs={12} sm={6} md={3}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image="/images/profile.png"
                                        title="Image title"
                                    />
                                    <CardContent
                                        className={classes.cardContent}
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h6"
                                            className={classes.userName}
                                            onClick={() => paymentInfo(user.id)}
                                        >
                                            {`${user.name} ${user.last_name}`}
                                        </Typography>
                                        <Typography>
                                            Estatus:{' '}
                                            <span
                                                style={{
                                                    color: user.is_active
                                                        ? '#00ff00'
                                                        : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >{`${
                                                user.is_active
                                                    ? 'Disponible'
                                                    : 'Inactivo'
                                            }`}</span>
                                        </Typography>
                                        <Typography>
                                            Realizar Pagos:{' '}
                                            <span
                                                style={{
                                                    color: user.can_pay
                                                        ? '#00ff00'
                                                        : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >{`${
                                                user.can_pay
                                                    ? 'Activado'
                                                    : 'Desactivado'
                                            }`}</span>
                                        </Typography>
                                        <Typography>
                                            {`Fecha de regístro: ${new Date(
                                                user.created_at,
                                            )
                                                .toLocaleDateString('en-GB', {
                                                    // you can use undefined as first argument
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                })
                                                .replace('/', '-')
                                                .replace('/', '-')}`}
                                        </Typography>
                                        <Typography>{user.email}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                                toggleActivatePayment(user.id)
                                            }
                                        >
                                            {`${
                                                !user.can_pay
                                                    ? 'Activar'
                                                    : 'Desactivar'
                                            }`}
                                        </Button>
                                        <Button
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                                propertiesInfo(user.id)
                                            }
                                        >
                                            {`Ver propiedades`}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {DialogUserInfo()}
                    {DialogPropertyInfo()}
                </Container>
            </main>
            {/* End footer */}
        </React.Fragment>
    );
}
